import React, { Component, lazy, Suspense } from "react";
import apiServices from "../../services/requestHandler";
import ReactTimerStopwatch from "react-stopwatch-timer";
import azFlag from "../../images/country-flags/Azerbaijan.png";
import {
  capitalize,
  checkTrueValue,
  detailPanelOrder,
  drinksAddErrors,
  isNumber,
  pizaAddErrors,
  removerValue,
  sortStoresByDistance,
  getDealsError,
  setCookingInstruction,
  drinkApiCall,
  getHeightLightTime,
  isFreeDeal,
  lateOrderCouponCheck,
  isEmailValid,
} from "../../utils/functions";
import Loader from "../../components/ReUseableComponant/Loader";
import moment from "moment";
import { withStore } from "../../utils/store.util";
import "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Card,
  Grid,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import localStoreUtil from "../../utils/localstore.util";

const OrderComponant = lazy(() => import("./OrderComponant"));

const defaultSizeCode = {
  8: "small",
  10: "medium",
  12: "large",
};

const defaultSizeName = {
  small: "8",
  medium: "10",
  large: "12",
};

const defaultPismis = {
  Azpismis: "Az Pismis",
  Cookpismis: "Cook Pismis",
  "": "",
};

const defaultCuts = {
  DoubleCut: "Double Cut",
  SquareCut: "Square Cut",
  RegularCut: "",
};

const initailState = {
  freeCoupon: {},
  LateOrderDateData: [],
  LateOrderAllDate: [],

  //Customer Start
  openFlagList: false,
  addressFromMap: "",
  mapOpen: false,
  laterDeliveryValue: false,
  customerFirstName: "",
  customerLastName: "",
  customerEmail: "",
  customerGender: "",
  customerStreet: "",
  customerBlock: "",
  customerBuilding: "",
  customerFloor: "",
  customerUnit: "",
  customerNote: "",
  customerAgentNote: "",
  customerAddressTitle: "home",
  customerPrevAdddressTitle: "",
  pastOrders: [],
  resentOrders: [],
  rewards: [],
  newCustomer: false,
  autoFocusCheck: false,
  deliveryInstructions: "",
  deliveryInstructionsCopy: "",

  //Locations State Start
  customerLocation: [],
  customerAddress: [],
  allStoresWithDistance: [],
  //Locations State End
  //Customer End

  //Finish Screen Start
  driverInstruction: [],
  driverInstructionModal: false,
  modifyModal: false,
  deliveryInstructionAccept: false,
  paymentMethod: "",
  //Finish Screen End

  valueIsTruePizza: false,
  valueIsTrueTopping: false,
  valueIsTrueCrust: false,
  isDeliveryInstructionEdited: false,
  completeModalOrder: false,
  StoreIsClosedOrNot: false,
  CustumerError: false,
  printOrderNumber: "",
  modifyClick: false,
  preData: [],
  scrioll: 0,
  storeMsg: "Please Select Store",
  cancelDailogModel: false,
  running: false,
  redirect: false,
  deliveryTill: "",
  dateCountHandler: 1,
  logOutDailogModel: false,
  selectedFixTab: "",
  payload: [],
  storeNameDisplay: "",
  nearToExpiredCoupons: false,
};

const initailStateCheck = {
  //Cart Data Start
  itemList: [],
  listIndexSet: "",
  selectedPizza: {},
  //Cart Data End
  countryCode: "+994",
  countryFlag: azFlag,
  deliveryTime: "",
  serviceMethod: "",
  selectedDateTime: {
    selectedHour: "",
    selectedDate: "",
  },

  laterTime: "",
  preStore: "",
  storeNameSelected: [],
  openHour: "",
  closeHour: "",
};

const allTabs = {
  customer: {
    // title: CUSTOMER_TITLE,
    title: "Customer",
    tabs: [{ tab: "New Order" }, { tab: "Modify Order" }, { tab: "Re-Order" }],
  },
  pizza: {
    title: "Pizzas",
    tabs: [
      { tab: "Speciality Pizza" },
      { tab: "Half and Half" },
      { tab: "Pizza Toppings" },
      { tab: "Cooking Instructions" },
    ],
  },
  everythingElse: {
    title: "Everything Else",
    tabs: [
      { tab: "Bread" },
      { tab: "Chicken" },
      { tab: "Salad" },
      { tab: "Drink" },
      { tab: "Dessert" },
      { tab: "Dips" },
    ],
  },
  coupons: {
    title: "Coupons",
    tabs: [
      { tab: "All Coupons" },
      { tab: "Filter Coupons" },
      { tab: "Service Method Coupons" },
      { tab: "Product Categories" },
    ],
  },
  finish: {
    title: "Finish",
    tabs: [{ tab: "Order Confirmation" }, { tab: "Driver's Instruction" }],
  },
  exitOrder: {
    title: "Exit",
    tabs: [],
  },
};

export class OrdersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initailStateCheck,
      ...initailState,
      tab1: allTabs,

      //Side Button Group Start
      customer: true,
      orderFinish: false,
      exitOrder: false,
      pizza: false,
      everythingElse: false,
      coupons: false,
      finish: false,

      //Side Button Group End
      mapRerender: false,

      //Tabs Start
      tabs: allTabs.customer.tabs,
      tabTitle: allTabs.customer.title,
      selectedTab: allTabs.customer.tabs[0].tab,

      //Tabs End

      //Everything Else Section Start
      EveryThingItemList: [],
      DrinkList: [],
      phone: "",

      //Everything Else Section End

      //Pizza Section Start
      specialityPizza: [],
      pizzaAllData: [],
      addPiza: true,
      alltoppingData: [],
      toppingData: [],
      halfNhalfPizza: [],
      crustAllData: [],
      crustData: [],
      coupanData: [],

      //Piza nevigation Counts Start
      halfNhalfPizzaCount: 1,
      specialityPizzaCount: 1,
      crustDataCount: 1,
      couponsDataCount: 1,
      alltoppingDataCount: 1,

      //Piza nevigation Counts End
      couponsComplete: "",
      pizzaComplete: "",
      everyThingComplete: "",
      customerComplete: "",

      //Pizza Section End,
      newPastOrder: [],
      newRecentOrder: [],
      finishComplete: "",

      // All Coupons
      allCouponsData: [],
      filterCouponsData: [],
      agentCoupons: [],

      url: null,
      activity: true,
      removedHalfnHalf: false,
      dealItemIndex: null,
      cartIndex: "",
      orderPlaceSuccefully: false,
      noLoaderTab: allTabs.finish.tabs[0],

      //for order takeTime
      running: false,
      deletedItems: [],
      agentData: {},
    };

    this.handleChangeMobile = this.handleChangeMobile.bind(this);
    this.parentRef = React.createRef();
  }

  async shouldComponentUpdate(nextProps) {
    // Rendering the component only if
    // passed props value is changed
    let payload = await localStoreUtil.get_data("payload");

    if (payload) {
      let payloadDetails = payload.data.body.split(",");
      let customerMobile = payloadDetails[1].split("_ ");

      if (this.state?.customerObj?.mobile === customerMobile[1]) {
        this.setState({ mapOpen: true, payload });
      } else {
        await localStoreUtil.remove_data("payload");
      }
    }
  }

  //Did Mount TO get All APis
  async componentDidMount() {
    try {
      let { itemList } = this.state;

      itemList = [];

      await this.setState({ itemList });

      await this.getCategories();

      const { store } = this.props;

      // Drinks K lye
      let ResponseDrink = store.get("drink");

      let showDrinks = "";

      if (ResponseDrink) {
        showDrinks = ResponseDrink;
      } else {
        ResponseDrink = await drinkApiCall();
        await store.setWithRender("drink", ResponseDrink);
      }

      await this.setState({
        DrinkList: showDrinks,
      });

      const language = await localStoreUtil.get_data("lang");
      if (language) {
        await store.setWithRender("lang", language);
      } else {
        await localStoreUtil.store_data("lang", "en");
        await store.setWithRender("lang", language);
      }

      await this.getAllCoupons();

      await this.getMenuByType();
      if (this.props.match.url !== undefined && this.props.match.url !== "") {
        let split = `${this.props.match.url}`.split("/");
        if (split[2] === "orders2") {
          this.setState({
            mapRerender: true,
          });
        }
      }
      if (this.props.match.params.section_type) {
        let obj = {
          [this.props.match.params.section_type]: true,
        };
        this.setState({ url: this.props.match.params.section_type });
        this.RightMenuHandler(obj, this.props.match.params.section_type, {});
      } else {
        this.setState({ url: null });
      }
    } catch (error) {
      console.log(error, "Error");
      this.setState({ activity: false });
    }
  }

  getCategories = async () => {
    try {
      const getCategory = await this.props.store.get("categories");
      if (getCategory) {
        await this.setState({
          categories: getCategory,
        });
        let obj = {};
        const arrayList = [];
        getCategory.map((item) => {
          if (item?.code === "sides") {
            item.code = "dips";
          }
          if (item?.code !== "pizza") {
            obj = {
              tab: item?.code?.charAt(0).toUpperCase() + item?.code?.slice(1),
            };
            arrayList.push(obj);
          }
          return "";
        });
        this.setState((prevState) => ({
          tab1: {
            ...prevState.tab1,
            everythingElse: {
              title: "Everything Else",
              tabs: arrayList,
            },
          },
        }));
      } else {
        const categoryResponse = await apiServices.getCategories();
        await this.setState({
          categories: categoryResponse.data.data,
        });
        let obj = {};
        const arrayList = [];
        categoryResponse.data.data.map((item) => {
          if (item?.code === "sides") {
            item.code = "dips";
          }
          if (item?.code !== "pizza") {
            obj = {
              tab: item?.code?.charAt(0).toUpperCase() + item?.code?.slice(1),
            };
            arrayList.push(obj);
          }
          return "";
        });

        this.setState((prevState) => ({
          tab1: {
            ...prevState.tab1,
            everythingElse: {
              title: "Everything Else",
              tabs: arrayList,
            },
          },
        }));
        await this.props.store.setWithRender(
          "categories",
          categoryResponse.data.data
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Dynamic State Set
  customerFormOnChangeHandler = async (state, value) => {
    try {
      if (state === "countryCode") {
        await this.setState({ [state]: value.replace("+", "") });
      }

      // Testing White Space and setting reamining states too Start
      if (state === "customerLastName" || state === "customerEmail") {
        await this.setState({ [state]: value.trimStart() });
      }

      if (state === "customerGender") {
        await this.setState({ [state]: value });
      }

      if (
        state === "customerFirstName" ||
        state === "customerBlock" ||
        state === "customerBuilding" ||
        state === "customerFloor" ||
        state === "customerUnit" ||
        state === "customerNote"
      ) {
        if (
          state === "customerBlock" ||
          state === "customerBuilding" ||
          state === "customerFloor" ||
          state === "customerUnit" ||
          state === "customerNote" ||
          state === "customerAgentNote"
        ) {
          // eslint-disable-next-line
          const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

          if (!format.test(value.trimStart())) {
            await this.setState({ [state]: value.trimStart() });
          }
        } else {
          // eslint-disable-next-line
          const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

          if (!format.test(value.trimStart())) {
            await this.setState({ [state]: value.trimStart() });
          }
        }
      } else {
        await this.setState({ [state]: value }, () => {
          if (state === "deliveryInstructions") {
            this.setState({ isDeliveryInstructionEdited: true });
          }

          if (state === "serviceMethod" || state === "storeNameSelected") {
            let cartItems = [];
            this.state.itemList.map(async (item) => {
              if (item.type === "coupons") {
                let error = await getDealsError({
                  state: {
                    storeNameSelected: this.state.storeNameSelected,
                    serviceMethod: this.state.serviceMethod,
                    storeName: this.state.storeName,
                  },
                  deal: item?.coupons?.deal,
                });
                let obj = {
                  ...item,
                  error: error,
                };
                this.setState({
                  couponsComplete: error === "NoError" ? true : false,
                });
                cartItems.push(obj);
              } else {
                cartItems.push(item);
              }
            });

            this.setState({ itemList: cartItems });
          }
        });
      }

      // Customer Error Handling / Red Border Start
      if (state === "customerFirstName" && value && value?.length < 2) {
        if (this.state.phone) {
          await this.setState({
            customerErrors: { ...this.state.customerErrors, FirstName: false },
          });
        } else {
          await this.setState({
            customerErrors: {
              ...this.state.customerErrors,
              FirstName: false,
              phone: true,
            },
          });
        }
        this.customerErrorTest();
      }

      if (state === "serviceMethod") {
        if (this.state.phone) {
          await this.setState({
            customerErrors: {
              ...this.state.customerErrors,
              ServiceMethod: false,
            },
          });
        } else {
          await this.setState({
            customerErrors: {
              ...this.state.customerErrors,
              ServiceMethod: false,
              phone: true,
            },
          });
        }
        const customerSectionComplete = checkTrueValue(
          this.state.customerErrors
        );
        await this.setState({
          customerComplete: customerSectionComplete,
        });
      }

      if (state === "deliveryTime") {
        if (this.state.phone) {
          await this.setState({
            customerErrors: {
              ...this.state.customerErrors,
              DeliveryTime: false,
            },
          });
        } else {
          await this.setState({
            customerErrors: {
              ...this.state.customerErrors,
              DeliveryTime: false,
              phone: true,
            },
          });
        }
        const customerSectionComplete = checkTrueValue(
          this.state.customerErrors
        );
        await this.setState({
          customerComplete: customerSectionComplete,
        });
      }

      if (state === "customerStreet") {
        if (this.state.phone) {
          await this.setState({
            customerErrors: { ...this.state.customerErrors, Street: false },
          });
        } else {
          await this.setState({
            customerErrors: {
              ...this.state.customerErrors,
              Street: false,
              phone: true,
            },
          });
        }
        const customerSectionComplete = checkTrueValue(
          this.state.customerErrors
        );
        await this.setState({
          customerComplete: customerSectionComplete,
        });
      }

      // Customer Error Handling / Red Border End
    } catch (error) {
      console.log(error);
    }
  };

  //Modify Data
  getAndSetModifyData = async (orderData, cancel) => {
    this.setState({ activity: true });
    try {
      this.parentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      document.getElementById("parentDivScroll").scrollTop = 1;
      document.getElementById("parentDivScroll").scrollLeft = 1;

      if (orderData.serviceMethod === "delivery" || orderData?.address) {
        await this.getStoreByLocation(
          orderData?.address?.location[1],
          orderData?.address?.location[0]
        );
        let data = await this.state?.allStores?.filter((item) => {
          return item?._id === orderData?.store?._id;
        });
        await this.setState({
          storeNameSelected: data?.[0],
          storeName: data?.[0],
          openHour: data?.[0].openingHour,
          closeHour: data?.[0].closingHour,
        });
      } else {
        let data = await this.state?.allStores?.filter((item) => {
          return item?._id === orderData?.store?._id;
        });
        await this.setState({
          storeNameSelected: data?.[0],
          storeName: data?.[0],
          openHour: data?.[0].openingHour,
          closeHour: data?.[0].closingHour,
        });
      }

      let obj = {};
      let itemData = [];

      // eslint-disable-next-line array-callback-return
      for (let iddxx = 0; iddxx < orderData?.items.length; iddxx++) {
        const item = orderData?.items[iddxx];
        this.setState({ activity: true });
        if (
          item?.item?.categoryCode === "Pizza" &&
          (item?.item?.active || (!item?.item?.active && item?.item?.isReorder))
        ) {
          if (
            orderData?.usedCoupons?.length > 0 &&
            cancel !== "reOrder" &&
            item?.discountCode
          ) {
            this.setState({ freeCoupon: orderData?.usedCoupons?.[0] });
          }

          const matchStandard = (item?.item?.toppings || []).filter(
            (data, index) => {
              return !item?.standardToppings?.some((_topping) => {
                return _topping?._id === (data || {})._id;
              });
            }
          );
          let removeddToppings = {};
          // eslint-disable-next-line array-callback-return
          matchStandard?.map((item) => {
            removeddToppings = {
              ...removeddToppings,
              [item.productCode]: item.productCode,
            };
          });
          obj = {
            pizza: {
              item: { Whole: { ...item?.item, options: item?.item.toppings } },
              size:
                item?.size === "small"
                  ? "8"
                  : item?.size === "medium"
                  ? "10"
                  : item?.size === "large"
                  ? "12"
                  : "",
              cut: setCookingInstruction(item?.cookingInstructions).cuts,
              cook: setCookingInstruction(item?.cookingInstructions).cook,
              cookingInstructions: setCookingInstruction(
                item?.cookingInstructions
              ).cooking,
              cruust: item?.dough,
              edge: item?.edge,
              slices: item?.slices,
              toppings: {
                standard: {
                  Whole: item?.standardToppings,
                },
                extraTopping: {
                  Whole: item?.extraToppings,
                },
                removedToppings: {
                  Whole: removeddToppings,
                },
              },
            },
            error: "NoError",
            type: "pizza",
            subType: "Speciality Pizza",
            Qty: item?.quantity,
            price: item?.price,
          };
          if (cancel === "reOrder") {
            let obj1 = {
              item: item?.item?._id,
              dough: item?.dough?._id,
              edge: item?.edge?._id || undefined,
              standardToppings: item?.standardToppings?.map((stdItem) => {
                return {
                  stdTopping: stdItem?._id,
                  modifierQty: stdItem.toppingSize,
                };
              }),
              extraToppings:
                item?.extraToppings?.map((extItem) => {
                  return {
                    extTopping: extItem?._id,
                    modifierQty: extItem.toppingSize,
                  };
                }) || [],

              size: item?.size,
              quantity: item?.quantity,
              slices: item?.slices,
            };
            const taxResponse = await apiServices.getTax({
              items: [obj1],
              channel: "callcenter",
            });
            obj["price"] = Number(taxResponse?.data?.data?.paymentAmount);
          }

          if (item?.discountCode && cancel !== "reOrder") {
            let obj1 = {
              item: item?.item?._id,
              dough: item?.dough?._id,
              edge: item?.edge?._id || undefined,
              standardToppings: item?.standardToppings?.map((stdItem) => {
                return {
                  stdTopping: stdItem?._id,
                  modifierQty: stdItem.toppingSize,
                };
              }),
              extraToppings:
                item?.extraToppings?.map((extItem) => {
                  return {
                    extTopping: extItem?._id,
                    modifierQty: extItem.toppingSize,
                  };
                }) || [],

              size: item?.size,
              quantity: item?.quantity,
              slices: item?.slices,
            };
            const taxResponse = await apiServices.getTax({
              items: [obj1],
              channel: "callcenter",
            });
            obj["isDiscount"] = true;
            obj["showMenuAmount"] = true;
            obj["regularPrice"] = Number(
              taxResponse?.data?.data?.paymentAmount
            );
            obj["price"] = item?.price;
          }

          itemData.push(obj);
        } else if (item?.type === "half_half") {
          if (
            orderData?.usedCoupons?.length > 0 &&
            cancel !== "reOrder" &&
            item?.discountCode
          ) {
            this.setState({ freeCoupon: orderData?.usedCoupons?.[0] });
          }

          const matchStandardLeft = (
            item?.dealItems?.[0]?.item?.toppings || []
          ).filter((data, index) => {
            return !item?.dealItems?.[0]?.standardToppings?.some((_topping) => {
              return _topping?._id === (data || {})._id;
            });
          });
          let removeddToppingsLeft = {};
          // eslint-disable-next-line array-callback-return
          matchStandardLeft?.map((item) => {
            removeddToppingsLeft = {
              ...removeddToppingsLeft,
              [item.productCode]: item.productCode,
            };
          });

          const matchStandardRight = (
            item?.dealItems?.[1]?.item?.toppings || []
          ).filter((data, index) => {
            return !item?.dealItems?.[1]?.standardToppings?.some((_topping) => {
              return _topping?._id === (data || {})._id;
            });
          });
          let removeddToppingsRight = {};
          // eslint-disable-next-line array-callback-return
          matchStandardRight?.map((item) => {
            removeddToppingsRight = {
              ...removeddToppingsRight,
              [item.productCode]: item.productCode,
            };
          });
          obj = {
            pizza: {
              item: {
                Left: item?.dealItems?.[0]?.item,
                Right: item?.dealItems?.[1]?.item,
              },
              cut: setCookingInstruction(item?.cookingInstructions).cuts,
              cook: setCookingInstruction(item?.cookingInstructions).cook,
              cookingInstructions: setCookingInstruction(
                item?.cookingInstructions
              ).cooking,
              size:
                item?.size === "small"
                  ? "8"
                  : item?.size === "medium"
                  ? "10"
                  : item?.size === "large"
                  ? "12"
                  : "",
              cruust: item?.dough,
              edge: item?.edge,
              slices: item?.slices,
              toppings: {
                standard: {
                  Left: item?.dealItems?.[0]?.standardToppings,
                  Right: item?.dealItems?.[1]?.standardToppings,
                },
                extraTopping: {
                  Left: item?.dealItems?.[0]?.extraToppings,
                  Right: item?.dealItems?.[1]?.extraToppings,
                },
                removedToppings: {
                  Left: removeddToppingsLeft,
                  Right: removeddToppingsRight,
                },
              },
            },
            error: "NoError",
            type: "pizza",
            subType: "Half and Half",
            Qty: item?.quantity,
            price:
              !item?.discountCode && item?.actualPrice
                ? item?.actualPrice
                : item?.price,
          };

          if (cancel === "reOrder") {
            const items = [
              {
                item: item?.dealItems?.[0]?.item?._id,
                extraToppings: item?.dealItems?.[0]?.extraToppings?.map(
                  (extLeftItem) => {
                    return {
                      extTopping: extLeftItem._id,
                      modifierQty: extLeftItem.toppingSize,
                    };
                  }
                ),
                standardToppings: item?.dealItems?.[0]?.standardToppings?.map(
                  (stdLeftItem) => {
                    return {
                      stdTopping: stdLeftItem._id,
                      modifierQty: stdLeftItem.toppingSize,
                    };
                  }
                ),
              },
              {
                item: item?.dealItems?.[1]?.item?._id,
                extraToppings: item?.dealItems?.[1]?.extraToppings?.map(
                  (extRightItem) => {
                    return {
                      extTopping: extRightItem._id,
                      modifierQty: extRightItem.toppingSize,
                    };
                  }
                ),
                standardToppings: item?.dealItems?.[1]?.standardToppings?.map(
                  (stdRightItem) => {
                    return {
                      stdTopping: stdRightItem._id,
                      modifierQty: stdRightItem.toppingSize,
                    };
                  }
                ),
              },
            ];
            let obj1 = {
              type: "half_half",
              items: items,
              quantity: item?.quantity,
              dough: item?.dough,
              edge: item?.edge,
              slices: item?.slices,
              size: item?.size,
            };
            const taxResponse = await apiServices.getTax({
              items: [obj1],
              channel: "callcenter",
            });
            obj["price"] = Number(taxResponse?.data?.data?.paymentAmount);
          }

          if (item?.discountCode && cancel !== "reOrder") {
            const items = [
              {
                item: item?.dealItems?.[0]?.item?._id,
                extraToppings: item?.dealItems?.[0]?.extraToppings?.map(
                  (extLeftItem) => {
                    return {
                      extTopping: extLeftItem._id,
                      modifierQty: extLeftItem.toppingSize,
                    };
                  }
                ),
                standardToppings: item?.dealItems?.[0]?.standardToppings?.map(
                  (stdLeftItem) => {
                    return {
                      stdTopping: stdLeftItem._id,
                      modifierQty: stdLeftItem.toppingSize,
                    };
                  }
                ),
              },
              {
                item: item?.dealItems?.[1]?.item?._id,
                extraToppings: item?.dealItems?.[1]?.extraToppings?.map(
                  (extRightItem) => {
                    return {
                      extTopping: extRightItem._id,
                      modifierQty: extRightItem.toppingSize,
                    };
                  }
                ),
                standardToppings: item?.dealItems?.[1]?.standardToppings?.map(
                  (stdRightItem) => {
                    return {
                      stdTopping: stdRightItem._id,
                      modifierQty: stdRightItem.toppingSize,
                    };
                  }
                ),
              },
            ];
            let obj1 = {
              type: "half_half",
              items: items,
              quantity: item?.quantity,
              dough: item?.dough,
              edge: item?.edge,
              slices: item?.slices,
              size: item?.size,
            };
            const taxResponse = await apiServices.getTax({
              items: [obj1],
              channel: "callcenter",
            });
            obj["isDiscount"] = true;
            obj["showMenuAmount"] = true;
            obj["regularPrice"] = Number(
              taxResponse?.data?.data?.paymentAmount
            );
            obj["price"] = item?.price;
          }

          itemData.push(obj);
        } else if (item?.type === "deal") {
          // this.handleCouponClicked(item.deal);
          if (!isFreeDeal(item?.deal, cancel)) {
            const { storeNameSelected, storeName } = this.state;
            const allowedItemsResponse = await apiServices.getAllowedItems(
              item?.deal?._id
            );

            let error = await getDealsError({
              state: {
                storeNameSelected,
                serviceMethod: orderData.serviceMethod,
                storeName,
              },
              deal: item?.deal,
            });
            let newMyDealItems = [];
            let myDealItems = [];
            let DealItemDataaa = item.dealItems;
            // eslint-disable-next-line array-callback-return
            item.deal.dealItems.map((myITEM, iiddxx) => {
              myITEM.allowedItems =
                allowedItemsResponse.data.data.dealItems[iiddxx].allowedItems;
              for (let k = 0; k < item.dealItems.length; k++) {
                const ELE = item.dealItems[k];
                if (myITEM.defaultCategoryCode === "Pizza") {
                  if (myITEM.defaultCategoryCode === ELE?.item?.categoryCode) {
                    if (myITEM?.item === ELE?.item?._id) {
                      newMyDealItems.push({
                        ...ELE,
                        allowedItems:
                          allowedItemsResponse.data.data.dealItems[iiddxx]
                            .allowedItems,
                        minimumPrice: myITEM.minimumPrice,
                        maxDiscount: myITEM.maxDiscount,
                        defaultSizeCode: myITEM.defaultSizeCode,
                        defaultCategoryCode: myITEM.defaultCategoryCode,
                        regularPrice: myITEM.regularPrice,
                        dealCartIndex: iiddxx,
                      });
                      item.dealItems.splice(k, 1);
                      break;
                    } else if (
                      myITEM.allowedItems.some(
                        (lll) => lll?._id === ELE?.item?._id
                      )
                    ) {
                      newMyDealItems.push({
                        ...ELE,
                        allowedItems:
                          allowedItemsResponse.data.data.dealItems[iiddxx]
                            .allowedItems,
                        minimumPrice: myITEM.minimumPrice,
                        maxDiscount: myITEM.maxDiscount,
                        defaultSizeCode: myITEM.defaultSizeCode,
                        defaultCategoryCode: myITEM.defaultCategoryCode,
                        regularPrice: myITEM.regularPrice,
                        dealCartIndex: iiddxx,
                      });
                      item.dealItems.splice(k, 1);
                      break;
                    } else {
                      continue;
                    }
                  } else {
                    continue;
                  }
                } else {
                  if (myITEM.defaultCategoryCode === ELE?.item?.categoryCode) {
                    newMyDealItems.push({
                      ...ELE,
                      allowedItems:
                        allowedItemsResponse.data.data.dealItems[iiddxx]
                          .allowedItems,
                      minimumPrice: myITEM.minimumPrice,
                      maxDiscount: myITEM.maxDiscount,
                      defaultSizeCode: myITEM.defaultSizeCode,
                      defaultCategoryCode: myITEM.defaultCategoryCode,
                      regularPrice: myITEM.regularPrice,
                      dealCartIndex: iiddxx,
                    });
                    item.dealItems.splice(k, 1);
                    break;
                  } else {
                    continue;
                  }
                }
              }
            });
            newMyDealItems.sort((a, b) =>
              (a || {}).dealCartIndex > (b || {}).dealCartIndex ? 1 : -1
            );

            newMyDealItems?.map((uniqueItems, index) => {
              if (uniqueItems?.item?.categoryCode === "Pizza") {
                let price = 0,
                  priceStd = 0;
                price = Object.values(uniqueItems?.extraToppings).reduce(
                  (accVal, prevVal) => {
                    let Priceee;

                    if (prevVal?.toppingSize.toLowerCase() === "standard") {
                      Priceee =
                        prevVal.price[uniqueItems?.size][
                          "standard".toLowerCase()
                        ];
                    } else if (prevVal?.toppingSize.toLowerCase() === "light") {
                      Priceee =
                        prevVal.price[uniqueItems?.size]["light".toLowerCase()];
                    } else {
                      Priceee =
                        prevVal.price[uniqueItems?.size][
                          (prevVal?.toppingSize || "standard").toLowerCase()
                        ] +
                        prevVal.price[uniqueItems?.size][
                          "standard".toLowerCase()
                        ];
                    }

                    return accVal + Priceee;
                  },
                  0
                );
                priceStd = Object.values(uniqueItems?.standardToppings).reduce(
                  (accVal, prevVal) => {
                    let Priceee;
                    if (prevVal?.toppingSize.toLowerCase() === "standard") {
                      Priceee = 0;
                    } else if (prevVal?.toppingSize.toLowerCase() === "light") {
                      Priceee = 0;
                    } else {
                      Priceee =
                        prevVal.price[uniqueItems?.size][
                          (prevVal?.toppingSize || "standard").toLowerCase()
                        ] -
                        prevVal.price[uniqueItems?.size][
                          "standard"?.toLowerCase()
                        ];
                    }
                    return accVal + Priceee;
                  },
                  0
                );
                const matchStandard = (
                  uniqueItems?.item?.toppings || []
                ).filter((data, index) => {
                  return !uniqueItems?.standardToppings?.some((_topping) => {
                    return _topping?._id === (data || {})._id;
                  });
                });
                let removeddToppings = {};
                // eslint-disable-next-line array-callback-return
                matchStandard?.map((matchedItem) => {
                  removeddToppings = {
                    ...removeddToppings,
                    [matchedItem.productCode]: matchedItem.productCode,
                  };
                });
                myDealItems.push({
                  ...item?.deal?.dealItems[index],
                  allowedItems: uniqueItems.allowedItems,
                  pizza: {
                    item: {
                      Whole: {
                        ...uniqueItems?.item,
                        options: uniqueItems?.item?.toppings,
                      },
                    },
                    size: defaultSizeName[uniqueItems?.size],
                    cruust: uniqueItems?.dough,
                    edge: uniqueItems?.edge || undefined,
                    toppingPrice: price + priceStd,
                    toppings: {
                      standard: {
                        Whole: uniqueItems?.standardToppings,
                      },
                      removedToppings: {
                        Whole: removeddToppings,
                      },
                      extraTopping: {
                        Whole: uniqueItems?.extraToppings,
                      },
                    },
                  },
                  price:
                    (uniqueItems?.minimumPrice || 0) +
                    (price || 0) +
                    (priceStd || 0) +
                    (uniqueItems?.dough?.price[uniqueItems?.size] || 0) +
                    (uniqueItems?.edge?.price[uniqueItems?.size] || 0),
                  error: "NoError",
                  subType: "Speciality Pizza",
                  type: "pizza",
                  Qty: 1,
                  isCoupon: true,
                });

                const matchPizza = this.state.pizzaAllData?.filter(
                  (data, index) => {
                    return uniqueItems.allowedItems?.some((_topping) => {
                      return _topping._id === (data || {})._id;
                    });
                  }
                );
                this.setState({
                  pizzaAllData: matchPizza,
                });

                this.nextBtnHandler(0, matchPizza, 11, 1, "halfNhalfPizza");
                this.nextBtnHandler(0, matchPizza, 11, 1, "specialityPizza");
              } else if (uniqueItems?.item?.categoryCode === "Drinks") {
                var result = this.state.DrinkList.filter(function (o1) {
                  return uniqueItems.allowedItems.some(function (o2) {
                    return o1.flavorCode === o2.flavorCode; // return the ones with equal id
                  });
                });
                this.setState({
                  DrinkList: result,
                });
                myDealItems.push({
                  ...item?.deal?.dealItems[index],
                  defaultProduct: uniqueItems?.item,
                  allowedItems: uniqueItems.allowedItems,
                  everythingElse: {
                    flavorKey:
                      uniqueItems?.item?.flavorCode === "JALEME1" ||
                      uniqueItems?.item?.flavorCode === "QSU05" ||
                      uniqueItems?.item?.flavorCode === "ICETEA1" ||
                      uniqueItems?.item?.flavorCode === "_AYRAN"
                        ? true
                        : false,
                    item: uniqueItems?.item,
                    size: uniqueItems?.item?.sizeName?.en,
                  },
                  error: "NoError",
                  type: "everythingElse",
                  subType: capitalize(uniqueItems?.item?.type || ""),
                  Qty: uniqueItems?.quantity,
                  price: uniqueItems?.minimumPrice,
                  isCoupon: true,
                });
              } else {
                myDealItems.push({
                  ...item?.deal?.dealItems[index],
                  allowedItems: uniqueItems.allowedItems,
                  defaultProduct: uniqueItems?.item,
                  everythingElse: {
                    item: uniqueItems?.item,
                  },
                  error: "NoError",
                  type: "everythingElse",
                  subType: capitalize(uniqueItems?.item?.type || ""),
                  Qty: uniqueItems?.quantity,
                  price: uniqueItems?.minimumPrice,
                  isCoupon: true,
                });
              }
              return "";
            });

            obj = {
              coupons: {
                deal_id: item?.deal?._id,
                deal: {
                  ...item?.deal,
                  combined: allowedItemsResponse.data.data.combined,
                  subDeals: allowedItemsResponse.data.data.subDeals,
                },
                // subDeal: data,
                dealItems: item?.deal?.dealItems,
                myDealItems,
                isCombinedDeal: allowedItemsResponse.data.data.combined,
              },
              error: error,
              Qty: item?.quantity,
              type: "coupons",
              subType: "All Coupons",
              price: item.price,
              actualPrice: item.actualPrice,
            };
            if (item?.redeemedCoupon) {
              obj["redeemedCoupon"] = item?.redeemedCoupon;
            }
            itemData.push(obj);
            item.dealItems = DealItemDataaa;
          }
        } else {
          if (
            item?.item?.active ||
            (!item?.item?.active && item?.item?.isReorder)
          ) {
            if (
              orderData?.usedCoupons?.length > 0 &&
              cancel !== "reOrder" &&
              item?.discountCode
            ) {
              this.setState({ freeCoupon: orderData?.usedCoupons?.[0] });
            }

            if (item?.item?.type === "drink") {
              if (
                item?.item?.flavorCode === "QSU05" ||
                item?.item?.flavorCode === "SU" ||
                item?.item?.flavorCode === "ICETEA1" ||
                item?.item?.flavorCode === "ICETEA2" ||
                item?.item?.flavorCode === "ICETEA3" ||
                item?.item?.flavorCode === "JALEME1" ||
                item?.item?.flavorCode === "JALEME2" ||
                item?.item?.flavorCode === "JALEME3" ||
                item?.item?.flavorCode === "_AYRAN" ||
                item?.item?.flavorCode === "NAYRAN"
              ) {
                let DrinkData = [];
                let DrinkFlavor = [];

                if (
                  item?.item?.flavorCode === "_AYRAN" ||
                  item?.item?.flavorCode === "NAYRAN"
                ) {
                  // eslint-disable-next-line array-callback-return
                  let drink = this.state.DrinkList.filter((ALLDrink) => {
                    return ALLDrink?.flavorCode === item?.item?.flavorCode;
                  });
                  let fal =
                    drink[0]?.sizeVariants?.[item?.item?.flavorCode]?.[
                      "200 ML"
                    ];

                  obj = {
                    everythingElse: {
                      flavorKey: false,
                      flavor: fal[0],
                      item: drink[0],
                      size: item?.item?.sizeName?.en,
                    },
                    type: "everythingElse",
                    error: "NoError",
                    Qty: item?.quantity,
                    price: item?.price,
                    subType: "Drink",
                  };

                  if (
                    (item?.discountCode || item?.actualPrice > 0) &&
                    cancel === "reOrder"
                  ) {
                    let obj1 = {
                      item: drink[0]?._id,
                      quantity: item?.quantity,
                      price: item?.price,
                      cookingInstructions: "",
                    };
                    const taxResponse = await apiServices.getTax({
                      items: [obj1],
                      channel: "callcenter",
                    });
                    obj["price"] = Number(
                      taxResponse?.data?.data?.paymentAmount
                    );
                  }

                  if (item?.discountCode && cancel !== "reOrder") {
                    let obj1 = {
                      item: drink[0]?._id,
                      quantity: item?.quantity,
                      price: item?.price,
                      cookingInstructions: "",
                    };
                    const taxResponse = await apiServices.getTax({
                      items: [obj1],
                      channel: "callcenter",
                    });
                    obj["isDiscount"] = true;
                    obj["showMenuAmount"] = true;
                    obj["regularPrice"] = Number(
                      taxResponse?.data?.data?.paymentAmount
                    );
                    obj["price"] = item?.price;
                  }
                  itemData.push(obj);
                } else {
                  // eslint-disable-next-line array-callback-return
                  this.state.DrinkList.map((ALLDrink) => {
                    for (let abc = 0; abc < ALLDrink?.variants?.length; abc++) {
                      const element = ALLDrink?.variants[abc];

                      if (
                        element?.sizeName?.en === item?.item?.sizeName?.en ||
                        item.item.flavorCode === element.flavorCode
                      ) {
                        DrinkData.push(ALLDrink);
                        DrinkFlavor.push(element);
                      }
                    }
                  });

                  obj = {
                    everythingElse: {
                      flavorKey: false,
                      flavor: DrinkFlavor[0],
                      item: DrinkData[0],
                      size:
                        DrinkFlavor[0]?.flavorCode === "QSU05" ||
                        DrinkFlavor[0]?.flavorCode === "SU"
                          ? "500 ML"
                          : DrinkFlavor[0]?.flavorCode === "JALEME1" ||
                            DrinkFlavor[0]?.flavorCode === "JALEME2" ||
                            DrinkFlavor[0]?.flavorCode === "JALEME3"
                          ? "200 ML"
                          : item?.item?.flavorCode === "ICETEA1" ||
                            item?.item?.flavorCode === "ICETEA2" ||
                            item?.item?.flavorCode === "ICETEA3"
                          ? "330 ML"
                          : item?.item?.sizeName?.en,
                    },
                    type: "everythingElse",
                    error: "NoError",
                    Qty: item?.quantity,
                    price: item?.price,
                    subType: "Drink",
                  };

                  if (
                    (item?.discountCode || item?.actualPrice > 0) &&
                    cancel === "reOrder"
                  ) {
                    let obj1 = {
                      item: DrinkData[0]?._id,
                      quantity: item?.quantity,
                      price: item?.price,
                      cookingInstructions: "",
                    };
                    const taxResponse = await apiServices.getTax({
                      items: [obj1],
                      channel: "callcenter",
                    });
                    obj["price"] = Number(
                      taxResponse?.data?.data?.paymentAmount
                    );
                  }
                  if (item?.discountCode && cancel !== "reOrder") {
                    let obj1 = {
                      item: DrinkData[0]?._id,
                      quantity: item?.quantity,
                      price: item?.price,
                      cookingInstructions: "",
                    };
                    const taxResponse = await apiServices.getTax({
                      items: [obj1],
                      channel: "callcenter",
                    });
                    obj["isDiscount"] = true;
                    obj["showMenuAmount"] = true;
                    obj["regularPrice"] = Number(
                      taxResponse?.data?.data?.paymentAmount
                    );
                    obj["price"] = item?.price;
                  }
                  itemData.push(obj);
                }
              } else {
                let DrinkData = [];
                // eslint-disable-next-line array-callback-return
                this.state.DrinkList.map((ALLDrink) => {
                  for (let abc = 0; abc < ALLDrink?.variants?.length; abc++) {
                    const element = ALLDrink?.variants[abc];
                    if (
                      element?.sizeName?.en === item?.item?.sizeName?.en &&
                      item.item.flavorCode === element.flavorCode
                    ) {
                      DrinkData.push(ALLDrink);
                    }
                  }
                });
                obj = {
                  everythingElse: {
                    flavorKey: false,
                    item: DrinkData[0],
                    size: item?.item?.sizeName?.en,
                  },
                  type: "everythingElse",
                  error: "NoError",
                  Qty: item?.quantity,
                  price: item?.price,
                  subType: "Drink",
                };
                if (
                  (item?.discountCode || item?.actualPrice > 0) &&
                  cancel === "reOrder"
                ) {
                  let obj1 = {
                    item: DrinkData[0]?._id,
                    quantity: item?.quantity,
                    price: item?.price,
                    cookingInstructions: "",
                  };
                  const taxResponse = await apiServices.getTax({
                    items: [obj1],
                    channel: "callcenter",
                  });
                  obj["price"] = Number(taxResponse?.data?.data?.paymentAmount);
                }
                if (item?.discountCode && cancel !== "reOrder") {
                  let obj1 = {
                    item: DrinkData[0]?._id,
                    quantity: item?.quantity,
                    price: item?.price,
                    cookingInstructions: "",
                  };
                  const taxResponse = await apiServices.getTax({
                    items: [obj1],
                    channel: "callcenter",
                  });
                  obj["isDiscount"] = true;
                  obj["showMenuAmount"] = true;
                  obj["regularPrice"] = Number(
                    taxResponse?.data?.data?.paymentAmount
                  );
                  obj["price"] = item?.price;
                }
                itemData.push(obj);
              }
            } else {
              obj = {
                everythingElse: {
                  item: item.item,
                },
                type: "everythingElse",
                error: "NoError",
                Qty: item?.quantity,
                price: item?.price,
                subType: item?.item?.categoryCode,
              };

              if (
                // (item?.discountCode || item?.actualPrice > 0) &&
                cancel === "reOrder"
              ) {
                let obj1 = {
                  item: item?.item?._id,
                  quantity: item?.quantity,
                  price: item?.price,
                  cookingInstructions: "",
                };
                const taxResponse = await apiServices.getTax({
                  items: [obj1],
                  channel: "callcenter",
                });
                obj["price"] = Number(taxResponse?.data?.data?.paymentAmount);
              }
              if (item?.discountCode && cancel !== "reOrder") {
                let obj1 = {
                  item: item?.item?._id,
                  quantity: item?.quantity,
                  price: item?.price,
                  cookingInstructions: "",
                };
                const taxResponse = await apiServices.getTax({
                  items: [obj1],
                  channel: "callcenter",
                });
                obj["isDiscount"] = true;
                obj["showMenuAmount"] = true;
                obj["regularPrice"] = Number(
                  taxResponse?.data?.data?.paymentAmount
                );
                obj["price"] = item?.price;
              }
              itemData.push(obj);
            }
          }
        }
      }

      if (cancel === "reOrder" && !orderData?.promoCodeApplied?.promoCodeType) {
        itemData = await this.removeBirthdayCoupon(itemData);
      }

      await localStoreUtil.store_data("prevous_data", itemData);
      await localStoreUtil.store_data(
        "prevous_data_redeemed",
        orderData?.usedCoupons
      );

      await this.setState({
        serviceMethod: orderData.serviceMethod,
        deliveryTime:
          cancel === "reOrder"
            ? "now"
            : orderData?.futureOrder
            ? "later"
            : "now",
        selectedDateTime:
          cancel === "reOrder"
            ? {
                selectedHour: "",
                selectedDate: "",
              }
            : orderData?.futureOrder
            ? {
                selectedHour: `${moment(new Date(orderData?.deliveryTime))
                  .utc()
                  .format("HH:mm")}`,
                selectedDate: `${moment(new Date(orderData?.deliveryTime))
                  .utc()
                  .format("DD.MMM.YYYY")}`,
              }
            : {
                selectedHour: "",
                selectedDate: "",
              },
        customerStreet: orderData?.address,
        customerBlock: orderData?.address?.streetName,
        customerBuilding: orderData?.address?.building,
        customerFloor: orderData?.address?.floor,
        customerUnit: orderData?.address?.streetNumber,
        customerAddressTitle: orderData?.address?.name,
        itemList: itemData,
        myPreData: itemData,
        deletedItems: itemData,
        customer: true,
        exitOrder: false,
        driverInstruction: orderData?.driverInstruction,
        modifyClick: cancel === "modify" ? true : false,
      });

      await this.customerErrorTest();
      await this.checkSelectedItemError(itemData);

      if (cancel === "cancel") {
        await this.RightMenuHandler({ exitOrder: true }, "exitOrder", {}, "");
        await this.setState({
          OrderId: orderData?._id,
        });
      }

      if (cancel === "modify") {
        await this.setState({
          OrderId: orderData?._id,
          preData: orderData,
          selectedTab: "Modify Order",
          selectedFixTab: "Modify Order",
        });

        await this.RightMenuHandler({ pizza: true }, "pizza", {});
      }

      if (cancel === "reOrder") {
        await this.setState({
          OrderId: orderData?._id,
          selectedTab: "Re-Order",
          selectedFixTab: "Re-Order",
        });
        await this.RightMenuHandler({ pizza: true }, "pizza", {});
      }

      this.setState({ activity: false });
      this.parentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      document.getElementById("parentDivScroll").scrollTop = 1;
      document.getElementById("parentDivScroll").scrollLeft = 1;
    } catch (error) {
      this.setState({ activity: false });
      console.log("error", error);
    }
  };

  removeBirthdayCoupon = (updateCart) => {
    try {
      let arr = [];
      for (let index = 0; index < updateCart.length; index++) {
        const element = updateCart[index];
        let present;
        if (
          (element?.type === "pizza" && element?.subType !== "Half and Half") ||
          element?.prodType === "custom"
        ) {
          present = arr?.findIndex(
            (item) =>
              `${item?.pizza?.item?.Whole?._id}` ===
                `${element?.pizza?.item?.Whole?._id}` &&
              item?.pizza?.size === element?.pizza?.size
          );
        } else if (
          element?.type === "pizza" &&
          element?.subType === "Half and Half"
        ) {
          present = arr?.findIndex(
            (item) =>
              `${item?.pizza?.item?.Left?._id}` ===
                `${element?.pizza?.item?.Left?._id}` &&
              `${item?.pizza?.item?.Right?._id}` ===
                `${element?.pizza?.item?.Right?._id}` &&
              item?.pizza?.size === element?.pizza?.size
          );
        } else if (element?.type === "coupons") {
          present = -1;
        } else {
          present = arr?.findIndex(
            (item) =>
              `${item?.everythingElse?.item?._id}` ===
              `${element?.everythingElse?.item?._id}`
          );
        }

        if (present > -1) {
          arr[present] = {
            ...element,
            Qty: element?.Qty + arr[present]?.Qty,
            price: element?.price + arr[present]?.price,
          };
        } else {
          arr.push(element);
        }
      }

      return arr;
    } catch (error) {
      console.log("removeBirthdayCoupon", error);
    }
  };

  checkSelectedItemError = async (itemListGet) => {
    if (itemListGet.length > 0) {
      //Pizza k Lye Hy Ye
      const pizzaCount = await itemListGet?.filter(
        (pizzaI) => pizzaI?.type === "pizza"
      );
      const pizzaErrorCount = await itemListGet?.filter(
        (pizzaI) => pizzaI?.type === "pizza" && pizzaI?.error !== "NoError"
      );
      if (pizzaCount?.length > 0) {
        if (pizzaErrorCount?.length === 0) {
          await this.setState({ pizzaComplete: true });
        } else {
          await this.setState({ pizzaComplete: false });
        }
      } else {
        await this.setState({ pizzaComplete: "" });
      }

      //EveryThingElse k Lye Hy Ye
      const everythingElseCount = await itemListGet?.filter(
        (everythingElseI) => everythingElseI?.type === "everythingElse"
      );
      const everythingElseErrorCount = await itemListGet?.filter(
        (everythingElseI) =>
          everythingElseI?.type === "everythingElse" &&
          everythingElseI?.error !== "NoError"
      );
      if (everythingElseCount?.length > 0) {
        if (everythingElseErrorCount?.length === 0) {
          await this.setState({ everyThingComplete: true });
        } else {
          await this.setState({ everyThingComplete: false });
        }
      } else {
        await this.setState({ everyThingComplete: "" });
      }

      //Coupons k Lye Hy Ye
      const CouponCount = await itemListGet?.filter(
        (couponI) => couponI?.type === "coupons"
      );
      if (CouponCount?.length > 0) {
        const couponsErrorCount = await itemListGet?.filter(
          (couponsI) =>
            couponsI?.type === "coupons" && couponsI?.error !== "NoError"
        );
        if (couponsErrorCount?.length === 0) {
          CouponCount?.map(async (couponsIPizza) => {
            const couponsPizzaErrorCount =
              couponsIPizza?.coupons?.myDealItems?.filter(
                (i) => i?.type === "pizza" && i?.error !== "NoError"
              );
            const couponsPizzaCount =
              couponsIPizza?.coupons?.myDealItems?.filter(
                (i) => i?.type === "pizza"
              );
            if (couponsPizzaCount?.length > 0 || pizzaCount?.length > 0) {
              if (
                couponsPizzaErrorCount?.length === 0 &&
                pizzaErrorCount?.length === 0
              ) {
                await this.setState({ pizzaComplete: true });
              } else {
                await this.setState({ pizzaComplete: false });
              }
            } else {
              await this.setState({ pizzaComplete: "" });
            }
            const couponsEveryThingCount =
              couponsIPizza?.coupons?.myDealItems?.filter(
                (i) => i?.type === "everythingElse"
              );
            const couponsEveryThingErrorCount =
              couponsIPizza?.coupons?.myDealItems?.filter(
                (i) => i?.type === "everythingElse" && i?.error !== "NoError"
              );

            if (
              everythingElseCount?.length > 0 ||
              couponsEveryThingCount?.length > 0
            ) {
              if (
                couponsEveryThingErrorCount?.length === 0 &&
                everythingElseErrorCount?.length === 0
              ) {
                await this.setState({ everyThingComplete: true });
              } else {
                await this.setState({ everyThingComplete: false });
              }
            } else {
              await this.setState({ everyThingComplete: "" });
            }
          });
          await this.setState({ couponsComplete: true });
        } else {
          CouponCount?.map(async (couponsIPizza) => {
            const couponsPizzaErrorCount =
              couponsIPizza?.coupons?.myDealItems?.filter(
                (i) => i?.type === "pizza" && i?.error !== "NoError"
              );
            const couponsPizzaCount =
              couponsIPizza?.coupons?.myDealItems?.filter(
                (i) => i?.type === "pizza"
              );
            if (couponsPizzaCount?.length > 0 || pizzaCount?.length > 0) {
              if (
                couponsPizzaErrorCount?.length === 0 &&
                pizzaErrorCount?.length === 0
              ) {
                await this.setState({ pizzaComplete: true });
              } else {
                await this.setState({ pizzaComplete: false });
              }
            } else {
              await this.setState({ pizzaComplete: "" });
            }
            const couponsEveryThingCount =
              couponsIPizza?.coupons?.myDealItems?.filter(
                (i) => i?.type === "everythingElse"
              );
            const couponsEveryThingErrorCount =
              couponsIPizza?.coupons?.myDealItems?.filter(
                (i) => i?.type === "everythingElse" && i?.error !== "NoError"
              );

            if (
              everythingElseCount?.length > 0 ||
              couponsEveryThingCount?.length > 0
            ) {
              if (
                couponsEveryThingErrorCount?.length === 0 &&
                everythingElseErrorCount?.length === 0
              ) {
                await this.setState({ everyThingComplete: true });
              } else {
                await this.setState({ everyThingComplete: false });
              }
            } else {
              await this.setState({ everyThingComplete: "" });
            }
          });
          await this.setState({ couponsComplete: false });
        }
      } else {
        await this.setState({ couponsComplete: "" });
      }
    } else {
      await this.setState({
        pizzaComplete: "",
        everyThingComplete: "",
        couponsComplete: "",
      });
    }
  };

  //State Ko Toggle Kary Ga Dynimacaly//
  stateToggleHandler = async (name) => {
    try {
      await this.setState({ [name]: !this.state[name] });
    } catch (error) {
      console.log(error);
    }
  };

  //Exit Order (Reson for Exit)
  exitOrderHandler = async () => {
    try {
      this.setState({ activity: true });

      let { store } = this.props;
      let ResponseCheck = store.get("pizza");
      let data = "";
      if (ResponseCheck) {
        data = ResponseCheck;
      } else {
        const ownPizaResponse = await apiServices.getMakeYourOwnPiza();
        const pizzaResponse = await apiServices.getMenuByType("pizza");
        data = await pizzaResponse.data.data;
        ownPizaResponse.data.data.options = ownPizaResponse.data.data.toppings;
        data.unshift(ownPizaResponse.data.data);
        await store.setWithRender("pizza", data);
      }
      await this.setState({ pizzaAllData: data });
      await this.clearState();
      await this.setState({
        ...initailState,
        ...initailStateCheck,
        exitOrder: false,
        customer: true,
        finishComplete: "",
        itemList: [],
        selectedPizza: {},
      });
      await this.setState({
        tabTitle: this.state.tab1["customer"]?.title,
        tabs: this.state.tab1["customer"]?.tabs,
        selectedTab: this.state.tab1["customer"]?.tabs[0]?.tab,
      });
      this.props.store.setWithRender("isUrlChanged", true);
      this.props.history.push({ pathname: `/app/orders/${"customer"}` });
      await this.setState({
        customerComplete: "",
        running: false,
        url: "customer",
        customerErrors: {
          ...this.state.customerErrors,
          FirstName: false,
          ServiceMethod: false,
          DeliveryTime: false,
          Street: false,
          Store: true,
          phone: false,
          DeliveryStore: true,
        },
        activity: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Dynamically set count for Nevigations
  countHanler = (count, name) => {
    this.setState({ [name]: count });
  };

  //Phone State
  handleChangeMobile = (e) => {
    try {
      // const value = e.target.value;
      if (isNumber(e.target.value) || e.target.value === "") {
        this.setState({ phone: e.target.value });
      } else {
        this.setState((prevState) => ({ phone: prevState.phone + "" }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Cancel Order on reson click
  cancelOrder = async (item) => {
    this.setState({ activity: true });
    try {
      const data = {
        reason: item.toLowerCase(),
        order: this.state?.OrderId,
      };
      const agentData = await localStoreUtil.get_data("agent");
      let cancelOrderResponse;
      if (agentData?.isIntern) {
        cancelOrderResponse = await apiServices.cancelOrderByIdInternee({
          order: this.state?.OrderId,
        });

        if (cancelOrderResponse?.status === 200) {
          this.setState({
            ...initailState,
            exitOrder: false,
            ...initailStateCheck,

            customer: true,
            itemList: [],
            selectedPizza: {},
            OrderId: "",
          });
          toast.success(
            cancelOrderResponse?.message || "Order Cancelled Successfully"
          );
          this.exitOrderHandler();
        } else {
          toast.error(`${cancelOrderResponse?.message} ❕`);
        }
      } else {
        cancelOrderResponse = await apiServices.cancelOrderById(data);

        if (cancelOrderResponse?.status === 200) {
          this.setState({
            ...initailState,
            exitOrder: false,
            ...initailStateCheck,

            customer: true,
            itemList: [],
            selectedPizza: {},
            OrderId: "",
          });
          toast.success(
            cancelOrderResponse?.message || "Order Cancelled Successfully"
          );
          this.exitOrderHandler();
        } else {
          toast.error(`${cancelOrderResponse?.message} ❕`);
        }
      }

      this.setState({ activity: false });
    } catch (error) {
      this.setState({ activity: false });
    }
  };

  clearState = async () => {
    try {
      this.setState(
        {
          ...initailState,
          ...initailStateCheck,
          //Side Button Group Start
          customer: true,
          orderFinish: false,
          exitOrder: false,
          pizza: false,
          everythingElse: false,
          coupons: false,
          finish: false,
          //Side Button Group End
          couponsComplete: "",
          pizzaComplete: "",
          everyThingComplete: "",
          customerComplete: "",
          //Pizza Section End,
          storeName: {},
          storeNameSelected: {},
          itemList: [],
        },
        () => this.customerErrorTest()
      );
    } catch (error) {
      console.log(error);
    }
  };

  clearStateForCus = async () => {
    try {
      this.setState(
        {
          ...initailState,
          //Side Button Group Start
          customer: true,
          orderFinish: false,
          exitOrder: false,
          pizza: false,
          everythingElse: false,
          coupons: false,
          finish: false,
          //Side Button Group End
          customerComplete: "",
          //Pizza Section End,
          storeName: {},
        },
        () => this.customerErrorTest()
      );
    } catch (error) {
      console.log(error);
    }
  };

  //get user by phone will search customer by phone number
  getUserByPhone = async (e) => {
    e.preventDefault();
    this.setState({ activity: true });
    if (this.state?.freeCoupon?.text) {
      this.removeFreeCoupon();
    }
    // await this.setState({ customerComplete: true });
    //prevent default page reload on form submit
    try {
      if (this.state.phone.length > 8) {
        const data = {
          countryCode: this.state.countryCode?.replace("+", ""),
          mobile: this.state.phone.startsWith("0")
            ? this.state.phone.replace(/^0+/, "")
            : this.state.phone,
        };

        // await this.customerErrorTest();
        const response = await apiServices.getUserByPhone(data);
        this.clearStateForCus();
        if (response.data.status === 200) {
          const recentO = response?.data?.data?.recentOrder.length - 1;
          if (
            response.data.data.user.name !== "" &&
            response.data.data.user.name !== undefined
          ) {
            this.setState({
              customerErrors: {
                ...this.state.customerErrors,
                FirstName: false,
              },
            });
          }

          if (response.data.data.user.banned === true) {
            toast.error("This Customer is Banned");
          }

          if (response.data.data.user.customerType === "pulse") {
            toast.success("Pulse Customer 🙂");
          }

          let filterAddress = response.data.data.address.filter(
            (item) => item.addedByTraineeAgent !== true
          );

          if (this.state.agentData?.isIntern === true) {
            this.setState({
              newCustomer: false,
              customerAddress: response.data.data.address,
              customerAddressLength: response.data.data.address?.length,
              pastOrders: response.data.data.pastOrders,
              rewards: response.data.data.coupons,
              newPastOrder: _.isEmpty(response.data.data.pastOrders)
                ? []
                : await detailPanelOrder(response.data.data.pastOrders[0]),
              resentOrders: response.data.data.recentOrder,
              customerFirstName: response.data.data.user.name,
              customerLastName: response.data.data.user.surname,
              customerEmail: response.data.data.user.email,
              customerGender:
                response.data.data.user.gender?.toLowerCase() ?? "",
              customerObj: response.data.data.user,
              deliveryInstructions:
                response?.data?.data?.recentOrder?.[recentO]
                  ?.deliveryInstructions || "",
              deliveryInstructionsCopy:
                response?.data?.data?.recentOrder?.[recentO]
                  ?.deliveryInstructions || "",
              customerType: response.data.data.user.customerType,
              customerPulseAddress: response.data.data.user.pulseAddress,
              customerAgentNote: response.data.data.user.agentNote,
            });
          } else {
            await this.setState(
              {
                newCustomer: false,
                customerAddress: filterAddress,
                customerAddressLength: filterAddress?.length,
                pastOrders: response.data.data.pastOrders,
                rewards: response.data.data.coupons,
                newPastOrder: _.isEmpty(response.data.data.pastOrders)
                  ? []
                  : await detailPanelOrder(response.data.data.pastOrders[0]),
                resentOrders: response.data.data.recentOrder,
                customerFirstName: response.data.data.user.name,
                customerLastName: response.data.data.user.surname,
                customerEmail: response.data.data.user.email,
                customerGender:
                  response.data.data.user.gender?.toLowerCase() ?? "",
                customerObj: response.data.data.user,
                deliveryInstructions:
                  response?.data?.data?.recentOrder?.[recentO]
                    ?.deliveryInstructions || "",
                deliveryInstructionsCopy:
                  response?.data?.data?.recentOrder?.[recentO]
                    ?.deliveryInstructions || "",
                customerType: response.data.data.user.customerType,
                customerPulseAddress: response.data.data.user.pulseAddress,
                customerAgentNote: response.data.data.user.agentNote,
              },
              () => {
                if (this.state.nearToExpiredCoupons === false) {
                  // eslint-disable-next-line
                  response.data.data?.coupons.map((item) => {
                    let name = Object.keys(
                      lateOrderCouponCheck(item, item?.couponType)
                    );

                    if (name[1] === "nearToExpired") {
                      return this.setState({
                        nearToExpiredCoupons: true,
                      });
                    }
                  });
                }
              }
            );
          }
        } else {
          await this.setState({
            newCustomer: true,
            customerAddress: [],
            pastOrders: [],
            resentOrders: [],
            customerFirstName: "",
            customerLastName: "",
            customerEmail: "",
            customerGender: "",
            customerStreet: "",
            customerBlock: "",
            customerBuilding: "",
            customerFloor: "",
            customerUnit: "",
            customerNote: "",
            customerAddressTitle: "",
            customerAgentNote: "",
            storeName: [],
            customerLocation: [],
            customerObj: {},
          });

          if (response.data.status === 400) {
            toast.success("New Customer 🙂");
          } else {
            toast.error(`${capitalize(response.data.message || "")} ❕`);
          }
        }
        this.customerErrorTest();
        this.setState({ activity: false, running: true });
        this.startTimer();
      } else {
        toast.error(`${capitalize("Invalid Mobile Number")} ❕`);
        await this.setState({
          newCustomer: true,
          customerAddress: [],
          pastOrders: [],
          resentOrders: [],
          customerFirstName: "",
          customerLastName: "",
          customerEmail: "",
          customerGender: "",
          customerStreet: "",
          customerBlock: "",
          customerBuilding: "",
          customerFloor: "",
          customerUnit: "",
          customerNote: "",
          customerAgentNote: "",
          customerAddressTitle: "",
          storeName: [],
          customerLocation: [],
          customerObj: {},
        });
        this.customerErrorTest();

        this.setState({ activity: false, running: true });
      }
    } catch (error) {
      this.setState({ activity: false });
      console.log(error);
    }
  };

  startTimer = () => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  getToken = async () => {
    const token = await localStoreUtil.get_data("token");
    if (!token) {
      await localStoreUtil.remove_all();
      window.location.reload();
    }
  };

  //Group Buttons Switching And Tab Selection And Everything Else Data
  RightMenuHandler = async (
    obj,
    value,
    selected,
    subType,
    isDealItems,
    carIndex
  ) => {
    this.setState({ activity: true });
    this.getToken();

    if (value === "finish") {
      const { customerErrors, itemList } = this.state;
      if (
        customerErrors === undefined ||
        !customerErrors ||
        customerErrors?.phone ||
        customerErrors?.FirstName ||
        customerErrors?.ServiceMethod ||
        customerErrors?.DeliveryTime ||
        customerErrors?.Street ||
        customerErrors?.Store
      ) {
        toast.error("Missing Feilds in Customer Section ❕");
        obj = { customer: true };
        value = "customer";
        selected = {};
        subType = undefined;
        isDealItems = undefined;
        carIndex = undefined;
        try {
          await this.setState({
            exitOrder: false,
            customer: false,
            pizza: false,
            everythingElse: false,
            coupons: false,
            finish: false,
            laterDeliveryValue: false,
            addPiza: true,
            selectedPizza: selected,
            selectDealItems: isDealItems,
            listIndexSet: carIndex >= 0 ? carIndex : "",
          });
          await this.setState(obj);
          if (subType === undefined) {
            await this.setState({
              tabTitle: this.state.tab1[value]?.title,
              tabs: this.state.tab1[value]?.tabs,
              selectedTab: this.state.tab1[value]?.tabs[0]?.tab,
            });
            if (this.state.everythingElse) {
              const Response = await apiServices.getMenuByType(
                this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase()
              );

              if (
                this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase() === "drink"
              ) {
                const data = Response.data.data;
                let ayran = {};
                let ayranVariants = [];
                // eslint-disable-next-line no-unused-vars
                const variantData = data.filter((item) => {
                  if (item.flavorCode === "_AYRAN") {
                    const newObj = _.omit(item.variants[0], "sizeName");
                    ayran = { ...item };
                    ayranVariants.push({
                      ...newObj,
                      flavourName: item.name,
                    });
                  } else if (item.flavorCode === "NAYRAN") {
                    const newObj = _.omit(item.variants[0], "sizeName");
                    ayranVariants.push({
                      ...newObj,
                      flavourName: item.name,
                    });
                  }

                  return (
                    item.flavorCode === "_AYRAN" || item.flavorCode === "NAYRAN"
                  );
                });
                const showDrinks = Response.data.data.filter(
                  (item) =>
                    item.flavorCode !== "_AYRAN" && item.flavorCode !== "NAYRAN"
                );
                showDrinks.push({
                  ...ayran,
                  variants: ayranVariants,
                });
                await this.setState({
                  EveryThingItemList: Response.data.data,
                  DrinkList: showDrinks,
                });
              } else {
                await this.setState({
                  EveryThingItemList: Response.data.data,
                });
              }
            }
          } else {
            let index = this.state.tab1[value]?.tabs.findIndex(
              (x) => x.tab === subType
            );
            await this.setState({
              tabTitle: this.state.tab1[value]?.title,
              tabs: this.state.tab1[value]?.tabs,
              selectedTab: this.state.tab1[value]?.tabs[index]?.tab,
            });
            if (this.state.everythingElse) {
              let valueToSearch =
                this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase();
              if (
                this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase() ===
                "dips"
              ) {
                valueToSearch = "sides";
              }
              const Response = await apiServices.getMenuByType(valueToSearch);
              if (valueToSearch === "Drink") {
                const data = Response.data.data;
                let ayran = {};
                let ayranVariants = [];
                // eslint-disable-next-line no-unused-vars
                const variantData = data.filter((item) => {
                  if (item.flavorCode === "_AYRAN") {
                    const newObj = _.omit(item.variants[0], "sizeName");
                    ayran = { ...item };
                    ayranVariants.push({
                      ...newObj,
                      flavourName: item.name,
                    });
                  } else if (item.flavorCode === "NAYRAN") {
                    const newObj = _.omit(item.variants[0], "sizeName");
                    ayranVariants.push({
                      ...newObj,
                      flavourName: item.name,
                    });
                  }

                  return (
                    item.flavorCode === "_AYRAN" || item.flavorCode === "NAYRAN"
                  );
                });
                const showDrinks = Response.data.data.filter(
                  (item) =>
                    item.flavorCode !== "_AYRAN" && item.flavorCode !== "NAYRAN"
                );
                showDrinks.push({
                  ...ayran,
                  variants: ayranVariants,
                });

                await this.setState({
                  EveryThingItemList: Response.data.data,
                  DrinkList: showDrinks,
                });
              } else {
                await this.setState({
                  EveryThingItemList: Response.data.data,
                });
              }
            }
            // this.SelectedTabChange(null,subType)
          }

          this.setState({ url: value, activity: false });
          this.props.store.setWithRender("isUrlChanged", true);
          this.props.history.push({ pathname: `/app/orders/${value}` });
        } catch (error) {
          this.setState({ activity: false });

          console.log(error);
        }
      } else if (itemList.length === 0) {
        toast.error("Order Cart Is Empty ❕");
        obj = { pizza: true };
        value = "pizza";
        selected = {};
        subType = undefined;
        isDealItems = undefined;
        carIndex = undefined;
        try {
          await this.setState({
            exitOrder: false,
            customer: false,
            pizza: false,
            everythingElse: false,
            coupons: false,
            finish: false,
            laterDeliveryValue: false,
            addPiza: true,
            selectedPizza: selected,
            selectDealItems: isDealItems,
            listIndexSet: carIndex >= 0 ? carIndex : "",
          });
          await this.setState(obj);
          if (subType === undefined) {
            await this.setState({
              tabTitle: this.state.tab1[value]?.title,
              tabs: this.state.tab1[value]?.tabs,
              selectedTab: this.state.tab1[value]?.tabs[0]?.tab,
            });
            if (this.state.everythingElse) {
              const Response = await apiServices.getMenuByType(
                this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase()
              );

              if (
                this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase() === "drink"
              ) {
                const data = Response.data.data;
                let ayran = {};
                let ayranVariants = [];
                // eslint-disable-next-line no-unused-vars
                const variantData = data.filter((item) => {
                  if (item.flavorCode === "_AYRAN") {
                    const newObj = _.omit(item.variants[0], "sizeName");
                    ayran = { ...item };
                    ayranVariants.push({
                      ...newObj,
                      flavourName: item.name,
                    });
                  } else if (item.flavorCode === "NAYRAN") {
                    const newObj = _.omit(item.variants[0], "sizeName");
                    ayranVariants.push({
                      ...newObj,
                      flavourName: item.name,
                    });
                  }

                  return (
                    item.flavorCode === "_AYRAN" || item.flavorCode === "NAYRAN"
                  );
                });
                const showDrinks = Response.data.data.filter(
                  (item) =>
                    item.flavorCode !== "_AYRAN" && item.flavorCode !== "NAYRAN"
                );
                showDrinks.push({
                  ...ayran,
                  variants: ayranVariants,
                });

                await this.setState({
                  EveryThingItemList: Response.data.data,
                  DrinkList: showDrinks,
                });
              } else {
                await this.setState({
                  EveryThingItemList: Response.data.data,
                });
              }
            }
          } else {
            let index = this.state.tab1[value]?.tabs.findIndex(
              (x) => x.tab === subType
            );
            await this.setState({
              tabTitle: this.state.tab1[value]?.title,
              tabs: this.state.tab1[value]?.tabs,
              selectedTab: this.state.tab1[value]?.tabs[index]?.tab,
            });
            if (this.state.everythingElse) {
              let valueToSearch =
                this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase();
              if (
                this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase() ===
                "dips"
              ) {
                valueToSearch = "sides";
              }
              const Response = await apiServices.getMenuByType(valueToSearch);
              if (valueToSearch === "Drink") {
                const data = Response.data.data;
                let ayran = {};
                let ayranVariants = [];
                // eslint-disable-next-line no-unused-vars
                const variantData = data.filter((item) => {
                  if (item.flavorCode === "_AYRAN") {
                    const newObj = _.omit(item.variants[0], "sizeName");
                    ayran = { ...item };
                    ayranVariants.push({
                      ...newObj,
                      flavourName: item.name,
                    });
                  } else if (item.flavorCode === "NAYRAN") {
                    const newObj = _.omit(item.variants[0], "sizeName");
                    ayranVariants.push({
                      ...newObj,
                      flavourName: item.name,
                    });
                  }

                  return (
                    item.flavorCode === "_AYRAN" || item.flavorCode === "NAYRAN"
                  );
                });
                const showDrinks = Response.data.data.filter(
                  (item) =>
                    item.flavorCode !== "_AYRAN" && item.flavorCode !== "NAYRAN"
                );
                showDrinks.push({
                  ...ayran,
                  variants: ayranVariants,
                });

                await this.setState({
                  EveryThingItemList: Response.data.data,
                  DrinkList: showDrinks,
                });
              } else {
                await this.setState({
                  EveryThingItemList: Response.data.data,
                });
              }
            }
            // this.SelectedTabChange(null,subType)
          }

          this.setState({ url: value });
          this.props.store.setWithRender("isUrlChanged", true);
          this.props.history.push({ pathname: `/app/orders/${value}` });
        } catch (error) {
          console.log(error);
        }
      } else if (itemList.length > 0) {
        for (let index = 0; index < itemList.length; index++) {
          const item = itemList[index];
          if (item.error !== "NoError") {
            obj = { [item.type]: true };
            value = item.type;
            selected = {};
            subType = item.subType;
            isDealItems = undefined;
            carIndex = undefined;
            try {
              toast.error(`${item.error} ❕`);
              await this.setState({
                exitOrder: false,
                customer: false,
                pizza: false,
                everythingElse: false,
                coupons: false,
                finish: false,
                laterDeliveryValue: false,
                addPiza: true,
                selectedPizza: selected,
                selectDealItems: isDealItems,
                listIndexSet: carIndex >= 0 ? carIndex : "",
              });
              await this.setState(obj);
              if (subType === undefined) {
                await this.setState({
                  tabTitle: this.state.tab1[value]?.title,
                  tabs: this.state.tab1[value]?.tabs,
                  selectedTab: this.state.tab1[value]?.tabs[0]?.tab,
                });
                if (this.state.everythingElse) {
                  const Response = await apiServices.getMenuByType(
                    this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase()
                  );

                  if (
                    this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase() ===
                    "drink"
                  ) {
                    const data = Response.data.data;
                    let ayran = {};
                    let ayranVariants = [];
                    // eslint-disable-next-line no-unused-vars
                    const variantData = data.filter((item) => {
                      if (item.flavorCode === "_AYRAN") {
                        const newObj = _.omit(item.variants[0], "sizeName");
                        ayran = { ...item };
                        ayranVariants.push({
                          ...newObj,
                          flavourName: item.name,
                        });
                      } else if (item.flavorCode === "NAYRAN") {
                        const newObj = _.omit(item.variants[0], "sizeName");
                        ayranVariants.push({
                          ...newObj,
                          flavourName: item.name,
                        });
                      }

                      return (
                        item.flavorCode === "_AYRAN" ||
                        item.flavorCode === "NAYRAN"
                      );
                    });
                    const showDrinks = Response.data.data.filter(
                      (item) =>
                        item.flavorCode !== "_AYRAN" &&
                        item.flavorCode !== "NAYRAN"
                    );
                    showDrinks.push({
                      ...ayran,
                      variants: ayranVariants,
                    });

                    await this.setState({
                      EveryThingItemList: Response.data.data,
                      DrinkList: showDrinks,
                    });
                  } else {
                    await this.setState({
                      EveryThingItemList: Response.data.data,
                    });
                  }
                }
              } else {
                let index = this.state.tab1[value]?.tabs.findIndex(
                  // eslint-disable-next-line no-loop-func
                  (x) => x.tab === subType
                );
                await this.setState({
                  tabTitle: this.state.tab1[value]?.title,
                  tabs: this.state.tab1[value]?.tabs,
                  selectedTab: this.state.tab1[value]?.tabs[index]?.tab,
                });
                if (this.state.everythingElse) {
                  let valueToSearch =
                    this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase();
                  if (
                    this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase() ===
                    "dips"
                  ) {
                    valueToSearch = "sides";
                  }
                  const Response = await apiServices.getMenuByType(
                    valueToSearch
                  );
                  if (valueToSearch === "Drink") {
                    const data = Response.data.data;
                    let ayran = {};
                    let ayranVariants = [];
                    // eslint-disable-next-line no-unused-vars
                    const variantData = data.filter((item) => {
                      if (item.flavorCode === "_AYRAN") {
                        const newObj = _.omit(item.variants[0], "sizeName");
                        ayran = { ...item };
                        ayranVariants.push({
                          ...newObj,
                          flavourName: item.name,
                        });
                      } else if (item.flavorCode === "NAYRAN") {
                        const newObj = _.omit(item.variants[0], "sizeName");
                        ayranVariants.push({
                          ...newObj,
                          flavourName: item.name,
                        });
                      }

                      return (
                        item.flavorCode === "_AYRAN" ||
                        item.flavorCode === "NAYRAN"
                      );
                    });
                    const showDrinks = Response.data.data.filter(
                      (item) =>
                        item.flavorCode !== "_AYRAN" &&
                        item.flavorCode !== "NAYRAN"
                    );
                    showDrinks.push({
                      ...ayran,
                      variants: ayranVariants,
                    });

                    await this.setState({
                      EveryThingItemList: Response.data.data,
                      DrinkList: showDrinks,
                    });
                  } else {
                    await this.setState({
                      EveryThingItemList: Response.data.data,
                    });
                  }
                }
                // this.SelectedTabChange(null,subType)
              }

              this.setState({ url: value });
              this.props.store.setWithRender("isUrlChanged", true);
              this.props.history.push({ pathname: `/app/orders/${value}` });
            } catch (error) {}
            break;
          } else {
            try {
              await this.setState({
                exitOrder: false,
                customer: false,
                pizza: false,
                everythingElse: false,
                coupons: false,
                finish: false,
                laterDeliveryValue: false,
                addPiza: true,
                selectedPizza: selected,
                selectDealItems: isDealItems,
                listIndexSet: carIndex >= 0 ? carIndex : "",
              });
              await this.setState(obj);
              if (subType === undefined) {
                await this.setState({
                  tabTitle: this.state.tab1[value]?.title,
                  tabs: this.state.tab1[value]?.tabs,
                  selectedTab: this.state.tab1[value]?.tabs[0]?.tab,
                });
                if (this.state.everythingElse) {
                  const Response = await apiServices.getMenuByType(
                    this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase()
                  );

                  if (
                    this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase() ===
                    "drink"
                  ) {
                    const data = Response.data.data;
                    let ayran = {};
                    let ayranVariants = [];
                    // eslint-disable-next-line no-unused-vars
                    const variantData = data.filter((item) => {
                      if (item.flavorCode === "_AYRAN") {
                        const newObj = _.omit(item.variants[0], "sizeName");
                        ayran = { ...item };
                        ayranVariants.push({
                          ...newObj,
                          flavourName: item.name,
                        });
                      } else if (item.flavorCode === "NAYRAN") {
                        const newObj = _.omit(item.variants[0], "sizeName");
                        ayranVariants.push({
                          ...newObj,
                          flavourName: item.name,
                        });
                      }

                      return (
                        item.flavorCode === "_AYRAN" ||
                        item.flavorCode === "NAYRAN"
                      );
                    });
                    const showDrinks = Response.data.data.filter(
                      (item) =>
                        item.flavorCode !== "_AYRAN" &&
                        item.flavorCode !== "NAYRAN"
                    );
                    showDrinks.push({
                      ...ayran,
                      variants: ayranVariants,
                    });

                    await this.setState({
                      EveryThingItemList: Response.data.data,
                      DrinkList: showDrinks,
                    });
                  } else {
                    await this.setState({
                      EveryThingItemList: Response.data.data,
                    });
                  }
                }
              } else {
                let index = this.state.tab1[value]?.tabs.findIndex(
                  // eslint-disable-next-line no-loop-func
                  (x) => x.tab === subType
                );
                await this.setState({
                  tabTitle: this.state.tab1[value]?.title,
                  tabs: this.state.tab1[value]?.tabs,
                  selectedTab: this.state.tab1[value]?.tabs[index]?.tab,
                });
                if (this.state.everythingElse) {
                  let valueToSearch =
                    this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase();
                  if (
                    this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase() ===
                    "dips"
                  ) {
                    valueToSearch = "sides";
                  }
                  const Response = await apiServices.getMenuByType(
                    valueToSearch
                  );
                  if (valueToSearch === "Drink") {
                    const data = Response.data.data;
                    let ayran = {};
                    let ayranVariants = [];
                    // eslint-disable-next-line no-unused-vars
                    const variantData = data.filter((item) => {
                      if (item.flavorCode === "_AYRAN") {
                        const newObj = _.omit(item.variants[0], "sizeName");
                        ayran = { ...item };
                        ayranVariants.push({
                          ...newObj,
                          flavourName: item.name,
                        });
                      } else if (item.flavorCode === "NAYRAN") {
                        const newObj = _.omit(item.variants[0], "sizeName");
                        ayranVariants.push({
                          ...newObj,
                          flavourName: item.name,
                        });
                      }

                      return (
                        item.flavorCode === "_AYRAN" ||
                        item.flavorCode === "NAYRAN"
                      );
                    });
                    const showDrinks = Response.data.data.filter(
                      (item) =>
                        item.flavorCode !== "_AYRAN" &&
                        item.flavorCode !== "NAYRAN"
                    );
                    showDrinks.push({
                      ...ayran,
                      variants: ayranVariants,
                    });

                    await this.setState({
                      EveryThingItemList: Response.data.data,
                      DrinkList: showDrinks,
                    });
                  } else {
                    await this.setState({
                      EveryThingItemList: Response.data.data,
                    });
                  }
                }
                // this.SelectedTabChange(null,subType)
              }
              await this.getTaxAndTotal(true);
              this.setState({ url: value });
              this.props.store.setWithRender("isUrlChanged", true);
              this.props.history.push({ pathname: `/app/orders/${value}` });
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    } else {
      try {
        await this.setState({
          exitOrder: false,
          customer: false,
          pizza: false,
          everythingElse: false,
          coupons: false,
          finish: false,
          laterDeliveryValue: false,
          addPiza: true,
          selectedPizza: selected,
          selectDealItems: isDealItems,
          valueIsTruePizza: false,
          valueIsTrueTopping: false,
          valueIsTrueCrust: false,
          listIndexSet: carIndex >= 0 ? carIndex : "",
        });
        await this.setState(obj);
        if (subType === undefined) {
          await this.setState({
            tabTitle: this.state.tab1[value]?.title,
            tabs: this.state.tab1[value]?.tabs,
            selectedTab: this.state.tab1[value]?.tabs[0]?.tab,
          });
          if (this.state.everythingElse) {
            let { store } = this.props;
            let Response = "";
            let ResponseCheck = store.get(
              this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase()
            );

            if (ResponseCheck) {
              Response = ResponseCheck;
            } else {
              Response = await apiServices.getMenuByType(
                this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase()
              );
              await store.setWithRender(
                this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase(),
                Response
              );
            }

            if (
              this.state.tab1[value]?.tabs[0]?.tab?.toLowerCase() === "drink"
            ) {
              let showDrinks = "";
              if (ResponseCheck) {
                showDrinks = ResponseCheck;
              } else {
                showDrinks = drinkApiCall();
                await store.setWithRender("drink", showDrinks);
              }

              await this.setState({
                EveryThingItemList: showDrinks,
                DrinkList: showDrinks,
              });
            } else {
              await this.setState(
                { EveryThingItemList: Response.data.data },
                () => {
                  allTabs?.everythingElse?.tabs?.map(async (Listitem) => {
                    let ResponseCheckAll = store.get(
                      Listitem?.tab?.toLowerCase()
                    );
                    if (ResponseCheckAll) {
                    } else {
                      let ResponseData = await apiServices.getMenuByType(
                        Listitem?.tab?.toLowerCase() === "dips"
                          ? "sides"
                          : Listitem?.tab?.toLowerCase()
                      );
                      await store.setWithRender(
                        Listitem?.tab?.toLowerCase(),
                        ResponseData
                      );
                    }
                    return "";
                  });
                }
              );
            }
          }
        } else {
          let index = this.state.tab1[value]?.tabs.findIndex(
            (x) => x.tab === subType
          );
          await this.setState({
            tabTitle: this.state.tab1[value]?.title,
            tabs: this.state.tab1[value]?.tabs,
            selectedTab: this.state.tab1[value]?.tabs[index]?.tab,
          });
          if (this.state.everythingElse) {
            let valueToSearch =
              this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase();
            if (
              this.state.tab1[value]?.tabs[index]?.tab?.toLowerCase() === "dips"
            ) {
              valueToSearch = "sides";
            }
            const Response = await apiServices.getMenuByType(valueToSearch);
            if (valueToSearch === "Drink") {
              const data = Response.data.data;
              let ayran = {};
              let ayranVariants = [];
              // eslint-disable-next-line no-unused-vars
              const variantData = data.filter((item) => {
                if (item.flavorCode === "_AYRAN") {
                  const newObj = _.omit(item.variants[0], "sizeName");
                  ayran = { ...item };
                  ayranVariants.push({
                    ...newObj,
                    flavourName: item.name,
                  });
                } else if (item.flavorCode === "NAYRAN") {
                  const newObj = _.omit(item.variants[0], "sizeName");
                  ayranVariants.push({
                    ...newObj,
                    flavourName: item.name,
                  });
                }

                return (
                  item.flavorCode === "_AYRAN" || item.flavorCode === "NAYRAN"
                );
              });
              const showDrinks = Response.data.data.filter(
                (item) =>
                  item.flavorCode !== "_AYRAN" && item.flavorCode !== "NAYRAN"
              );
              showDrinks.push({
                ...ayran,
                variants: ayranVariants,
              });

              await this.setState({
                EveryThingItemList: Response.data.data,
                DrinkList: showDrinks,
              });
            } else {
              await this.setState({ EveryThingItemList: Response.data.data });
            }
          }
          // this.SelectedTabChange(null,subType)
        }

        this.setState({ url: value });
        this.props.store.setWithRender("isUrlChanged", true);
        this.props.history.push({ pathname: `/app/orders/${value}` });
        this.setState({ activity: false });
      } catch (error) {
        this.setState({ activity: false });

        console.log(error);
      }
    }

    if (!this.state.selectedPizza.isCoupon && this.state.pizza) {
      this.setState({ activity: true });

      let { store } = this.props;
      let ResponseCheck = store.get("pizza");
      let data = "";
      if (ResponseCheck) {
        data = ResponseCheck;
      } else {
        const ownPizaResponse = await apiServices.getMakeYourOwnPiza();
        const pizzaResponse = await apiServices.getMenuByType("pizza");
        data = await pizzaResponse.data.data;
        ownPizaResponse.data.data.options = ownPizaResponse.data.data.toppings;
        data.unshift(ownPizaResponse.data.data);
        await store.setWithRender("pizza", data);
      }

      await this.setState({ pizzaAllData: data });
      this.nextBtnHandler(0, data, 11, 1, "halfNhalfPizza");
      this.nextBtnHandler(0, data, 11, 1, "specialityPizza");
      this.setState({ activity: false });
    }

    if (this.state.selectedPizza.isCoupon) {
      this.setState({ activity: true });
      let { store } = this.props;
      let ResponseCheck = store.get("pizza");
      let data = "";
      if (ResponseCheck) {
        data = ResponseCheck;
      } else {
        const ownPizaResponse = await apiServices.getMakeYourOwnPiza();
        const pizzaResponse = await apiServices.getMenuByType("pizza");
        data = await pizzaResponse.data.data;
        ownPizaResponse.data.data.options = ownPizaResponse.data.data.toppings;
        data.unshift(ownPizaResponse.data.data);
        await store.setWithRender("pizza", data);
      }

      await this.setState({ pizzaAllData: data, activity: true });
      const matchPizza = this.state.pizzaAllData?.filter((data, index) => {
        return this.state.selectedPizza.allowedItems?.some((_topping) => {
          return _topping._id === (data || {})._id;
        });
      });

      this.setState({
        pizzaAllData: matchPizza,
      });
      this.nextBtnHandler(0, matchPizza, 11, 1, "halfNhalfPizza");
      this.nextBtnHandler(0, matchPizza, 11, 1, "specialityPizza");
      this.setState({ activity: false });
    }

    this.setState({ activity: false });
  };

  //get store from customer location And Open Hour And Close Hour
  getStoreByLocation = async (lat, lng) => {
    try {
      let Result = {};
      if (
        this.state.customerStreet &&
        (this.state.serviceMethod.toLowerCase() === "takeaway" ||
          this.state.serviceMethod.toLowerCase() === "dinein")
      ) {
        const stores = sortStoresByDistance(this.state.allStores, {
          latitude: this.state.customerStreet?.location[1],
          longitude: this.state.customerStreet?.location[0],
        });

        await this.setState(
          {
            allStoresWithDistance: stores,
            storeNameSelected: _.isEmpty(this.state.storeNameSelected)
              ? stores[0]
              : stores.filter(
                  (itemsss) =>
                    itemsss?._id === this.state.storeNameSelected?._id
                )?.[0],
            openHour: _.isEmpty(this.state.storeNameSelected)
              ? stores[0]?.openingHour || "0900"
              : this.state.openHour,
            closeHour: _.isEmpty(this.state.storeNameSelected)
              ? stores[0]?.closingHour || "0400"
              : this.state.closeHour,
          },
          () => {
            let cartItems = [];
            this.state.itemList.map(async (item) => {
              if (item.type === "coupons") {
                let error = await getDealsError({
                  state: {
                    storeNameSelected: this.state.storeNameSelected,
                    serviceMethod: this.state.serviceMethod,
                    storeName: this.state.storeName,
                  },
                  deal: item?.coupons?.deal,
                });
                let obj = {
                  ...item,
                  error: error,
                };
                cartItems.push(obj);
                this.setState({
                  couponsComplete: error === "NoError" ? true : false,
                });
              } else {
                cartItems.push(item);
              }
            });
            this.setState({ itemList: cartItems });
          }
        );
        this.customerErrorTest();
        const Response = await apiServices.getStoreByLocation(
          this.state.customerStreet?.location[1],
          this.state.customerStreet?.location[0]
        );
        Result = Response;
        await this.setState({ redirect: false, deliveryTill: "" });

        let systemDate = moment(new Date()).utcOffset("+04:00").format("HHmm");
        let closingHour = Number(this.state.closeHour);
        let openingHour = Number(this.state.openHour);
        if (this.state.laterTime && this.state.deliveryTime === "later") {
          if (
            !(
              (
                (openingHour > closingHour && //1000 200
                  ((Number(this.state.laterTime) >= openingHour &&
                    Number(this.state.laterTime) <= 2359) || //100 >= 1000 && 100 <= 2359
                    Number(this.state.laterTime) <= closingHour)) || //100 <= 200
                (openingHour <= closingHour && //1000 2200
                  Number(this.state.laterTime) >= openingHour && //1823 >= 1000
                  Number(this.state.laterTime) <= closingHour)
              ) //1823 <= 2200
            )
          ) {
            await this.setState({
              deliveryTime: "",
              selectedDateTime: {
                selectedHour:
                  moment(
                    new Date()?.setMinutes(new Date()?.getMinutes() + 30)
                  ).format("HH") + getHeightLightTime(30),
                selectedDate: "",
              },
            });
            this.customerErrorTest();
            await toast.error("Store Is Closed At This Time");
          }
        } else {
        }
        if (
          Result.data.status === 404 ||
          !(
            (
              (openingHour > closingHour && //1000 200
                ((systemDate >= openingHour && systemDate <= 2359) || //100 >= 1000 && 100 <= 2359
                  systemDate <= closingHour)) || //100 <= 200
              (openingHour <= closingHour && //1000 2200
                systemDate >= openingHour && //1823 >= 1000
                systemDate <= closingHour)
            ) //1823 <= 2200
          )
        ) {
          if (Result.data.status === 200) {
            await toast.error("Store Is Closed At This Time");
            this.setState({ storeMsg: "Store Not Available" });
          } else {
            await toast.error(Result.data.message);
          }

          if (this.state.serviceMethod === "delivery") {
            await this.setState({
              StoreIsClosedOrNot: true,
              customerErrors: {
                ...this.state.customerErrors,
                Street: true,
                DeliveryStore: true,
              },
              customerComplete: true,
            });
            this.customerErrorTest();
          } else {
            await this.setState({
              StoreIsClosedOrNot: true,
              customerErrors: { ...this.state.customerErrors, Store: true },
              customerComplete: true,
            });
            this.customerErrorTest();
          }
        } else {
          this.setState({
            StoreIsClosedOrNot: false,
          });
        }
      } else if (
        (this.state.serviceMethod.toLowerCase() === "takeaway" ||
          this.state.serviceMethod.toLowerCase() === "dinein") &&
        !_.isEmpty(this.state.storeNameSelected)
      ) {
        await this.setState({ redirect: false, deliveryTill: "" });
        let systemDate = moment(new Date()).utcOffset("+04:00").format("HHmm");
        let closingHour = Number(this.state.closeHour);
        let openingHour = Number(this.state.openHour);

        if (this.state.laterTime && this.state.deliveryTime === "later") {
          if (
            !(
              (
                (openingHour > closingHour && //1000 200
                  ((Number(this.state.laterTime) >= openingHour &&
                    Number(this.state.laterTime) <= 2359) || //100 >= 1000 && 100 <= 2359
                    Number(this.state.laterTime) <= closingHour)) || //100 <= 200
                (openingHour <= closingHour && //1000 2200
                  Number(this.state.laterTime) >= openingHour && //1823 >= 1000
                  Number(this.state.laterTime) <= closingHour)
              ) //1823 <= 2200
            )
          ) {
            await this.setState({
              deliveryTime: "",
              selectedDateTime: {
                selectedHour:
                  moment(
                    new Date()?.setMinutes(new Date()?.getMinutes() + 30)
                  ).format("HH") + getHeightLightTime(30),
                selectedDate: "",
              },
            });
            this.customerErrorTest();
            await toast.error("Store Is Closed At This Time");
          }
        } else {
        }

        if (
          !(
            (
              (openingHour > closingHour && //1000 200
                ((systemDate >= openingHour && systemDate <= 2359) || //100 >= 1000 && 100 <= 2359
                  systemDate <= closingHour)) || //100 <= 200
              (openingHour <= closingHour && //1000 2200
                systemDate >= openingHour && //1823 >= 1000
                systemDate <= closingHour)
            ) //1823 <= 2200
          )
        ) {
          await toast.error("Store Is Closed At This Time");
          this.setState({ storeMsg: "Store Not Available" });

          await this.setState({
            StoreIsClosedOrNot: true,
            customerErrors: { ...this.state.customerErrors, Store: true },
            customerComplete: true,
          });
        } else {
          this.setState({
            StoreIsClosedOrNot: false,
          });
        }
        await this.customerErrorTest();
      } else if (
        this.state.customerStreet &&
        this.state.serviceMethod.toLowerCase() === "delivery"
      ) {
        const Response = await apiServices.getStoreByLocation(lat, lng);
        Result = Response;
        if (Response.data.status === 600) {
          toast.error(`${capitalize(Response.data.messege || "")} ❕`);
        }
        // State Set
        await this.setState(
          {
            storeName:
              Response?.data?.status !== 404 ? Response?.data?.data : undefined,
            preStore:
              Response?.data?.status !== 404 ? Response?.data?.data : undefined,
          },
          () => {
            let cartItems = [];
            this.state.itemList.map(async (item) => {
              if (item.type === "coupons") {
                let error = await getDealsError({
                  state: {
                    storeNameSelected: this.state.storeNameSelected,
                    serviceMethod: this.state.serviceMethod,
                    storeName: this.state.storeName,
                  },
                  deal: item?.coupons?.deal,
                });
                let obj = {
                  ...item,
                  error: error,
                };
                cartItems.push(obj);
                this.setState({
                  couponsComplete: error === "NoError" ? true : false,
                });
              } else {
                cartItems.push(item);
              }
            });
            this.setState({ itemList: cartItems });
          }
        );

        if (Response?.data?.status !== 404) {
          let data = {
            store: Response?.data?.data?._id,
          };

          const etdResponse = await apiServices.getEstimateTime(data);

          this.setState({
            estimatedTime: etdResponse?.data?.data,
          });
        }

        // TimeSet
        await this.customerFormOnChangeHandler(
          "openHour",
          Response?.data?.data?.openingHour || "0900"
        );
        await this.customerFormOnChangeHandler(
          "closeHour",
          Response?.data?.data?.closingHour || "0400"
        );
        await this.setState({
          redirect: Response?.data?.data?.redirect,
          deliveryTill:
            Response?.data?.data?.deliveryTill ||
            Response?.data?.data?.closingHour,
        });

        let systemDate = await moment(new Date())
          .utcOffset("+04:00")
          .format("HHmm");
        let closingHour = await Number(Response?.data?.data?.closingHour);
        let openingHour = await Number(Response?.data?.data?.openingHour);
        // let till = await Number(Response?.data?.data?.deliveryTill);
        if (this.state.laterTime && this.state.deliveryTime === "later") {
          if (
            Response?.data?.data?.redirect &&
            !(
              (
                (openingHour > closingHour && //1000 200
                  ((Number(this.state.laterTime) >= openingHour &&
                    Number(this.state.laterTime) <= 2359) || //100 >= 1000 && 100 <= 2359
                    Number(this.state.laterTime) <= closingHour)) || //100 <= 200
                (openingHour <= closingHour && //1000 2200
                  Number(this.state.laterTime) >= openingHour && //1823 >= 1000
                  Number(this.state.laterTime) <= closingHour)
              ) //1823 <= 2200
            )
          ) {
            let store = this.state.allStores?.filter(
              (item) => item?._id === Response?.data?.data?.redirectStore
            );
            this.customerFormOnChangeHandler("storeName", store?.[0]);
            this.customerFormOnChangeHandler(
              "closeHour",
              store?.[0]?.closingHour
            );
            this.customerFormOnChangeHandler(
              "openHour",
              store?.[0]?.openingHour
            );
          } else if (
            !Response?.data?.data?.redirect &&
            !(
              (
                (openingHour > closingHour && //1000 200
                  ((Number(this.state.laterTime) >= openingHour &&
                    Number(this.state.laterTime) <= 2359) || //100 >= 1000 && 100 <= 2359
                    Number(this.state.laterTime) <= closingHour)) || //100 <= 200
                (openingHour <= closingHour && //1000 2200
                  Number(this.state.laterTime) >= openingHour && //1823 >= 1000
                  Number(this.state.laterTime) <= closingHour)
              ) //1823 <= 2200
            )
          ) {
            await this.setState({
              deliveryTime: "",
              selectedDateTime: {
                selectedHour:
                  moment(
                    new Date()?.setMinutes(new Date()?.getMinutes() + 30)
                  ).format("HH") + getHeightLightTime(30),
                selectedDate: "",
              },
            });
            await this.customerErrorTest();
            toast.error("Store Is Closed At This Time");
          }
        } else {
        }
        if (
          !(
            (
              (openingHour > closingHour && //1000 200
                ((systemDate >= openingHour && systemDate <= 2359) || //100 >= 1000 && 100 <= 2359
                  systemDate <= closingHour)) || //100 <= 200
              (openingHour <= closingHour && //1000 2200
                systemDate >= openingHour && //1823 >= 1000
                systemDate <= closingHour)
            ) //1823 <= 2200
          )
        ) {
          if (Result.data.status === 200) {
            await toast.error("Store Is Closed At This Time");
            this.setState({ storeMsg: "Store Not Available" });
          } else {
            await toast.error(Result.data.message);
          }
          if (this.state.serviceMethod === "delivery") {
            this.setState({
              StoreIsClosedOrNot: true,
              customerErrors: {
                ...this.state.customerErrors,
                Street: true,
                DeliveryStore: true,
              },
              customerComplete: true,
            });
          } else {
            this.setState({
              StoreIsClosedOrNot: true,
              customerErrors: { ...this.state.customerErrors, Store: true },
              customerComplete: true,
            });
          }
          this.customerErrorTest();
        } else {
          this.setState({
            StoreIsClosedOrNot: false,
            customerComplete: false,
          });
          this.customerErrorTest();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Tab Switching And Everything Else data According to Selected Tab Also Enable New Item to be added in Cart
  // Also Api for Everything is called here
  SelectedTabChange = async (e, value) => {
    try {
      await this.setState({
        selectedTab: value,
        countForUpAndDown: 1,
      });
      if (value === "Filter Coupons") {
        const data = [];
        let obj = {};

        // eslint-disable-next-line array-callback-return
        this.state.itemList.map((item) => {
          if (
            item?.type === "pizza" &&
            item?.pizza?.item.Whole !== undefined &&
            !item?.pizza?.item?.Left &&
            !item?.pizza?.item?.Right
          ) {
            obj = {
              _id: item?.pizza?.item?.Whole?._id,
              size: item?.pizza?.size,
              type: item?.type?.toLowerCase(),
            };
            data.push(obj);
          } else if (item?.type === "everythingElse") {
            obj = {
              _id: item?.everythingElse?.item?._id,
              type: item?.subType?.toLowerCase(),
            };
            data.push(obj);
          }
        });

        await apiServices.getDealsSearchCart(data);
      }
      if (this.state.everythingElse) {
        let list = document.getElementsByClassName("triangle-left");
        let list2 = document.getElementsByClassName("piza-detail-item");
        for (let i = 0; i < list?.length; i++) {
          list[i].style.borderRight = "10px solid #fff";
          list2[i].style.background =
            " linear-gradient(to top left, transparent 50%, #0477ac 0) top left/15px 15px no-repeat, #d0ca90";
        }

        let { store } = this.props;
        let Response = "";
        const ResponseCheck = await store.get(value?.toLowerCase());

        if (ResponseCheck) {
          Response = ResponseCheck;
        } else {
          if (value?.toLowerCase() === "dips") {
            value = "sides";
          }
          Response = await apiServices.getMenuByType(value?.toLowerCase());
          await store.setWithRender(
            value?.toLowerCase() === "sides" ? "dips" : value?.toLowerCase(),
            Response
          );
        }
        if (value?.toLowerCase() === "drink") {
          let showDrinks = "";
          if (ResponseCheck) {
            showDrinks = ResponseCheck;
          } else {
            showDrinks = drinkApiCall();
            await store.setWithRender("drink", showDrinks);
          }
          await this.setState({
            EveryThingItemList: showDrinks,
            DrinkList: showDrinks,
            addPiza: true,
            listIndexSet: "",
            selectedPizza: {},
            removedHalfnHalf: false,
          });
        } else {
          await this.setState({
            EveryThingItemList: Response.data.data,
            addPiza: true,
            listIndexSet: "",
            selectedPizza: {},
            removedHalfnHalf: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Pagination/Navigation Handler
  nextBtnHandler = async (startValue, array, lenght, pageNo, stateName) => {
    try {
      let le;
      if (startValue === null) {
        let a = pageNo - 1;
        startValue = lenght * a;
      }
      le = lenght * pageNo;

      const test = [];
      for (let i = startValue; i < le; i++) {
        test.push(array[i]);
      }
      var filtered = test.filter(function (x) {
        return x !== undefined;
      });
      await this.setState({
        [stateName]: test,
      });
      return filtered;
    } catch (error) {
      console.log(error);
    }
  };

  // Get address on click on Map
  getAddressFromMap = async (value, location, city) => {
    try {
      const Response = await apiServices.getStoreByLocation(
        location[0],
        location[1]
      );
      await this.setState({
        addressFromMap: value,
        storeNameDisplay:
          Response.data.status === 200
            ? Response.data.data?.storeName?.en
            : "Out of Delivery Area",
        customerLocation: [location[1], location[0]],
        customerCity: city,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Add Addres
  addAddress = async (id, data) => {
    try {
      const Response = await apiServices.addAddress(id, data);
      await this.setState({
        customerStreet: Response.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Update Address
  updateAddress = async (id, data) => {
    try {
      const Response = await apiServices.updateAddress(id, data);
      await this.setState({
        customerStreet: Response.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //this function is calling apis
  getMenuByType = async () => {
    try {
      let { store } = this.props;

      // Crust K lye
      let ResponseCrust = store.get("crust");
      let crustResponse = "";
      if (ResponseCrust) {
        crustResponse = ResponseCrust;
      } else {
        crustResponse = await apiServices.getCrust();
        await store.setWithRender("crust", crustResponse);
      }

      // Drinks K lye
      let ResponseDrink = store.get("drink");
      let showDrinks = "";
      if (ResponseDrink) {
        showDrinks = ResponseDrink;
      } else {
        ResponseDrink = await drinkApiCall();
        await store.setWithRender("drink", ResponseDrink);
      }

      // Toppings  K lye
      let ResponseToppings = store.get("topping");
      let toppingResponse = "";
      if (ResponseToppings) {
        toppingResponse = ResponseToppings;
      } else {
        toppingResponse = await apiServices.getMenuByType("topping"); /**/
        await store.setWithRender("topping", toppingResponse);
      }

      // Toppings  K lye
      let ResponseAllStore = store.get("allStore");
      let allStoresResponse = "";
      if (ResponseAllStore) {
        allStoresResponse = ResponseAllStore;
      } else {
        allStoresResponse = await apiServices.getAllStores();
        await store.setWithRender("allStore", allStoresResponse);
      }

      crustResponse = crustResponse.data.data.filter(function (x) {
        return x?.name?.en !== "";
      });
      // this.getNearByStore();

      //Pizza k lye
      let ResponseCheck = store.get("pizza");
      let data = "";
      if (ResponseCheck) {
        data = ResponseCheck;
      } else {
        const ownPizaResponse = await apiServices.getMakeYourOwnPiza();
        const pizzaResponse = await apiServices.getMenuByType("pizza");
        data = await pizzaResponse.data.data;
        ownPizaResponse.data.data.options = ownPizaResponse.data.data.toppings;
        data.unshift(ownPizaResponse.data.data);
        await store.setWithRender("pizza", data);
      }
      await this.nextBtnHandler(0, crustResponse, 5, 1, "crustData");
      await this.nextBtnHandler(
        0,
        toppingResponse.data.data,
        11,
        1,
        "toppingData"
      );
      await this.nextBtnHandler(0, data, 11, 1, "specialityPizza");
      await this.nextBtnHandler(0, data, 11, 1, "halfNhalfPizza");

      await this.setState({
        DrinkList: showDrinks,
        alltoppingData: toppingResponse.data.data,
        allStores: allStoresResponse.data.data,
        crustAllData: crustResponse,
        pizzaAllData: data,
        activity: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Everything Else Add in cart Item
  selectEveryThingHandlerOne = async (value) => {
    try {
      let { itemList } = this.state;
      if (this.state.selectedPizza.isCoupon) {
        if (
          itemList[this.state.cartIndex].coupons.myDealItems[
            this.state.dealItemIndex
          ].allowedItems.length < 2
        ) {
          toast.warning("This Item Cannot Be Change");
        } else {
          itemList[this.state.cartIndex].coupons.myDealItems[
            this.state.dealItemIndex
          ] = {
            ...itemList[this.state.cartIndex].coupons.myDealItems[
              this.state.dealItemIndex
            ],
            ...value,
          };
          this.setState({ itemList });
        }
      } else {
        const error = drinksAddErrors(value);
        const selectItems = this.state.itemList;
        let obj = value;
        if (value?.subType === "Drink") {
          if (value?.everythingElse?.flavor) {
            let drinkPrice = value?.everythingElse?.flavor;

            if (
              drinkPrice?.flavorCode === "NAYRAN" ||
              drinkPrice?.flavorCode === "_AYRAN"
            ) {
              value.everythingElse.flavor._id =
                value?.everythingElse?.item?.sizeVariants?.[
                  drinkPrice?.flavorCode
                ]?.[value?.everythingElse?.size]?._id;
              drinkPrice =
                value?.everythingElse?.item?.sizeVariants?.[
                  drinkPrice?.flavorCode
                ]?.[value?.everythingElse?.size];

              if (this.state.freeCoupon?.text) {
                obj["regularPrice"] =
                  drinkPrice?.sizes?.["defaultSize"] * value?.Qty;
                obj["showMenuAmount"] = true;
                obj["isDiscount"] = true;
                let discountValue =
                  (drinkPrice?.sizes?.["defaultSize"] / 100) *
                    this.state.freeCoupon?.discountValue || 0;
                let discountPrice =
                  drinkPrice?.sizes?.["defaultSize"] - discountValue;
                // let price =
                //   drinkPrice?.sizes?.["defaultSize"] *
                //   (this.state.freeCoupon?.discountValue / 100);
                obj["price"] = discountPrice * value?.Qty;
              } else {
                obj["price"] = drinkPrice?.sizes?.["defaultSize"] * value?.Qty;
              }
            } else {
              if (this.state.freeCoupon?.text) {
                obj["regularPrice"] =
                  drinkPrice?.sizes?.["defaultSize"] * value?.Qty;
                obj["showMenuAmount"] = true;
                obj["isDiscount"] = true;
                let discountValue =
                  (drinkPrice?.sizes?.["defaultSize"] / 100) *
                    this.state.freeCoupon?.discountValue || 0;
                let discountPrice =
                  drinkPrice?.sizes?.["defaultSize"] - discountValue;
                // let price =
                //   drinkPrice?.sizes?.["defaultSize"] *
                //   (this.state.freeCoupon?.discountValue / 100);
                obj["price"] = discountPrice * value?.Qty;
              } else {
                obj["price"] = drinkPrice?.sizes?.["defaultSize"] * value?.Qty;
              }
            }
          } else {
            let drinkPrice = value?.everythingElse?.item?.variants?.filter(
              (e) => e?.sizeName?.en === value?.everythingElse?.size
            );

            if (this.state.freeCoupon?.text) {
              obj["regularPrice"] = drinkPrice?.[0]?.sizes?.["defaultSize"];
              obj["showMenuAmount"] = true;
              obj["isDiscount"] = true;
              let discountValue =
                (drinkPrice?.[0]?.sizes?.["defaultSize"] / 100) *
                  this.state.freeCoupon?.discountValue || 0;
              let discountPrice =
                drinkPrice?.[0]?.sizes?.["defaultSize"] - discountValue;
              // let price =
              //   drinkPrice?.[0]?.sizes?.["defaultSize"] *
              //   (this.state.freeCoupon?.discountValue / 100);
              obj["price"] = discountPrice * value?.Qty;
            } else {
              obj["price"] =
                drinkPrice?.[0]?.sizes?.["defaultSize"] * value?.Qty;
            }
          }
        } else {
          if (this.state.freeCoupon?.text) {
            obj["regularPrice"] =
              value?.everythingElse?.item?.sizes?.["defaultSize"] * value?.Qty;
            obj["showMenuAmount"] = true;
            obj["isDiscount"] = true;

            let discountValue =
              (value?.everythingElse?.item?.sizes?.["defaultSize"] / 100) *
                this.state.freeCoupon?.discountValue || 0;
            let discountPrice =
              value?.everythingElse?.item?.sizes?.["defaultSize"] -
              discountValue;
            // let price =
            //   value?.everythingElse?.item?.sizes?.["defaultSize"] *
            //   (this.state.freeCoupon?.discountValue / 100);
            obj["price"] = discountPrice * value?.Qty;
          } else {
            obj["price"] =
              value?.everythingElse?.item?.sizes?.["defaultSize"] * value?.Qty;
          }
        }

        if (this.state.addPiza) {
          this.setState({
            addPiza: false,
            everyThingComplete: false,
          });
          let list = document.getElementsByClassName("triangle-left");
          let list2 = document.getElementsByClassName("piza-detail-item");
          for (let i = 0; i < list?.length; i++) {
            list[i].style.borderRight = "10px solid #fff";
            list2[i].style.background =
              " linear-gradient(to top left, transparent 50%, #0477ac 0) top left/15px 15px no-repeat, #d0ca90";
          }
          selectItems.push({ ...value, error: error });
          await this.setState({
            itemList: selectItems,
            selectedPizza: { ...value, error: error },
            everyThingComplete: error === "NoError" ? true : false,
          });

          if (!this.state.addPiza) {
            const itemListLength = this.state.itemList?.length - 1;
            document.getElementById(itemListLength).style.borderRight =
              "10px solid #d06536";
            const itemId = `ASD-${itemListLength}`;
            document.getElementById(itemId).style.background =
              " linear-gradient(to top left, transparent 50%, #d06536 0) top left/15px 15px no-repeat,#efe7d2";
          }
          let top = (document.getElementById("task-list").scrollTop += 500);

          this.setState({
            listIndexSet: selectItems.length > 1 ? selectItems.length - 1 : 0,
            scrioll: top,
          });
        } else if (!this.state.addPiza) {
          if (this.state.listIndexSet !== "") {
            const itemListLength = this.state.listIndexSet;
            selectItems.splice(itemListLength, 1, { ...value, error: error });
            await this.setState({
              itemList: selectItems,
              selectedPizza: { ...value, error: error },
              everyThingComplete: error === "NoError" ? true : false,
            });
          } else {
            const itemListLength = this.state.itemList?.length - 1;
            selectItems.splice(itemListLength, 1, { ...value, error: error });
            await this.setState({
              itemList: selectItems,
              selectedPizza: { ...value, error: error },
              everyThingComplete: error === "NoError" ? true : false,
            });
          }
        }

        // const errorCheck = this.state?.itemList?.filter((item) => {
        //   return item.error !== "NoError" && item.type === "everythingElse";
        // });

        // if (errorCheck.length !== 0) {
        //   this.setState({
        //     everyThingComplete: false,
        //   });
        // } else {
        //   this.setState({
        //     everyThingComplete: true,
        //   });
        // }

        // const errorCheckHay = this.state?.itemList?.filter((item) => {
        //   return item.error !== "NoError" && item.type === undefined;
        // });

        // if (errorCheckHay.length !== 0) {
        //   this.setState({
        //     everyThingComplete: false,
        //   });
        // }
        this.checkSelectedItemError(itemList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //piza add and update in Item Cart Also the css of selected item
  selectSpecializePizzaHandler = async (value) => {
    try {
      let { itemList } = this.state;
      if (this.state.selectedPizza.isCoupon) {
        if (
          itemList[this.state.cartIndex].coupons.myDealItems[
            this.state.dealItemIndex
          ].allowedItems.length < 1
        ) {
          toast.warning("This Item cannot be change");
        } else {
          let obj = value;
          let Crust =
            value?.pizza?.cruust?.price[
              defaultSizeCode[Number(value?.pizza?.size)]
            ] || 0;
          let toppingPrice = value?.pizza?.toppingPrice || 0;
          let Edge =
            value?.pizza?.edge?.price[
              defaultSizeCode[Number(value?.pizza?.size)]
            ] || 0;
          let Whole =
            itemList[this.state.cartIndex]?.coupons?.myDealItems[
              this.state.dealItemIndex
            ].minimumPrice || 0;
          let Sum = Whole + Crust + Edge + toppingPrice;
          obj["price"] = Sum * value?.Qty;
          obj["discountValue"] =
            itemList[this.state.cartIndex].coupons?.myDealItems[
              this.state.dealItemIndex
            ].maxDiscount;

          itemList[this.state.cartIndex].coupons.myDealItems[
            this.state.dealItemIndex
          ] = {
            ...itemList[this.state.cartIndex]?.coupons?.myDealItems[
              this.state.dealItemIndex
            ],
            ...value,
          };
          if (itemList?.[this.state.cartIndex]?.coupons?.deal?.combined) {
            const subDeal = await this.getSpecialDealId(
              itemList?.[this.state.cartIndex]?.coupons?.deal,
              itemList?.[this.state.cartIndex]?.coupons?.myDealItems
            );
            itemList[this.state.cartIndex].coupons = {
              ...itemList[this.state.cartIndex].coupons,
              subDeal: subDeal,
            };

            itemList[this.state.cartIndex].coupons.myDealItems[
              this.state.dealItemIndex
            ] = {
              allowedItems:
                itemList?.[this.state.cartIndex]?.coupons?.dealItems[
                  this.state.dealItemIndex
                ].allowedItems,
              ...subDeal?.dealItems?.[this.state?.dealItemIndex],
              ...value,
              price:
                subDeal?.dealItems?.[this.state?.dealItemIndex]?.minimumPrice +
                Crust +
                Edge +
                toppingPrice,
              isCoupon: true,
            };

            let dealItemUpdatePrice = [];

            // subDeal.subCategories.sort((a, b) => b - a);
            subDeal?.subCategories?.sort((a, b) =>
              a < b ? 1 : b < a ? -1 : 0
            );

            if (itemList[this.state.cartIndex]?.coupons?.myDealItems) {
              for (
                let aabcde = 0;
                aabcde <
                itemList[this.state.cartIndex]?.coupons.dealItems.length;
                aabcde++
              ) {
                const aabbcc =
                  itemList[this.state.cartIndex]?.coupons.dealItems[aabcde];
                subDeal.dealItems[aabcde].allowedItems = aabbcc.allowedItems;
              }
              itemList[this.state.cartIndex].coupons.dealItems =
                subDeal.dealItems;

              for (
                let idxxxxxx = 0;
                idxxxxxx < subDeal?.dealItems?.length;
                idxxxxxx++
              ) {
                const subDataaaaa = subDeal?.dealItems[idxxxxxx];
                for (
                  let iddf = 0;
                  iddf <
                  itemList[this.state.cartIndex].coupons.dealItems.length;
                  iddf++
                ) {
                  const deaaaaa =
                    itemList[this.state.cartIndex].coupons.dealItems[iddf];
                  if (`${deaaaaa?._id}` === `${subDataaaaa?._id}`) {
                    if (subDataaaaa?.defaultCategoryCode === "Pizza") {
                      let crustt =
                        itemList[this.state.cartIndex].coupons.myDealItems[iddf]
                          ?.pizza?.cruust?.price[
                          defaultSizeCode[
                            itemList[this.state.cartIndex].coupons.myDealItems[
                              iddf
                            ]?.pizza?.size
                          ]
                        ] === -1
                          ? 0
                          : itemList[this.state.cartIndex].coupons.myDealItems[
                              iddf
                            ]?.pizza?.cruust?.price[
                              defaultSizeCode[
                                itemList[this.state.cartIndex].coupons
                                  .myDealItems[iddf]?.pizza?.size
                              ]
                            ] || 0;
                      let topppp =
                        itemList[this.state.cartIndex].coupons.myDealItems[iddf]
                          ?.pizza?.toppingPrice || 0;
                      let edgeeee =
                        itemList[this.state.cartIndex].coupons.myDealItems[iddf]
                          ?.pizza?.edge?.price[
                          defaultSizeCode[
                            Number(
                              itemList[this.state.cartIndex].coupons
                                .myDealItems[iddf]?.pizza?.size
                            )
                          ]
                        ] || 0;

                      dealItemUpdatePrice.push({
                        ...itemList[this.state.cartIndex].coupons.myDealItems[
                          iddf
                        ],
                        minimumPrice: subDataaaaa?.minimumPrice,
                        regularPrice: subDataaaaa?.regularPrice,
                        price:
                          subDataaaaa?.minimumPrice + crustt + topppp + edgeeee,
                        error: "NoError",
                      });
                    } else {
                      dealItemUpdatePrice.push({
                        ...itemList[this.state.cartIndex].coupons.myDealItems[
                          iddf
                        ],
                        minimumPrice: subDataaaaa?.minimumPrice,
                        regularPrice: subDataaaaa?.regularPrice,
                        price: subDataaaaa?.minimumPrice,
                        error: "NoError",
                      });
                    }
                  }
                }
              }
            }

            itemList[this.state.cartIndex].coupons.myDealItems =
              dealItemUpdatePrice;

            itemList[this.state.cartIndex] = {
              ...itemList[this.state.cartIndex],
              actualPrice: dealItemUpdatePrice?.reduce((preVal, curVal) => {
                return (
                  preVal +
                  curVal?.regularPrice +
                  (curVal?.pizza?.cruust?.price?.[
                    defaultSizeCode[curVal?.pizza?.size]
                  ] || 0) +
                  (curVal?.pizza?.edge?.price?.[
                    defaultSizeCode[curVal?.pizza?.size]
                  ] || 0) +
                  +(curVal?.pizza?.toppingPrice || 0)
                );
              }, 0),
              price: dealItemUpdatePrice?.reduce((preVal, curVal) => {
                return preVal + curVal.price;
              }, 0),
            };
          } else {
            itemList[this.state.cartIndex] = {
              ...itemList[this.state.cartIndex],
              actualPrice: itemList?.[
                this.state.cartIndex
              ]?.coupons?.myDealItems?.reduce((preVal, curVal) => {
                return (
                  preVal +
                  curVal?.regularPrice +
                  (curVal?.pizza?.cruust?.price?.[
                    defaultSizeCode[curVal?.pizza?.size]
                  ] || 0) +
                  (curVal?.pizza?.edge?.price?.[
                    defaultSizeCode[curVal?.pizza?.size]
                  ] || 0) +
                  +(curVal?.pizza?.toppingPrice || 0)
                );
              }, 0),
              price: itemList?.[
                this.state.cartIndex
              ]?.coupons?.myDealItems?.reduce((preVal, curVal) => {
                return (
                  preVal +
                  curVal?.minimumPrice +
                  (curVal?.pizza?.cruust?.price?.[
                    defaultSizeCode[curVal?.pizza?.size]
                  ] || 0) +
                  (curVal?.pizza?.edge?.price?.[
                    defaultSizeCode[curVal?.pizza?.size]
                  ] || 0) +
                  (curVal?.pizza?.toppingPrice || 0)
                );
              }, 0),
              isCoupon: true,
            };
          }

          this.setState({
            itemList,
            selectedPizza: {
              ...itemList[this.state.cartIndex].coupons.myDealItems[
                this.state.dealItemIndex
              ],
              ...value,
              error: "NoError",
            },
          });
        }
      } else {
        let obj = value;
        obj["discountValue"] = 0;
        if (value?.subType === "Half and Half") {
          let Crust =
            value?.pizza?.cruust?.price[
              defaultSizeCode[Number(value.pizza.size)]
            ] || 0;

          let Edge =
            value?.pizza?.edge?.price[
              defaultSizeCode[Number(value.pizza.size)]
            ] || 0;

          let Left =
            value?.pizza?.item?.Left?.sizes[
              defaultSizeCode[Number(value.pizza.size)]
            ] / 2;

          let Right =
            value?.pizza?.item?.Right?.sizes[
              defaultSizeCode[Number(value.pizza.size)]
            ] / 2;

          let toppingRightPrice = value?.pizza.toppingRightPrice || 0;
          let toppingLeftPrice = value?.pizza.toppingLeftPrice || 0;

          let Sum =
            Left + Right + Crust + Edge + toppingRightPrice + toppingLeftPrice;

          if (
            this.state.freeCoupon?.text &&
            (value?.pizza?.item?.Left?.subCategory !== "premium" ||
              value?.pizza?.item?.Right?.subCategory !== "premium")
          ) {
            let discountValue =
              (Sum / 100) * this.state.freeCoupon?.discountValue || 0;
            let discountPrice = Sum - discountValue;
            obj["regularPrice"] = Sum * value?.Qty;
            obj["showMenuAmount"] = true;
            obj["isDiscount"] = true;
            // let price = Sum * (this.state.freeCoupon?.discountValue / 100) || 0;
            obj["price"] = discountPrice * value?.Qty;
          } else {
            obj["price"] = Sum * value.Qty;
          }
        } else {
          let Crust =
            value?.pizza?.cruust?.price[
              defaultSizeCode[Number(value.pizza.size)]
            ] || 0;
          let toppingPrice = value?.pizza?.toppingPrice || 0;
          let Edge =
            value?.pizza?.edge?.price[
              defaultSizeCode[Number(value.pizza.size)]
            ] || 0;
          let Whole =
            value?.pizza?.item?.Whole?.sizes[
              defaultSizeCode[Number(value.pizza.size)]
            ];
          let Sum = Whole + Crust + Edge + toppingPrice;

          if (
            this.state.freeCoupon?.text &&
            value?.pizza?.item?.Whole?.subCategory !== "premium"
          ) {
            let discountValue =
              (Sum / 100) * this.state.freeCoupon?.discountValue || 0;
            let discountPrice = Sum - discountValue;
            obj["regularPrice"] = Sum * value?.Qty;
            obj["showMenuAmount"] = true;
            obj["isDiscount"] = true;
            // let price = Sum * (this.state.freeCoupon?.discountValue / 100) || 0;
            obj["price"] = discountPrice * value?.Qty;
          } else {
            obj["price"] = Sum * value.Qty;
          }
        }

        const error = pizaAddErrors(value);
        const selectItems = this.state.itemList;
        if (this.state.addPiza) {
          this.setState({
            addPiza: false,
            pizzaComplete: false,
          });

          selectItems.push({ ...value, error: error });

          await this.setState({
            itemList: selectItems,
            selectedPizza: { ...value, error: error },
            pizzaComplete: error === "NoError" ? true : false,
          });
          let list = document.getElementsByClassName("triangle-left");
          let list2 = document.getElementsByClassName("piza-detail-item");
          for (let i = 0; i < list?.length; i++) {
            list[i].style.borderRight = "10px solid #fff";
            list2[i].style.background =
              " linear-gradient(to top left, transparent 50%, #0477ac 0) top left/15px 15px no-repeat, #d0ca90";
          }

          const itemListLength = this.state.itemList?.length - 1;
          document.getElementById(itemListLength).style.borderRight =
            "10px solid #d06536";
          const itemId = `ASD-${itemListLength}`;
          document.getElementById(itemId).style.background =
            " linear-gradient(to top left, transparent 50%, #d06536 0) top left/15px 15px no-repeat,#efe7d2";
          let top = (document.getElementById("task-list").scrollTop += 500);

          this.setState({
            listIndexSet: selectItems.length > 1 ? selectItems.length - 1 : 0,
            scrioll: top,
          });
        } else if (!this.state.addPiza) {
          if (this.state.listIndexSet !== "") {
            const itemListLength = this.state.listIndexSet;
            selectItems.splice(itemListLength, 1, { ...value, error: error });

            this.setState({
              itemList: selectItems,
              selectedPizza: { ...value, error: error },
              pizzaComplete: error === "NoError" ? true : false,
            });
          } else {
            const itemListLength = this.state.itemList?.length - 1;
            selectItems.splice(itemListLength, 1, { ...value, error: error });
            this.setState({
              itemList: selectItems,
              selectedPizza: { ...value, error: error },
              pizzaComplete: error === "NoError" ? true : false,
            });
          }
        }

        // const errorCheck = this.state?.itemList?.filter((item) => {
        //   return item.error !== "NoError" && item.type === "pizza";
        // });
        // if (errorCheck.length !== 0) {
        //   this.setState({
        //     pizzaComplete: false,
        //   });
        // } else {
        //   this.setState({
        //     pizzaComplete: true,
        //   });
        // }
      }
      await this.checkSelectedItemError(itemList);
    } catch (error) {
      console.log(error);
    }
  };

  //Use For Language
  setLocalizeContent = (obj) => {
    try {
      const { store } = this.props;
      return obj[store.get("lang")];
    } catch (error) {
      console.log(error);
    }
  };

  //will Add address in street Option on Map close
  addAddressFromMap = async () => {
    try {
      const streetOptions = this.state.customerAddress;

      let data = {};

      if (this.state.agentData?.isIntern === true) {
        data = {
          addedByAgent: true,
          addedByTraineeAgent: true,
          location: this.state.customerLocation,
          streetAddress: this.state.addressFromMap,
          streetName: "",
          streetNumber: "",
          floor: "",
          instructions: "",
          name: "",
        };
      } else {
        data = {
          addedByAgent: true,
          addedByTraineeAgent: false,
          location: this.state.customerLocation,
          streetAddress: this.state.addressFromMap,
          streetName: "",
          streetNumber: "",
          floor: "",
          instructions: "",
          name: "",
        };
      }

      if (streetOptions.length > 0) {
        if (this.state.customerStreet?.addedByAgent) {
          data = {
            ...data,
            _id: this.state.customerStreet?._id,
          };
          streetOptions[streetOptions.indexOf(this.state.customerStreet)] =
            data;
        } else {
          streetOptions[streetOptions.length] = data;
        }
      }
      if (streetOptions.length < 1) {
        streetOptions.push(data);
      }
      if (this.state.phone) {
        await this.setState({
          customerAddress: streetOptions,
          customerStreet: data,
          customerErrors: { ...this.state.customerErrors, Street: false },
        });
      } else {
        await this.setState({
          customerAddress: streetOptions,
          customerStreet: streetOptions[streetOptions.length - 1],
          customerErrors: {
            ...this.state.customerErrors,
            Street: false,
            phone: true,
          },
        });
      }
      const customerSectionComplete = checkTrueValue(this.state.customerErrors);
      await this.setState({
        customerComplete: customerSectionComplete,
      });

      this.customerFormOnChangeHandler("customerBlock", "");
      this.customerFormOnChangeHandler("customerBuilding", "");
      this.customerFormOnChangeHandler("customerFloor", "");
      this.customerFormOnChangeHandler("customerUnit", "");
      this.getStoreByLocation(
        streetOptions[streetOptions.length - 1].location[1],
        streetOptions[streetOptions.length - 1].location[0]
      );
    } catch (error) {
      console.log(error);
    }
  };

  //Map close and function
  mapHandler = () => {
    try {
      // if (this.state.mapOpen) {
      //   this.addAddressFromMap();
      // }
      this.setState({
        mapOpen: !this.state.mapOpen,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // will get One month date from today
  getDates = (d1, d2) => {
    try {
      var oneDay = 24 * 3600 * 1000;
      for (var d = [], ms = d1 * 1, last = d2 * 1; ms < last; ms += oneDay) {
        d.push(new Date(ms));
      }
      return d;
    } catch (error) {
      console.log(error);
    }
  };

  //Open Later Delivery And maintain data
  laterDelivery = async () => {
    try {
      let date = new Date(); // Now
      await this.setState({
        laterDeliveryValue: !this.state.laterDeliveryValue,
        LateOrderAllDate: this.getDates(
          new Date(),
          date.setDate(date.getDate() + 30)
        ),
      });
      await this.nextBtnHandler(
        0,
        this.state.LateOrderAllDate,
        5,
        1,
        "LateOrderDateData"
      );
    } catch (error) {
      console.log(error);
    }
  };

  //Enable Functionality to add new piza
  addPizaHandler = async (newPizza) => {
    let list = document.getElementsByClassName("triangle-left");
    let list2 = document.getElementsByClassName("piza-detail-item");
    for (let i = 0; i < list?.length; i++) {
      list[i].style.borderRight = "10px solid #fff";
      list2[i].style.background =
        " linear-gradient(to top left, transparent 50%, #0477ac 0) top left/15px 15px no-repeat, #d0ca90";
    }
    try {
      await this.customerFormOnChangeHandler("valueIsTruePizza", false);
      await this.customerFormOnChangeHandler("valueIsTrueTopping", false);
      await this.customerFormOnChangeHandler("valueIsTrueCrust", false);

      if (newPizza) {
        await this.SelectedTabChange("", "Speciality Pizza");
      }
      await this.setState({
        addPiza: true,
        listIndexSet: "",
        selectedPizza: {},
        removedHalfnHalf: false,
      });
      this.nextBtnHandler(0, this.state.crustAllData, 5, 1, "crustData");
      this.nextBtnHandler(0, this.state.pizzaAllData, 11, 1, "specialityPizza");
    } catch (error) {
      console.log(error);
      await this.setState({
        // removedHalfnHalf: false,
      });
    }
  };

  //On Blur WIll add customer  with phone and first name
  addCustomer = async () => {
    try {
      if (
        this.state.phone.length > 8 &&
        this.state.customerFirstName !== "" &&
        this.state.customerFirstName !== undefined &&
        this.state.newCustomer
      ) {
        const data = {
          mobile: this.state.phone.startsWith("0")
            ? this.state.phone.replace(/^0+/, "")
            : this.state.phone,
          countryCode: this.state.countryCode?.replace("+", ""),
          name: this.state.customerFirstName,
        };

        const CustomerResponse = await apiServices.addCustomer(data);

        if (CustomerResponse?.data?.status === 200) {
          await this.setState({
            customerObj: CustomerResponse.data.user,
            newCustomer: false,
            CustumerError: false,
          });
        } else {
          if (CustomerResponse?.data?.status === 600) {
            await this.setState({
              customerErrors: {
                ...this.state.customerErrors,
                FirstName: true,
                phone: true,
              },
              CustumerError: true,
            });

            await this.customerErrorTest();
            toast.error("User Already Exists With Another Country Code");
          } else {
            toast.error(CustomerResponse?.data?.message || "");
          }
        }
      } else {
        if (this.state.phone.length <= 8) {
          await this.setState({
            customerErrors: {
              ...this.state.customerErrors,
              phone: true,
              FirstName: true,
            },
            CustumerError: true,
          });

          await this.customerErrorTest();
          toast.error("Invalid Mobile Number");
        } else if (!this.state.customerFirstName) {
          await this.setState({
            customerErrors: {
              ...this.state.customerErrors,
              phone: true,
              FirstName: true,
            },
            CustumerError: true,
          });

          await this.customerErrorTest();
          toast.error("Please Enter Customer Name");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //On Blur WIll update customer  with first name & last name
  updateCustomerProfile = async () => {
    try {
      if (this.state.customerEmail && !isEmailValid(this.state.customerEmail)) {
        toast.error("Please validate customer email.");
      } else if (this.state.customerFirstName || this.state.customerLastName) {
        const data = {
          _id: this.state.customerObj?._id,
          name: this.state.customerFirstName,
          surname: this.state.customerLastName,
          email: this.state.customerEmail,
          gender: this.state.customerGender ?? "",
          agentNote: this.state.customerAgentNote,
        };

        await apiServices.profileUpdateByAgent(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get Near By store According to the location
  getNearByStore = async (lat, lng) => {
    try {
      if (this.state.allStores === null) {
        this.setState({ chkStoreAvailable: false });
      } else {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude,
              longitude = position.coords.longitude;

            const sortedStores = sortStoresByDistance(this.state.allStores, {
              latitude,
              longitude,
            });

            this.setState({
              allStoresWithDistance: sortedStores,
            });
          },
          () => {
            const sortedStores = sortStoresByDistance(this.state.allStores, {
              latitude: 40.4093,
              longitude: 49.8671,
            });

            this.setState({
              allStoresWithDistance: sortedStores,
            });
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //will test Customer Errors
  customerErrorTest = async () => {
    try {
      const error = {
        phone:
          this.state.phone &&
          this.state.phone.length > 8 &&
          !this.state.CustumerError
            ? false
            : true,
        FirstName:
          this.state.customerFirstName !== "" && !this.state.CustumerError
            ? false
            : true,
        Street:
          this.state.serviceMethod === "dinein" ||
          this.state.serviceMethod === "takeaway"
            ? false
            : this.state.customerStreet
            ? false
            : true,
        Store:
          this.state.serviceMethod === "dinein" ||
          this.state.serviceMethod === "takeaway"
            ? _.isEmpty(this.state.storeNameSelected)
              ? true
              : false
            : false,
        ServiceMethod: this.state.serviceMethod !== "" ? false : true,
        DeliveryTime: this.state.deliveryTime !== "" ? false : true,
        DeliveryStore:
          this.state.serviceMethod === "delivery"
            ? this.state.storeName
              ? false
              : true
            : false,
        StoreIsClosed: this.state.StoreIsClosedOrNot,
        CustumerError: this.state.CustumerError,
      };
      const value = checkTrueValue(error);
      this.setState({
        customerErrors: error,
        customerComplete: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // will remover an item from cart according to selected Index
  removerFromCart = async (type) => {
    try {
      if (type === "Half and Half") {
        this.setState({ removedHalfnHalf: true });
      }
      // if (this.state.listIndexSet !== '') {
      const itemListLength = this.state.listIndexSet;
      await this.setState({
        itemList: removerValue(this.state.itemList, itemListLength, 1),
        selectedPizza: this.state.itemList.splice(
          itemListLength,
          1,
          this.state.selectedPizza
        ),
      });

      await this.customerFormOnChangeHandler("listIndexSet", "");
      await this.addPizaHandler();

      await this.customerFormOnChangeHandler("valueIsTruePizza", false);
      await this.customerFormOnChangeHandler("valueIsTrueTopping", false);
      await this.customerFormOnChangeHandler("valueIsTrueCrust", false);

      await this.checkSelectedItemError(this.state.itemList);
    } catch (error) {
      console.log(error);
    }
  };

  removeDealFromCart = async () => {
    try {
      let { store } = this.props;
      let { itemList, listIndexSet } = this.state;
      itemList[listIndexSet]?.coupons?.myDealItems?.map((item) => {
        if (item.defaultCategoryCode === "Drinks") {
          let DrinkData = [];
          this.state.DrinkList.map((ALLDrink) => {
            for (let abc = 0; abc < ALLDrink?.variants?.length; abc++) {
              const element = ALLDrink?.variants[abc];
              if (
                element?.sizeName?.en ===
                  item?.everythingElse?.item?.sizeName?.en &&
                item?.everythingElse?.item.flavorCode === element.flavorCode
              ) {
                DrinkData.push(ALLDrink);
              }
            }
            return "";
          });
          item.everythingElse = { ...item?.everythingElse, item: DrinkData[0] };
          let obj = {
            everythingElse: {
              ...item?.everythingElse,
            },
            type: "everythingElse",
            subType: capitalize(item?.allowedItems[0]?.type || ""),
            Qty: 1,
            error: "NoError",
          };

          if (this.state.freeCoupon?.text) {
            obj["regularPrice"] = item?.regularPrice;
            obj["showMenuAmount"] = true;
            obj["isDiscount"] = true;
            let discountValue =
              (item?.regularPrice / 100) *
                this.state.freeCoupon?.discountValue || 0;
            let discountPrice = item?.regularPrice - discountValue;
            // let price =
            //   item?.regularPrice * (this.state.freeCoupon?.discountValue / 100);
            obj["price"] = discountPrice * 1;
          } else {
            obj["price"] = item?.regularPrice * 1;
          }
          itemList.push(obj);
        } else if (item.defaultCategoryCode === "Pizza") {
          let crustt =
            item?.pizza?.cruust?.price[defaultSizeCode[item?.pizza?.size]] ===
            -1
              ? 0
              : item?.pizza?.cruust?.price[
                  defaultSizeCode[item?.pizza?.size]
                ] || 0;
          let topppp = item?.pizza?.toppingPrice || 0;
          let edgeeee =
            item?.pizza?.edge?.price[
              defaultSizeCode[Number(item?.pizza?.size)]
            ] || 0;

          let obj = {
            pizza: {
              ...item.pizza,
            },

            type: item?.type,
            subtype: item?.subtype,
            Qty: item?.Qty,
            error: "NoError",
            discountValue: 0,
          };
          if (this.state.freeCoupon?.text) {
            obj["regularPrice"] =
              item?.regularPrice + crustt + topppp + edgeeee;
            obj["showMenuAmount"] = true;
            obj["isDiscount"] = true;
            let discountValue =
              ((item?.regularPrice + crustt + topppp + edgeeee) / 100) *
                this.state.freeCoupon?.discountValue || 0;
            let discountPrice = item?.regularPrice - discountValue;
            // let price =
            //   (item?.regularPrice + crustt + topppp + edgeeee) *
            //   (this.state.freeCoupon?.discountValue / 100);
            obj["price"] = discountPrice * 1;
          } else {
            obj["price"] = (item?.regularPrice + crustt + topppp + edgeeee) * 1;
          }
          itemList.push(obj);
        } else {
          let obj = {
            everythingElse: {
              ...item?.everythingElse,
            },
            type: "everythingElse",
            subType:
              item?.allowedItems[0]?.type?.toLowerCase() === "sides"
                ? "Dips"
                : capitalize(item?.allowedItems[0]?.type || ""),
            Qty: 1,
            error: "NoError",
          };
          if (this.state.freeCoupon?.text) {
            obj["regularPrice"] = item?.regularPrice;
            obj["showMenuAmount"] = true;
            obj["isDiscount"] = true;

            let discountValue =
              (item?.regularPrice / 100) *
                this.state.freeCoupon?.discountValue || 0;
            let discountPrice = item?.regularPrice - discountValue;
            // let price =
            //   item?.regularPrice * (this.state.freeCoupon?.discountValue / 100);
            obj["price"] = discountPrice * 1;
          } else {
            obj["price"] = item?.everythingElse?.item?.sizes?.defaultSize * 1;
          }
          itemList.push(obj);
        }
        return "";
      });
      itemList.splice(listIndexSet, 1);
      this.setState({ itemList });

      const errorCheck = this.state?.itemList?.filter((item) => {
        return item.type === "coupons";
      });

      if (errorCheck.length === 0) {
        this.setState({
          couponsComplete: "",
        });
      } else {
        this.setState({
          couponsComplete: true,
        });
      }

      let ResponseCheck = store.get("pizza");
      let data = "";
      if (ResponseCheck) {
        data = ResponseCheck;
      } else {
        const ownPizaResponse = await apiServices.getMakeYourOwnPiza();
        const pizzaResponse = await apiServices.getMenuByType("pizza");
        ownPizaResponse.data.data.options = ownPizaResponse.data.data.toppings;
        data = await pizzaResponse.data.data;
        data.unshift(ownPizaResponse.data.data);
        await store.setWithRender("pizza", data);
      }

      this.setState({
        pizzaAllData: data,
      });
      this.nextBtnHandler(0, data, 11, 1, "specialityPizza");
      this.nextBtnHandler(0, data, 11, 1, "halfNhalfPizza");

      await this.customerFormOnChangeHandler("listIndexSet", "");
      await this.addPizaHandler();
    } catch (error) {
      console.log(error, "error remove coupons");
    }
  };

  addDeal = async () => {
    try {
      await this.customerFormOnChangeHandler("listIndexSet", "");
      await this.addPizaHandler();
    } catch (error) {}
  };

  getSliceFromSize = (size, cut) => {
    if (size === "8") {
      if (cut === "RegularCut") {
        return 4;
      } else if (cut === "DoubleCut") {
        return 8;
      } else if (cut === "SquareCut") {
        return 9;
      } else {
        return 4;
      }
    } else if (size === "10") {
      if (cut === "RegularCut") {
        return 6;
      } else if (cut === "DoubleCut") {
        return 12;
      } else if (cut === "SquareCut") {
        return 16;
      } else {
        return 6;
      }
    } else if (size === "12") {
      if (cut === "RegularCut") {
        return 8;
      } else if (cut === "DoubleCut") {
        return 16;
      } else if (cut === "SquareCut") {
        return 16;
      } else {
        return 8;
      }
    }
  };

  getToppingsDate = (topings) => {
    if (topings) {
      let finalToppingsArray = [];
      const toopingArray = this.state.alltoppingData.filter((element) =>
        topings.includes(element.productCode)
      );
      toopingArray.map((item) => {
        finalToppingsArray.push(item._id);
        return "";
      });
      return finalToppingsArray;
    }
  };

  removeDealItem = async (item, cartDeal) => {
    try {
      // half sai hai
      let { itemList } = this.state;

      itemList[cartDeal.dealCartIndex].coupons.dealItems[
        cartDeal.dealItemIndex
      ].allowedItems[0] = undefined;
      this.setState({ itemList });
    } catch (error) {
      console.log(error);
    }
  };

  modifyOrderPlace = async (paymentMethod, value) => {
    try {
      this.setState({ activity: true });
      const preData = await localStoreUtil.get_data("prevous_data");
      const agentData = await localStoreUtil.get_data("agent");
      let futureDate = moment(
        this.state?.selectedDateTime?.selectedDate
      ).format("DD.MMM.YYYY");
      let futureTimmee = `${this.state?.selectedDateTime?.selectedHour}:00`;

      const preFinalArray2 = [];
      for (let idxxxx = 0; idxxxx < preData.length; idxxxx++) {
        const item = preData[idxxxx];

        if (item.type === "pizza") {
          if (item.subType === "Half and Half") {
            const items = [
              {
                item: item?.pizza?.item?.Left?._id,
                extraToppings:
                  item?.pizza?.toppings?.extraTopping?.Left?.map(
                    (leftExtItem) => {
                      return {
                        extTopping: leftExtItem?._id,
                        modifierQty: leftExtItem?.toppingSize,
                      };
                    }
                  ) || [],
                standardToppings:
                  item?.pizza?.toppings?.standard?.Left?.map((leftStdItem) => {
                    return {
                      stdTopping: leftStdItem?._id,
                      modifierQty: leftStdItem?.toppingSize,
                    };
                  }) || [],
              },
              {
                item: item?.pizza?.item?.Right?._id,
                extraToppings:
                  item?.pizza?.toppings?.extraTopping?.Right?.map(
                    (rightExtItem) => {
                      return {
                        extTopping: rightExtItem?._id,
                        modifierQty: rightExtItem?.toppingSize,
                      };
                    }
                  ) || [],
                standardToppings:
                  item?.pizza?.toppings?.standard?.Right?.map(
                    (rightStdItem) => {
                      return {
                        stdTopping: rightStdItem?._id,
                        modifierQty: rightStdItem?.toppingSize,
                      };
                    }
                  ) || [],
              },
            ];
            let obj = {
              type: "half_half",
              items: items,
              quantity: item?.Qty,
              dough: item?.pizza?.cruust?._id,
              edge: item?.pizza?.cruust?.basic
                ? item?.pizza?.edge?._id
                : undefined,
              cookingInstructions: `${
                defaultCuts[item?.pizza?.cut || "RegularCut"]
              } ${defaultPismis[item?.pizza?.cook || ""]} ${
                item?.pizza?.cookingInstructions || ""
              }`,

              slices: this.getSliceFromSize(
                item?.pizza?.size,
                item?.pizza?.cut
              ),
              price: item?.price,
              size: defaultSizeCode[item?.pizza?.size],
            };
            if (item.discountCode) {
              obj.discountCode = item.discountCode;
            }
            preFinalArray2.push(obj);
            // return
          } else {
            let obj = {
              item: item?.pizza?.item?.Whole?._id,
              dough: item?.pizza?.cruust?._id,
              edge: item?.pizza?.edge?._id || undefined,
              standardToppings:
                item?.pizza?.toppings?.standard?.Whole.map((stdItem) => {
                  return {
                    stdTopping: stdItem._id,
                    modifierQty: stdItem.toppingSize,
                  };
                }) || [],
              extraToppings:
                item?.pizza?.toppings?.extraTopping?.Whole !== undefined
                  ? item?.pizza?.toppings?.extraTopping?.Whole.map(
                      (extItem) => {
                        return {
                          extTopping: extItem._id,
                          modifierQty: extItem.toppingSize,
                        };
                      }
                    )
                  : [],

              size: defaultSizeCode[item?.pizza?.size],
              quantity: item?.Qty,
              price: item?.price,
              slices: this.getSliceFromSize(
                item?.pizza?.size,
                item?.pizza?.cut
              ),
              cookingInstructions: `${
                defaultCuts[item?.pizza?.cut || "RegularCut"]
              } ${defaultPismis[item?.pizza?.cook || ""]} ${
                item?.pizza?.cookingInstructions || ""
              }`,
            };
            if (item.discountCode) {
              obj.discountCode = item.discountCode;
            }
            preFinalArray2.push(obj);
          }
        } else if (item.type === "everythingElse") {
          let obj = {
            item: item?.everythingElse?.flavor
              ? item?.everythingElse?.flavor._id
              : item?.everythingElse?.item?._id,
            quantity: item?.Qty,
            price: item?.price,
            cookingInstructions: "",
          };
          if (item.discountCode) {
            obj.discountCode = item.discountCode;
          }
          preFinalArray2.push(obj);
        } else if (item.type === "coupons") {
          let obj = {
            deal: item?.coupons?.isCombinedDeal
              ? item?.coupons?.subDeal?._id || item?.coupons?.deal?._id
              : item?.coupons?.deal?._id,
            actualPrice: item?.actualPrice,
            items: item?.coupons?.myDealItems?.map((item, index) => {
              if (item.defaultCategoryCode === "Pizza") {
                return {
                  item: item?.pizza?.item?.Whole?._id,
                  quantity: item?.Qty,
                  size: defaultSizeCode[Number(item?.pizza?.size)],
                  slices: this.getSliceFromSize(
                    item?.pizza?.size,
                    item?.pizza?.cut
                  ),
                  cookingInstructions: `${
                    defaultCuts[item?.pizza?.cut || "RegularCut"]
                  } ${defaultPismis[item?.pizza?.cook || ""]} ${
                    item?.pizza?.cookingInstructions || ""
                  }`,

                  dough: item?.pizza?.cruust?._id,
                  edge: item?.pizza?.edge?._id || undefined,
                  extraToppings: (
                    item?.pizza?.toppings?.extraTopping || []
                  )?.Whole?.map((exTop) => {
                    return {
                      extTopping: exTop?._id,
                      modifierQty: exTop.toppingSize,
                    };
                  }),
                  standardToppings: (
                    item?.pizza?.toppings?.standard || []
                  )?.Whole?.map((stdTop) => {
                    return {
                      stdTopping: stdTop?._id,
                      modifierQty: stdTop.toppingSize,
                    };
                  }),
                };
              } else {
                return { item: item.allowedItems[0]._id, quantity: 1 };
              }
            }),
            price: item?.price,
            quantity: item.Qty,
          };
          preFinalArray2.push(obj);
        }
      }
      const data = {
        serviceMethod: this.state.serviceMethod,
        paymentMethod: paymentMethod,
        deliveryInstructions:
          value === true
            ? this.state.deliveryInstructions
            : this.state.deliveryInstructionsCopy,
        driverInstruction: this.state.driverInstruction,
        isGuestOrder: false,
        futureOrder: this.state.deliveryTime === "now" ? false : true,

        // deliveryTime:
        //   this.state.deliveryTime === 'now'
        //     ? new Date()
        //     : new Date(
        //         moment(this.state.selectedDateTime.selectedDate).format('ll') +
        //           ' ' +
        //           this.state.selectedDateTime.selectedHour
        //       ),
        address: this.state.customerStreet?._id,
        deductPoints: 0,
        loyaltyPointsRedeemed: false,
        items: [],
        store:
          this.state.serviceMethod === "delivery"
            ? this.state?.storeName?._id
            : this.state?.storeNameSelected?._id,
        user: this.state.customerObj?._id,
        contactInfo: {
          name:
            this.state.customerFirstName + " " + this.state.customerLastName,
          number: this.state.phone.startsWith("0")
            ? this.state.phone.replace(/^0+/, "")
            : this.state.phone,
        },
        order: this.state.OrderId,
        orderModified: true,
      };
      const itemFinalArray = [];
      for (let idxxx = 0; idxxx < this.state.itemList.length; idxxx++) {
        const item = this.state.itemList[idxxx];
        if (item.type === "pizza") {
          if (item.subType === "Half and Half") {
            const items = [
              {
                item: item?.pizza?.item?.Left?._id,
                extraToppings:
                  item?.pizza?.toppings?.extraTopping?.Left?.map(
                    (leftItemExt) => {
                      return {
                        extTopping: leftItemExt?._id,
                        modifierQty: leftItemExt?.toppingSize,
                      };
                    }
                  ) || [],
                standardToppings:
                  item?.pizza?.toppings?.standard?.Left?.map((leftItemStd) => {
                    return {
                      stdTopping: leftItemStd?._id,
                      modifierQty: leftItemStd?.toppingSize,
                    };
                  }) || [],
              },
              {
                item: item?.pizza?.item?.Right?._id,
                extraToppings:
                  item?.pizza?.toppings?.extraTopping?.Right?.map(
                    (rightItemExt) => {
                      return {
                        extTopping: rightItemExt?._id,
                        modifierQty: rightItemExt?.toppingSize,
                      };
                    }
                  ) || [],
                standardToppings:
                  item?.pizza?.toppings?.standard?.Right?.map(
                    (rightItemStd) => {
                      return {
                        stdTopping: rightItemStd?._id,
                        modifierQty: rightItemStd?.toppingSize,
                      };
                    }
                  ) || [],
              },
            ];
            let obj = {
              type: "half_half",
              items: items,
              quantity: item?.Qty,
              dough: item?.pizza?.cruust?._id,
              edge: item?.pizza?.cruust?.basic
                ? item?.pizza?.edge?._id
                : undefined,
              cookingInstructions: `${
                defaultCuts[item?.pizza?.cut || "RegularCut"]
              } ${defaultPismis[item?.pizza?.cook || ""]} ${
                item?.pizza?.cookingInstructions || ""
              }`,

              slices: this.getSliceFromSize(
                item?.pizza?.size,
                item?.pizza?.cut
              ),
              price: item?.price,
              size: defaultSizeCode[item?.pizza?.size],
            };
            if (this.state.freeCoupon?.text) {
              obj.discountCode = this.state.freeCoupon?.couponCode;
              obj["redeemedCoupon"] = this.state.freeCoupon?._id;
            }
            itemFinalArray.push(obj);
          } else {
            let obj = {
              item: item?.pizza?.item?.Whole?._id,
              dough: item?.pizza?.cruust?._id,
              edge: item?.pizza?.edge?._id || undefined,
              standardToppings:
                item?.pizza?.toppings?.standard?.Whole.map((stdItem) => {
                  return {
                    stdTopping: stdItem._id,
                    modifierQty: stdItem.toppingSize,
                  };
                }) || [],
              extraToppings:
                item?.pizza?.toppings?.extraTopping?.Whole !== undefined
                  ? item?.pizza?.toppings?.extraTopping?.Whole.map(
                      (extItem) => {
                        return {
                          extTopping: extItem._id,
                          modifierQty: extItem.toppingSize,
                        };
                      }
                    )
                  : [],

              size: defaultSizeCode[item?.pizza?.size],
              quantity: item?.Qty,
              price: item?.price,
              slices: this.getSliceFromSize(
                item?.pizza?.size,
                item?.pizza?.cut
              ),
              cookingInstructions: `${
                defaultCuts[item?.pizza?.cut || "RegularCut"]
              } ${defaultPismis[item?.pizza?.cook || ""]} ${
                item?.pizza?.cookingInstructions || ""
              }`,
            };
            if (this.state.freeCoupon?.text) {
              obj.discountCode = this.state.freeCoupon?.couponCode;
              obj["redeemedCoupon"] = this.state.freeCoupon?._id;
              obj["actualPrice"] = item?.regularPrice;
            }
            itemFinalArray.push(obj);
          }
        } else if (item.type === "everythingElse") {
          let obj = {
            item: item?.everythingElse?.flavor
              ? item?.everythingElse?.flavor._id
              : item?.subType === "Drink"
              ? item?.everythingElse?.item?.variants
                ? item?.everythingElse?.item?.variants?.filter(
                    (sizeNameID) =>
                      sizeNameID.sizeName.en === item?.everythingElse?.size
                  )?.[0]?._id
                : item?.everythingElse?.item?._id
              : item?.everythingElse?.item?._id,
            quantity: item?.Qty,
            price: item?.price,
            cookingInstructions: "",
          };
          if (this.state.freeCoupon?.text) {
            obj.discountCode = this.state.freeCoupon?.couponCode;
            obj["redeemedCoupon"] = this.state.freeCoupon?._id;
            obj["actualPrice"] = item?.regularPrice;
          }
          itemFinalArray.push(obj);
        } else if (item.type === "coupons") {
          let obj = {
            deal: item?.coupons?.isCombinedDeal
              ? item?.coupons?.subDeal?._id || item?.coupons?.deal?._id
              : item?.coupons?.deal?._id,
            actualPrice: item?.actualPrice,
            items: [],
            price:
              item?.coupons?.myDealItems?.length > 0
                ? item?.coupons?.myDealItems?.reduce(
                    (total, currentItem) => total + currentItem?.price,
                    0
                  )
                : item?.price,
            quantity: item.Qty,
          };

          if (item?.coupons?.redeemedCoupon) {
            obj["redeemedCoupon"] = item?.coupons?.redeemedCoupon;
          }

          let allItem = [];

          for (
            let index = 0;
            index < item?.coupons?.myDealItems.length;
            index++
          ) {
            const items = item?.coupons?.myDealItems[index];

            if (items.defaultCategoryCode === "Pizza") {
              allItem.push({
                item: items?.pizza?.item?.Whole?._id,
                quantity: items?.Qty,
                size: defaultSizeCode[Number(items?.pizza?.size)],
                slices: this.getSliceFromSize(
                  items?.pizza?.size,
                  items?.pizza?.cut
                ),
                cookingInstructions: `${
                  defaultCuts[items?.pizza?.cut || "RegularCut"]
                } ${defaultPismis[items?.pizza?.cook || ""]} ${
                  items?.pizza?.cookingInstructions || ""
                }`,

                dough: items?.pizza?.cruust?._id,
                edge: items?.pizza?.edge?._id || undefined,
                extraToppings: (
                  items?.pizza?.toppings?.extraTopping || []
                )?.Whole?.map((exTop) => {
                  return {
                    extTopping: exTop._id,
                    modifierQty: exTop.toppingSize,
                  };
                }),
                standardToppings: (
                  items?.pizza?.toppings?.standard || []
                )?.Whole?.map((stdTop) => {
                  return {
                    stdTopping: stdTop._id,
                    modifierQty: stdTop.toppingSize,
                  };
                }),
              });
            } else if (item.defaultCategoryCode === "Drinks") {
              allItem.push({
                item: items?.everythingElse?.flavor
                  ? items?.everythingElse?.flavor._id
                  : items?.subType === "Drink"
                  ? items?.everythingElse?.item?.variants
                    ? items?.everythingElse?.item?.variants?.filter(
                        (sizeNameID) =>
                          sizeNameID.sizeName.en === items?.everythingElse?.size
                      )?.[0]?._id
                    : items?.everythingElse?.item?._id
                  : items?.everythingElse?.item?._id,
                quantity: 1,
              });
            } else if (items.defaultCategoryCode === "GSalad") {
              allItem.push({
                item: items?.everythingElse?.item?._id,
                quantity: 1,
              });
            } else if (items.defaultCategoryCode === "GSalad") {
              allItem.push({
                item: items?.everythingElse?.item?._id,
                quantity: 1,
              });
            } else {
              for (let indexing = 1; indexing <= items?.Qty; indexing++) {
                allItem.push({
                  item: items.allowedItems[0]._id,
                  quantity: 1,
                });
              }
            }
          }

          obj["items"] = allItem;

          itemFinalArray.push(obj);
        }
      }

      data.items = itemFinalArray;
      data.deletedItems = preFinalArray2;
      if (this.state.deliveryTime === "now") {
        data.deliveryTime = new Date();
      } else {
        data.deliveryTime = `${futureDate} ${futureTimmee}`;
      }

      let response;
      if (agentData?.isIntern) {
        response = await apiServices.modifyOrderByInternee(data);
      } else {
        response = await apiServices.modifyOrder(data);
      }

      if (response.data.status === 200) {
        this.setState({
          printOrderNumber: response?.data?.data?.orderNumber,
        });
        await localStoreUtil.remove_data("prevous_data");
        await this.setState(
          { orderPlaceSuccefully: true, activity: false },
          () => {
            setTimeout(async () => {
              await this.setState({
                completeModalOrder: false,
                orderPlaceSuccefully: false,
                ...initailState,
                ...initailStateCheck,
                itemList: [],
                orderFinish: false,
                customer: true,
                finish: false,
                pizzaComplete: "",
                everyThingComplete: "",
                finishComplete: "",
                // phone: "",
                storeName: "",
                customerErrors: undefined,
                customerComplete: "",
                customerPulseAddress: "",
              });
              this.exitOrderHandler();
            }, 5000); //5 Second delay
          }
        );
        toast.success("Order Place Successfully <3");
      } else {
        toast.error(`${response.data.message} ❕`);
      }
      this.setState({ activity: false });
    } catch (error) {
      console.log(error);
      toast.error(error.data.message);
      this.setState({ activity: false });
    }
  };

  orderPlace = async (paymentMethod, value) => {
    try {
      this.setState({ activity: true });

      let futureDate = moment(
        this.state?.selectedDateTime?.selectedDate
      ).format("YYYY-MM-DD");
      let futureTimmee = `${this.state?.selectedDateTime?.selectedHour}:00`;

      // deliveryTime: "2021-11-11 23:55:41"
      const data = {
        serviceMethod: this.state.serviceMethod,
        paymentMethod: paymentMethod,
        channel: "callcenter",
        driverInstruction: this.state.driverInstruction,
        deliveryInstructions:
          value === true
            ? this.state.deliveryInstructions
            : this.state.deliveryInstructionsCopy,
        isGuestOrder: false,
        futureOrder: this.state.deliveryTime === "now" ? false : true,
        address: this.state.customerStreet?._id,
        deductPoints: 0,
        loyaltyPointsRedeemed: false,
        items: [],
        store:
          this.state.serviceMethod === "delivery"
            ? this.state?.storeName?._id
            : this.state?.storeNameSelected?._id,
        user: this.state.customerObj?._id,
        contactInfo: {
          name:
            this.state.customerFirstName + " " + this.state.customerLastName,
          number: this.state.phone.startsWith("0")
            ? this.state.phone.replace(/^0+/, "")
            : this.state.phone,
        },
      };

      const itemFinalArray = [];
      let obj = {};

      // eslint-disable-next-line array-callback-return
      this.state.itemList.map((item) => {
        if (item.type === "pizza") {
          if (item.subType === "Half and Half") {
            const items = [
              {
                item: item?.pizza?.item?.Left?._id,
                extraToppings: item?.pizza?.toppings?.extraTopping?.Left?.map(
                  (extLeftItem) => {
                    return {
                      extTopping: extLeftItem._id,
                      modifierQty: extLeftItem.toppingSize,
                    };
                  }
                ),
                standardToppings: item?.pizza?.toppings?.standard?.Left?.map(
                  (stdLeftItem) => {
                    return {
                      stdTopping: stdLeftItem._id,
                      modifierQty: stdLeftItem.toppingSize,
                    };
                  }
                ),
              },
              {
                item: item?.pizza?.item?.Right?._id,
                extraToppings: item?.pizza?.toppings?.extraTopping?.Right?.map(
                  (extRightItem) => {
                    return {
                      extTopping: extRightItem._id,
                      modifierQty: extRightItem.toppingSize,
                    };
                  }
                ),
                standardToppings: item?.pizza?.toppings?.standard?.Right?.map(
                  (stdRightItem) => {
                    return {
                      stdTopping: stdRightItem._id,
                      modifierQty: stdRightItem.toppingSize,
                    };
                  }
                ),
              },
            ];
            let obj = {
              type: "half_half",
              items: items,
              quantity: item?.Qty,
              dough: item?.pizza?.cruust?._id,
              edge: item?.pizza?.cruust?.basic
                ? item?.pizza?.edge?._id
                : undefined,
              cookingInstructions: `${
                defaultCuts[item?.pizza?.cut || "RegularCut"]
              } ${defaultPismis[item?.pizza?.cook || ""]} ${
                item?.pizza?.cookingInstructions || ""
              }`,
              slices: this.getSliceFromSize(
                item?.pizza?.size,
                item?.pizza?.cut
              ),
              price: item?.price,
              size: defaultSizeCode[item?.pizza?.size],
            };
            if (this.state.freeCoupon?.text) {
              obj.discountCode = this.state.freeCoupon?.couponCode;
              obj["redeemedCoupon"] = this.state.freeCoupon?._id;
              obj["actualPrice"] = item?.regularPrice;
            }
            itemFinalArray.push(obj);
          } else {
            let obj = {
              item: item?.pizza?.item?.Whole?._id,
              dough: item?.pizza?.cruust?._id,
              edge: item?.pizza?.edge?._id || undefined,
              standardToppings: item?.pizza?.toppings?.standard?.Whole.map(
                (stdItem) => {
                  return {
                    stdTopping: stdItem._id,
                    modifierQty: stdItem.toppingSize,
                  };
                }
              ),
              extraToppings:
                item?.pizza?.toppings?.extraTopping?.Whole !== undefined
                  ? item?.pizza?.toppings?.extraTopping?.Whole.map(
                      (extItem) => {
                        return {
                          extTopping: extItem._id,
                          modifierQty: extItem.toppingSize,
                        };
                      }
                    )
                  : [],

              size: defaultSizeCode[item?.pizza?.size],
              quantity: item?.Qty,
              price: item?.price,
              slices: this.getSliceFromSize(
                item?.pizza?.size,
                item?.pizza?.cut
              ),
              cookingInstructions: `${
                defaultCuts[item?.pizza?.cut || "RegularCut"]
              } ${defaultPismis[item?.pizza?.cook || ""]} ${
                item?.pizza?.cookingInstructions || ""
              }`,
            };
            if (this.state.freeCoupon?.text) {
              obj.discountCode = this.state.freeCoupon?.couponCode;
              obj["redeemedCoupon"] = this.state.freeCoupon?._id;
              obj["actualPrice"] = item?.regularPrice;
            }
            itemFinalArray.push(obj);
          }
        } else if (item.type === "everythingElse") {
          let obj = {
            item: item?.everythingElse?.flavor
              ? item?.everythingElse?.flavor._id
              : item?.subType === "Drink"
              ? item?.everythingElse?.item?.variants
                ? item?.everythingElse?.item?.variants?.filter(
                    (sizeNameID) =>
                      sizeNameID.sizeName.en === item?.everythingElse?.size
                  )?.[0]?._id
                : item?.everythingElse?.item?._id
              : item?.everythingElse?.item?._id,
            quantity: item?.Qty,
            price: item?.price,
            cookingInstructions: "",
          };
          if (this.state.freeCoupon?.text) {
            obj.discountCode = this.state.freeCoupon?.couponCode;
            obj["redeemedCoupon"] = this.state.freeCoupon?._id;
            obj["actualPrice"] = item?.regularPrice;
          }
          itemFinalArray.push(obj);
        } else if (item.type === "coupons") {
          obj = {
            deal: item?.coupons?.isCombinedDeal
              ? item?.coupons?.subDeal?._id || item?.coupons?.deal?._id
              : item?.coupons?.deal?._id,
            actualPrice: Number(parseFloat(item?.actualPrice).toFixed(2)),
            items: [],
            price:
              item?.coupons?.myDealItems?.length > 0
                ? item?.coupons?.myDealItems?.reduce(
                    (total, currentItem) => total + currentItem?.price,
                    0
                  )
                : item?.price,
            quantity: item.Qty,
          };

          if (item?.coupons?.redeemedCoupon) {
            obj["redeemedCoupon"] = item?.coupons?.redeemedCoupon;
          }

          let allItem = [];

          for (
            let index = 0;
            index < item?.coupons?.myDealItems.length;
            index++
          ) {
            const items = item?.coupons?.myDealItems[index];
            if (items.defaultCategoryCode === "Pizza") {
              allItem.push({
                item: items?.pizza?.item?.Whole?._id,
                quantity: items?.Qty,
                size: defaultSizeCode[Number(items?.pizza?.size)],
                slices: this.getSliceFromSize(
                  items?.pizza?.size,
                  items?.pizza?.cut
                ),
                cookingInstructions: `${
                  defaultCuts[items?.pizza?.cut || "RegularCut"]
                } ${defaultPismis[items?.pizza?.cook || ""]} ${
                  items?.pizza?.cookingInstructions || ""
                }`,

                dough: items?.pizza?.cruust?._id,
                edge: items?.pizza?.edge?._id || undefined,
                extraToppings: (
                  items?.pizza?.toppings?.extraTopping || []
                )?.Whole?.map((exTop) => {
                  return {
                    extTopping: exTop._id,
                    modifierQty: exTop.toppingSize,
                  };
                }),
                standardToppings: (
                  items?.pizza?.toppings?.standard || []
                )?.Whole?.map((stdTop) => {
                  return {
                    stdTopping: stdTop._id,
                    modifierQty: stdTop.toppingSize,
                  };
                }),
              });
            } else if (items.defaultCategoryCode === "Drinks") {
              allItem.push({
                item: items?.everythingElse?.flavor
                  ? items?.everythingElse?.flavor._id
                  : items?.subType === "Drink"
                  ? items?.everythingElse?.item?.variants
                    ? items?.everythingElse?.item?.variants?.filter(
                        (sizeNameID) =>
                          sizeNameID.sizeName.en === items?.everythingElse?.size
                      )?.[0]?._id
                    : items?.everythingElse?.item?._id
                  : items?.everythingElse?.item?._id,
                quantity: 1,
              });
            } else if (items.defaultCategoryCode === "GSalad") {
              allItem.push({
                item: items?.everythingElse?.item?._id,
                quantity: 1,
              });
            } else if (items.defaultCategoryCode === "GSalad") {
              allItem.push({
                item: items?.everythingElse?.item?._id,
                quantity: 1,
              });
            } else if (items.defaultCategoryCode === "Bread") {
              allItem.push({
                item: items?.everythingElse?.item?._id,
                quantity: 1,
              });
            } else {
              for (let indexing = 1; indexing <= items?.Qty; indexing++) {
                allItem.push({
                  item: items.allowedItems[0]._id,
                  quantity: 1,
                });
              }
            }
          }

          obj["items"] = allItem;

          itemFinalArray.push(obj);
        }
      });

      data.items = itemFinalArray;

      const agentData = await localStoreUtil.get_data("agent");
      if (this.state.deliveryTime === "now") {
        data.deliveryTime = new Date();
      } else {
        data.deliveryTime = `${futureDate} ${futureTimmee}`;
      }

      let response;
      if (agentData?.isIntern) {
        response = await apiServices.orderPlaceByInternee(data);
      } else {
        response = await apiServices.orderPlace(data);
      }

      if (response.data.status === 200) {
        this.setState({
          printOrderNumber: response?.data?.data?.orderNumber,
        });
        await this.setState(
          { orderPlaceSuccefully: true, activity: false },
          () => {
            setTimeout(async () => {
              await this.setState({
                completeModalOrder: false,
                orderPlaceSuccefully: false,
                ...initailState,
                ...initailStateCheck,

                itemList: [],
                orderFinish: false,
                customer: true,
                finish: false,
                pizzaComplete: "",
                everyThingComplete: "",
                finishComplete: "",
                // phone: "",
                storeName: "",
                customerErrors: undefined,
                customerComplete: "",
                customerPulseAddress: "",
              });
              this.exitOrderHandler();
            }, 5000); //5 Second delay
          }
        );
        this.setState({ running: false });
        toast.success("Order Place Successfully <3");
      } else {
        toast.error(`${response.data.message} ❕`);
      }
      this.setState({ activity: false, running: false });
    } catch (error) {
      toast.error(`Error Occured While Placing Order ❕`);
      this.setState({ activity: false });
      console.log(error);
    }
  };

  checkData = async () => {
    this.setState({
      10: "",
      removedHalfnHalf: false,
    });
  };

  checkTime = (time) => {
    let correctTime;
    if (time <= 999) {
      correctTime = "0" + time;
    } else {
      correctTime = (time || 0).toString();
    }
    const hr = correctTime.slice(0, 2);
    const min = correctTime.slice(2, 4);
    // const meridiem =
    //   parseInt(hr) > parseInt('01') && parseInt(hr) <= parseInt('11')
    //     ? 'AM'
    //     : 'PM';
    return `${hr}:${min}`;
    // }
  };

  // Get All Active DCC coupons
  getAllCoupons = async () => {
    try {
      const response = await apiServices.getAllCoupons();
      if (response.data.status === 200) {
        this.setState({ agentCoupons: response.data.agentsCoupon });
        let deals = await this.isDealAvailable(response.data.updateData);

        this.setState({ allCouponsData: deals });
        this.nextBtnHandler(0, deals, 14, 1, "coupanData");
      } else {
        toast.error("Error Getting Coupons ❕");
      }
    } catch (error) {
      console.log(error);
    }
  };

  isDealAvailable = async (itemData) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let d = (new Date() / 1000) * 1000 - 5 * 60 * 60 * 1000;
    const date = new Date(d);
    const dayIndex = date.getDay();

    const deals = itemData?.filter((item) => {
      let isAvailable = item.days.some((day) => {
        return days[dayIndex] === day ? item : false;
      });

      if (isAvailable && (item || {}).startDate && (item || {}).endDate) {
        let startDateTime = item.startDate;
        let endDateTime = item.endDate;

        const start = startDateTime;
        const end = endDateTime;
        const now = moment()
          .utcOffset("+04:00")
          .format("YYYY-MM-DDTHH:mm:ss.000Z");

        if (now >= start && now <= end) {
          if (
            (item || {}).effectiveStartTime >= 0 &&
            (item || {}).effectiveEndTime >= 0
          ) {
            const current_date = parseInt(moment(new Date()).format("HHmm"));

            if (
              (item || {}).effectiveStartTime === (item || {}).effectiveEndTime
            ) {
              isAvailable = true;
            } else if (
              (item || {}).effectiveStartTime < (item || {}).effectiveEndTime &&
              current_date >= (item || {}).effectiveStartTime &&
              current_date <= (item || {}).effectiveEndTime
            ) {
              isAvailable = true;
            } else if (
              (item || {}).effectiveStartTime > (item || {}).effectiveEndTime &&
              current_date >= (item || {}).effectiveStartTime &&
              (current_date <= 2359 ||
                current_date <= (item || {}).effectiveEndTime)
            ) {
              isAvailable = true;
            } else {
              isAvailable = false;
            }
          } else {
            isAvailable = true;
          }
        } else {
          isAvailable = false;
        }
      }

      return isAvailable;
    });

    return deals;
  };

  getServiceMethodDeals = async (value) => {
    try {
      this.setState({
        activity: true,
      });

      const response = await apiServices.getServiceMethodDeals({
        serviceMethods: value,
      });

      if (response.data.status === 200) {
        let deals = await this.isDealAvailable(response.data.deal);

        this.setState({
          serviceMethodDeals: deals,
        });
      } else {
        toast.error(`${response.data.message} ❕`);
        this.setState({
          serviceMethodDeals: [],
        });
      }
      this.setState({
        activity: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        activity: false,
      });
    }
  };

  getProductCategories = async (value) => {
    try {
      const response = await apiServices.getProductCategories({
        code: value?.code,
      });

      if (response.data.status === 200) {
        let deals = await this.isDealAvailable(response.data.productDeals);

        if (deals.length === 0) {
          toast.error("No coupon found on this category");
        }

        this.setState({
          productDeals: deals,
        });
      } else {
        this.setState({
          productDeals: [],
        });

        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      this.setState({
        activity: false,
      });
    }
  };

  handleCouponClicked = async (SelectedDealsData, redeemedCoupon) => {
    try {
      let { store } = this.props;
      // enable loader
      this.setState({ activity: true });
      let ResponseCheck = store.get("pizza");
      let dataAllPizza = "";
      if (ResponseCheck) {
        dataAllPizza = ResponseCheck;
      } else {
        const ownPizaResponse = await apiServices.getMakeYourOwnPiza();
        const pizzaResponse = await apiServices.getMenuByType("pizza");
        dataAllPizza = await pizzaResponse.data.data;
        ownPizaResponse.data.data.options = ownPizaResponse.data.data.toppings;
        dataAllPizza.unshift(ownPizaResponse.data.data);
        await store.setWithRender("pizza", dataAllPizza);
      }
      this.setState({
        pizzaAllData: dataAllPizza,
      });
      this.nextBtnHandler(0, dataAllPizza, 11, 1, "specialityPizza");
      this.nextBtnHandler(0, dataAllPizza, 11, 1, "halfNhalfPizza");
      // get selected deal
      const comDeal = await apiServices.getCouponByID(SelectedDealsData?._id);
      // variables that will save data
      let myDeal = comDeal.data.data;
      let myDealItems = [];
      let cartItemIds = [];
      let dealItemsIds = [];
      let notIncludeItems = [];
      const isPresent = [];
      let newMyDealItems = [];
      let dealItemsObjectIds = [];

      /*  S T A R T */
      // get all cart items ids in array excepts coupons id
      // eslint-disable-next-line array-callback-return
      this.state.itemList.map((cartItem) => {
        if (cartItem.type !== "coupons") {
          let varii = [];
          if (cartItem.subType === "Drink") {
            varii = cartItem?.everythingElse?.item?.variants?.filter(
              (e) => e?.sizeName?.en === cartItem?.everythingElse?.size
            );
          }
          cartItemIds.push(
            cartItem?.everythingElse
              ? cartItem?.subType === "Drink"
                ? varii?.[0]?._id
                : cartItem?.everythingElse?.item?._id
              : cartItem?.pizza?.item?.Whole?._id
          );
        }
      });
      /*  E N D  */

      let allowedItemsIds = [];
      let drinkAllowedItemsIds = [];

      /*  S T A R T */
      // get all default item ids and dealItemObjects
      // eslint-disable-next-line array-callback-return
      myDeal.dealItems.map((dealItem) => {
        dealItemsObjectIds.push(dealItem);
        if (dealItem?.defaultCategoryCode === "Pizza") {
          dealItemsIds.push(dealItem.defaultProduct._id);
          notIncludeItems.push(dealItem?.defaultProduct?._id);
          allowedItemsIds = [
            ...allowedItemsIds,
            ...dealItem.allowedItems.map((item) => item._id),
          ];
        } else if (dealItem?.defaultCategoryCode === "Drinks") {
          dealItemsIds.push(dealItem.allowedItems[0]._id);
          notIncludeItems.push(dealItem.allowedItems[0]._id);
          drinkAllowedItemsIds = [
            drinkAllowedItemsIds,
            ...dealItem.allowedItems.map((item) => item._id),
          ];
        } else {
          dealItemsIds.push(dealItem.allowedItems[0]._id);
          notIncludeItems.push(dealItem.allowedItems[0]._id);
        }
      });
      /*  E N D  */

      /*  S T A R T */
      // this map will match cart items with dealItems and remove matched items from cart
      let cartIItem = this.state.itemList;
      let newCardIds = [];
      // eslint-disable-next-line array-callback-return
      dealItemsObjectIds.map((myITEM, iiddxx) => {
        for (let abc = 0; abc < this.state.itemList?.length; abc++) {
          const elementsss = this.state.itemList[abc];
          if (elementsss?.type !== "coupons") {
            if (myITEM?.defaultCategoryCode === "Pizza") {
              if (
                Number(elementsss?.pizza?.size) ===
                Number(myITEM?.defaultSizeCode)
              ) {
                if (
                  myITEM.allowedItems.some(
                    (lll) => lll?._id === elementsss?.pizza?.item?.Whole?._id
                  )
                ) {
                  newMyDealItems.push({ ...elementsss, dealCartIndex: iiddxx });
                  newCardIds.push({ ...elementsss, dealCartIndex: iiddxx });
                  isPresent.push(elementsss?.pizza?.item?.Whole?._id);
                  this.state.itemList?.splice(abc, 1);
                  break;
                } else {
                  continue;
                }
              } else {
                continue;
              }
            } else if (myITEM.defaultCategoryCode === "Drinks") {
              const xyz = elementsss?.everythingElse?.item?.variants?.filter(
                (iteeem) =>
                  iteeem?.sizeName?.en === elementsss?.everythingElse?.size
              );
              if (
                myITEM.allowedItems.some((lll) => lll?._id === xyz?.[0]?._id)
              ) {
                newCardIds.push({ ...elementsss, dealCartIndex: iiddxx });
                newMyDealItems.push({ ...elementsss, dealCartIndex: iiddxx });
                isPresent.push(xyz?.[0]?._id);
                this.state.itemList.splice(abc, 1);

                break;
              } else {
                continue;
              }
            } else {
              if (
                myITEM.allowedItems.some(
                  (lll) => lll._id === elementsss?.everythingElse?.item?._id
                )
              ) {
                isPresent.push(elementsss?.everythingElse?.item?._id);
                newCardIds.push({ ...elementsss, dealCartIndex: iiddxx });
                newMyDealItems.push({ ...elementsss, dealCartIndex: iiddxx });
                this.state.itemList.splice(abc, 1);
                break;
              } else {
                continue;
              }
            }
          } else {
            continue;
          }
        }
        return "";
      });
      this.setState({
        itemList: cartIItem,
      });
      /*  E N D  */

      /*  S T A R T */
      //this loop will get only deal items id which is not present in cart
      isPresent.forEach((val, inddd2) => {
        for (let j = 0; j < notIncludeItems.length; j++) {
          const element = notIncludeItems[j];
          if (drinkAllowedItemsIds.includes(val)) {
            const isPresentDrink = drinkAllowedItemsIds.filter(
              (drinkVal) => drinkVal === element
            );
            const index = notIncludeItems.indexOf(isPresentDrink[0]);
            if (index > -1) {
              notIncludeItems.splice(index, 1);
              break;
            }
          }

          if (allowedItemsIds.includes(val)) {
            const isPresentPizza = allowedItemsIds.filter(
              (pizzaVal) => pizzaVal === element
            );
            const idxx = notIncludeItems.indexOf(isPresentPizza[0]);
            if (idxx > -1) {
              notIncludeItems.splice(idxx, 1);
              break;
            }
          }

          if (element === val) {
            const index = notIncludeItems.indexOf(element);
            if (index > -1) {
              notIncludeItems.splice(index, 1);
              break;
            }
          }
        }
      });
      /*  E N D  */

      /*  S T A R T */
      // from above loop we have the items ids which is not in cart so this loop will push the item on behalf of notIncluded item id
      notIncludeItems.forEach((notIncludedItem, notIncludedItemIndex) => {
        for (
          let myDealIndex = 0;
          myDealIndex < dealItemsObjectIds.length;
          myDealIndex++
        ) {
          const myValue = dealItemsObjectIds[myDealIndex];
          if (myValue.defaultCategoryCode === "Pizza") {
            if (
              myValue?.defaultProduct?._id === notIncludedItem &&
              !newMyDealItems.some((i) => i.dealCartIndex === myDealIndex)
            ) {
              let pizza = {
                item: { Whole: myValue?.defaultProduct },
                size: myValue?.defaultSizeCode,
                cruust: this.state.crustAllData[0],
                edge: undefined,
                toppings: {
                  standard: {
                    Whole: myValue?.defaultProduct?.options || [],
                  },
                  extraTopping: {},
                },
              };
              newMyDealItems.push({
                pizza,
                dealCartIndex: myDealIndex,
                price: myValue?.minimumPrice,
                subType: "Speciality Pizza",
                type: "pizza",
                Qty: 1,
              });
              break;
            }
          } else if (myValue.defaultCategoryCode === "Drinks") {
            if (drinkAllowedItemsIds.includes(notIncludedItem)) {
              let everythingElse = {
                flavorKey:
                  myValue.allowedItems[0]?.flavorCode === "JALEME1" ||
                  myValue.allowedItems[0]?.flavorCode === "QSU05" ||
                  myValue.allowedItems[0]?.flavorCode === "ICETEA1" ||
                  myValue.allowedItems[0]?.flavorCode === "_AYRAN"
                    ? true
                    : false,
                item: myValue.allowedItems[0],
                size: myValue.allowedItems[0].sizeName.en,
              };

              newMyDealItems.push({
                everythingElse,
                dealCartIndex: myDealIndex,
                price: myValue?.minimumPrice,
                subType: "Drink",
                type: "everythingElse",
                Qty: 1,
              });
              break;
            }
          } else {
            if (myValue?.allowedItems[0]?._id === notIncludedItem) {
              let everythingElse = {
                item: myValue.allowedItems[0],
              };
              newMyDealItems.push({
                everythingElse,
                dealCartIndex: myDealIndex,
                price: myValue?.minimumPrice,
                subType: myValue.allowedItems[0].type,
                type: "everythingElse",
                Qty: 1,
              });
              break;
            }
          }
        }
      });
      /*  E N D  */

      /*  S T A R T */
      // this will sort the new dealItems array as it is like dealItems (which we get from API)
      newMyDealItems.sort((a, b) =>
        (a || {}).dealCartIndex > (b || {}).dealCartIndex ? 1 : -1
      );
      /*  E N D  */

      /*  S T A R T */
      // simply making the newDealItems on behalf of requirements
      myDeal.dealItems?.map((item, index) => {
        if (item.allowedItems.length >= 1) {
          if (item.defaultCategoryCode === "Pizza") {
            if (newMyDealItems.length > 0) {
              if (newMyDealItems[index].type === "pizza") {
                newMyDealItems[index].pizza = {
                  ...newMyDealItems[index].pizza,
                  size: item.defaultSizeCode,
                };
                myDealItems.push({
                  ...item,
                  ...newMyDealItems[index],
                  discountValue: item?.maxDiscount,
                  size: item.defaultSizeCode,
                  isCoupon: true,
                  price:
                    item?.minimumPrice +
                    (newMyDealItems[index]?.pizza?.toppingPrice || 0),
                  error: "NoError",
                });
              } else {
                myDealItems.push({
                  ...item,
                  pizza: {
                    item: { Whole: item.defaultProduct },
                    size: item.defaultSizeCode,
                    cruust: this.state.crustAllData[0],
                    edge: undefined,
                    toppings: {
                      standard: {
                        Whole: item.defaultProduct.options,
                        // Left: [],
                        // Right: [],
                      },
                      extraTopping: {},
                    },
                  },
                  price:
                    item?.minimumPrice +
                    (newMyDealItems[index]?.pizza?.toppingPrice || 0),
                  error: "NoError",
                  subType: "Speciality Pizza",
                  type: "pizza",
                  Qty: item.requiredQuantity,
                  isCoupon: true,
                });
              }
            } else {
              myDealItems.push({
                ...item,
                pizza: {
                  item: { Whole: item.defaultProduct },
                  size: item.defaultSizeCode,
                  cruust: this.state.crustAllData[0],
                  edge: undefined,
                  toppings: {
                    standard: {
                      Whole: item.defaultProduct.options,
                    },
                    extraTopping: {},
                  },
                },
                price:
                  item?.minimumPrice +
                  (newMyDealItems[index]?.pizza?.toppingPrice || 0),
                error: "NoError",
                subType: "Speciality Pizza",
                type: "pizza",
                Qty: item.requiredQuantity,
                isCoupon: true,
              });
            }

            const matchPizza = this.state.pizzaAllData?.filter(
              (data, index) => {
                return item.allowedItems?.some((_topping) => {
                  return _topping._id === (data || {})._id;
                });
              }
            );
            this.setState({
              pizzaAllData: matchPizza,
            });
            this.nextBtnHandler(0, matchPizza, 11, 1, "halfNhalfPizza");
            this.nextBtnHandler(0, matchPizza, 11, 1, "specialityPizza");
          } else if (item.defaultCategoryCode === "Drinks") {
            var result = this.state.DrinkList.filter(function (o1) {
              return item.allowedItems.some(function (o2) {
                return o1.flavorCode === o2.flavorCode; // return the ones with equal id
              });
            });

            this.setState({
              DrinkList: result,
            });

            if (newMyDealItems.length > 0) {
              if (newMyDealItems[index].subType === "Drink") {
                myDealItems.push({
                  ...item,
                  ...newMyDealItems[index],
                  defaultProduct: newMyDealItems[index].everythingElse.item,
                  price: item?.minimumPrice,
                  isCoupon: true,
                  error: "NoError",
                });
              } else {
                myDealItems.push({
                  ...item,
                  defaultProduct: item.allowedItems[0],
                  everythingElse: {
                    flavorKey:
                      item.allowedItems[0]?.flavorCode === "JALEME1" ||
                      item.allowedItems[0]?.flavorCode === "QSU05" ||
                      item.allowedItems[0]?.flavorCode === "ICETEA1" ||
                      item.allowedItems[0]?.flavorCode === "_AYRAN"
                        ? true
                        : false,
                    item: item.allowedItems[0],
                    size: item.allowedItems[0]?.sizeName?.en,
                  },
                  error: "NoError",
                  type: "everythingElse",
                  subType:
                    item.allowedItems[0]?.type === "sides"
                      ? "Dips"
                      : capitalize(item.allowedItems[0]?.type || ""),
                  Qty: item.requiredQuantity,
                  price: item?.minimumPrice,
                  isCoupon: true,
                });
              }
            } else {
              myDealItems.push({
                ...item,
                defaultProduct: item.allowedItems[0],
                everythingElse: {
                  flavorKey:
                    item.allowedItems[0]?.flavorCode === "JALEME1" ||
                    item.allowedItems[0]?.flavorCode === "QSU05" ||
                    item.allowedItems[0]?.flavorCode === "ICETEA1" ||
                    item.allowedItems[0]?.flavorCode === "_AYRAN"
                      ? true
                      : false,
                  item: item.allowedItems[0],
                  size: item.allowedItems[0]?.sizeName?.en,
                },
                error: "NoError",
                type: "everythingElse",
                subType:
                  item.allowedItems[0]?.type === "sides"
                    ? "Dips"
                    : capitalize(item.allowedItems[0]?.type || ""),
                Qty: item.requiredQuantity,
                price: item?.minimumPrice,
                isCoupon: true,
              });
            }
          } else {
            myDealItems.push({
              ...item,
              defaultProduct: item.allowedItems[0],
              everythingElse: {
                item: item.allowedItems[0],
              },
              error: "NoError",
              type: "everythingElse",
              subType:
                item.allowedItems[0]?.type === "sides"
                  ? "Dips"
                  : capitalize(item.allowedItems[0]?.type || ""),
              Qty: item.requiredQuantity,
              price: item?.minimumPrice,
              isCoupon: true,
            });
          }
          return "";
        } else {
          myDealItems.push({
            ...item,
            defaultProduct: item.allowedItems[0],
            everythingElse: {
              item: item.allowedItems[0],
            },
            error: "NoError",
            type: "everythingElse",
            subType:
              item.allowedItems[0]?.type === "sides"
                ? "Dips"
                : capitalize(item.allowedItems[0]?.type || ""),
            Qty: item.requiredQuantity,
            price: item?.minimumPrice,
            isCoupon: true,
          });
        }
        return "";
      });
      /*  E N D  */

      // if deal is combined then this func will return the single deal on behalf of items
      let data = await this.getSpecialDealId(myDeal, myDealItems);
      const { storeNameSelected, serviceMethod, storeName } = this.state;

      // this function will return deal error if available (not valid for delivery / not valid for {$Store} ...etc )
      let error = await getDealsError({
        state: { storeNameSelected, serviceMethod, storeName },
        deal: myDeal,
      });
      // making new object of coupons that will save in cart
      if (data?.dealItems?.length) {
        for (let aabcde = 0; aabcde < myDeal.dealItems.length; aabcde++) {
          const aabbcc = myDeal.dealItems[aabcde];
          data.dealItems[aabcde].allowedItems = aabbcc.allowedItems;
        }
        myDeal.dealItems = data?.dealItems;

        for (let idxxxxxx = 0; idxxxxxx < data?.dealItems?.length; idxxxxxx++) {
          const subDataaaaa = data?.dealItems[idxxxxxx];
          for (let iddf = 0; iddf < myDeal.dealItems.length; iddf++) {
            const deaaaaa = myDeal.dealItems[iddf];
            if (`${deaaaaa?._id}` === `${subDataaaaa?._id}`) {
              if (subDataaaaa?.defaultCategoryCode === "Pizza") {
                myDeal.dealItems[iddf] = {
                  ...myDeal.dealItems[iddf],
                  ...subDataaaaa,
                };
                myDealItems[iddf] = {
                  ...myDealItems[iddf],
                  ...subDataaaaa,
                };
                let crustt =
                  myDealItems[iddf]?.pizza?.cruust?.price[
                    defaultSizeCode[myDealItems[iddf]?.pizza?.size]
                  ] === -1
                    ? 0
                    : myDealItems[iddf]?.pizza?.cruust?.price[
                        defaultSizeCode[myDealItems[iddf]?.pizza?.size]
                      ] || 0;
                let topppp = myDealItems[iddf]?.pizza?.toppingPrice || 0;
                let edgeeee =
                  myDealItems[iddf]?.pizza?.edge?.price[
                    defaultSizeCode[Number(myDealItems[iddf]?.pizza?.size)]
                  ] || 0;

                myDealItems[iddf].price =
                  subDataaaaa?.minimumPrice + crustt + topppp + edgeeee;
              } else {
                myDeal.dealItems[iddf] = {
                  ...myDeal.dealItems[iddf],
                  ...subDataaaaa,
                };
                myDealItems[iddf] = {
                  ...myDealItems[iddf],
                  ...subDataaaaa,
                };
                myDealItems[iddf].price = subDataaaaa?.minimumPrice;
              }
            }
          }
        }
      }

      let obj = {
        coupons: {
          deal_id: myDeal?._id,
          deal: { ...myDeal },
          subDeal: data,
          dealItems: myDeal.dealItems,
          myDealItems: myDealItems,
          isCombinedDeal: SelectedDealsData?.combined,
          redeemedCoupon,
        },
        error: error,
        Qty: 1,
        type: "coupons",
        subType: "All Coupons",
        price: data
          ? data.price
            ? data.price
            : data.minimumPrice
            ? data.minimumPrice
            : Number(data?.priceWithApi?.paymentAmount)
          : myDeal.minimumPrice,
        actualPrice: data
          ? data.price
            ? data.price + data.maxDiscount
            : data.minimumPrice
            ? data.minimumPrice + data.maxDiscount
            : Number(data?.priceWithApi?.paymentAmount) +
              Number(data?.priceWithApi?.adjustmentAmount)
          : myDeal.minimumPrice + myDeal.maxDiscount,
      };

      // this function will set coupon in cart
      this.selectCouponHandler(obj);

      // show error and removing loader
      this.setState({
        activity: false,
        couponsComplete: error === "NoError" ? true : false,
      });

      // this will highlight pizza / everythingElse side button to green
      this.checkSelectedItemError(this.state.itemList);

      let top = (document.getElementsByClassName("task-list").scrollTop += 500);

      this.setState({
        scrioll: top,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        activity: false,
      });
    }
  };

  getSpecialDealId = async (deal, item) => {
    try {
      const _finalItems = item; // finalItems == myDealItms

      if (_finalItems.length === deal.dealItems.length) {
        // const { subcat, deal, this.state.selectedPizza } = this.state;
        // pizza extract krna hay idhr
        let pizzaOnlyItems = _finalItems.filter((_dealItem, index) => {
          return ((_dealItem || {}).type || "").toLowerCase() === "pizza";
        });

        let everyThingElseItems = _finalItems.filter((_dealItem, index) => {
          return ((_dealItem || {}).type || "").toLowerCase() !== "pizza";
        });

        // expected return pizza sub cat ["special","favorite"] | ["special","special"] | ["favorite","favorite"]
        const dealCartItemsSubCategory = pizzaOnlyItems.map(
          (dealItem, index) => {
            return (dealItem || {})?.pizza?.item?.Whole?.subCategory ===
              "favorites"
              ? "favorite"
              : (dealItem || {})?.pizza?.item?.Whole?.subCategory;
          }
        );

        let subDealId = [];

        if ((deal || {}).subDeals.length > 0) {
          subDealId = (deal || {}).subDeals.filter((deal) => {
            let dealSubCategory = JSON.parse(
              JSON.stringify(deal.subCategories)
            );
            return (dealCartItemsSubCategory || []).every((_subCategory) => {
              return (dealSubCategory || []).some((_dealSubCategory, index) => {
                if (
                  (_subCategory || "").toLowerCase() ===
                  (_dealSubCategory || "").toLowerCase()
                ) {
                  dealSubCategory.splice(index, 1);
                  return true;
                } else {
                  return false;
                }
              });
            });
          });
        }

        this.setState({ activity: true });

        const itemArray = [];
        let obj = {};

        // eslint-disable-next-line array-callback-return
        pizzaOnlyItems?.map((item) => {
          obj = {
            item: item?.pizza?.item?.Whole?._id,
            standardToppings: item?.pizza?.toppings?.standard?.Whole?.map(
              (item) => {
                return {
                  stdTopping: item?._id,
                  modifierQty: item?.toppingSize,
                };
              }
            ),
            extraToppings: item?.pizza?.toppings?.extraTopping?.Whole?.map(
              (item) => {
                return {
                  extTopping: item?._id,
                  modifierQty: item?.toppingSize,
                };
              }
            ),
            dough: item?.pizza?.cruust?._id,
            edge: item?.pizza?.edge?._id || undefined,
            // slice: item?.pizza,
            quantity: item?.Qty,
            size: defaultSizeCode[Number(item?.pizza?.size)],
          };
          itemArray.push(obj);
        });

        // eslint-disable-next-line array-callback-return
        everyThingElseItems.map((item) => {
          obj = {
            item: item?.everythingElse?.flavor
              ? item?.everythingElse?.flavor._id
              : item?.subType === "Drink"
              ? item?.everythingElse?.item?.variants
                ? item?.everythingElse?.item?.variants?.filter(
                    (sizeNameID) =>
                      sizeNameID.sizeName.en === item?.everythingElse?.size
                  )?.[0]?._id
                : item?.everythingElse?.item?._id
              : item?.everythingElse?.item?._id,
            quantity: item?.Qty,
            price: item?.price,
            cookingInstructions: "",
          };

          itemArray.push(obj);
        });

        const items = {
          items: [
            {
              deal: (subDealId[0] || {})._id || (deal || {})._id,
              quantity: 1,
              items: itemArray,
            },
          ],
          channel: "callcenter",
        };

        const response = await apiServices.getPrice(items);
        this.setState({ activity: false });
        const dataWithPrice = subDealId[0] || {} || deal || {};

        if (
          (subDealId[0] || {}).code === "_O055" ||
          (subDealId[0] || {}).code === "_O0551" ||
          (subDealId[0] || {}).code === "_O0552"
        ) {
          const price = _finalItems.map((item) => {
            return Number(item.price);
          });

          const priceSum = _.sum(price);

          return {
            ...dataWithPrice,
            price: priceSum,
            priceWithApi: response?.data?.data,
          };
        } else {
          return {
            ...dataWithPrice,
            priceWithApi: response?.data?.data,
          };
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ activity: false });
    }
  };

  selectCouponHandler = async (deal) => {
    try {
      const { itemList } = this.state;
      itemList.push(deal);
      await this.setState({
        itemList,
        listIndexSet: itemList.length - 1,
        couponsComplete: true,
      });
    } catch (error) {
      this.setState({
        activity: false,
      });
      console.log(error);
    }
  };

  getTaxAndTotal = async (popupModel = false) => {
    try {
      const { itemList } = this.state;
      this.setState({ activity: true });
      const itemFinalArray = [];
      this.setState({ openTax: popupModel === true ? false : true });

      if (itemList.length > 0) {
        this.state.itemList.map((item) => {
          if (item.type === "pizza") {
            if (item.subType === "Half and Half") {
              const items = [
                {
                  item: item?.pizza?.item?.Left?._id,
                  extraToppings: item?.pizza?.toppings?.extraTopping?.Left?.map(
                    (extLeftItem) => {
                      return {
                        extTopping: extLeftItem._id,
                        modifierQty: extLeftItem.toppingSize,
                      };
                    }
                  ),
                  standardToppings: item?.pizza?.toppings?.standard?.Left?.map(
                    (stdLeftItem) => {
                      return {
                        stdTopping: stdLeftItem._id,
                        modifierQty: stdLeftItem.toppingSize,
                      };
                    }
                  ),
                },
                {
                  item: item?.pizza?.item?.Right?._id,
                  extraToppings:
                    item?.pizza?.toppings?.extraTopping?.Right?.map(
                      (extRightItem) => {
                        return {
                          extTopping: extRightItem._id,
                          modifierQty: extRightItem.toppingSize,
                        };
                      }
                    ),
                  standardToppings: item?.pizza?.toppings?.standard?.Right?.map(
                    (stdRightItem) => {
                      return {
                        stdTopping: stdRightItem._id,
                        modifierQty: stdRightItem.toppingSize,
                      };
                    }
                  ),
                },
              ];
              let obj = {
                type: "half_half",
                items: items,
                quantity: item?.Qty,
                dough: item?.pizza?.cruust?._id,
                edge: item?.pizza?.cruust?.basic
                  ? item?.pizza?.edge?._id
                  : undefined,
                cookingInstructions: `${
                  defaultCuts[item?.pizza?.cut || "RegularCut"]
                } ${defaultPismis[item?.pizza?.cook || ""]} ${
                  item?.pizza?.cookingInstructions || ""
                }`,
                slices: this.getSliceFromSize(
                  item?.pizza?.size,
                  item?.pizza?.cut
                ),
                size: defaultSizeCode[item?.pizza?.size],
              };
              if (this.state.freeCoupon?.text) {
                obj.discountCode = this.state.freeCoupon?.couponCode;
                obj["actualPrice"] = item?.regularPrice;
              }
              return itemFinalArray.push(obj);
            } else {
              let obj = {
                item: item?.pizza?.item?.Whole?._id,
                dough: item?.pizza?.cruust?._id,
                edge: item?.pizza?.edge?._id || undefined,
                standardToppings: item?.pizza?.toppings?.standard?.Whole.map(
                  (stdItem) => {
                    return {
                      stdTopping: stdItem?._id,
                      modifierQty: stdItem.toppingSize,
                    };
                  }
                ),
                extraToppings:
                  item?.pizza?.toppings?.extraTopping?.Whole !== undefined
                    ? item?.pizza?.toppings?.extraTopping?.Whole.map(
                        (extItem) => {
                          return {
                            extTopping: extItem?._id,
                            modifierQty: extItem.toppingSize,
                          };
                        }
                      )
                    : [],

                size: defaultSizeCode[item?.pizza?.size],
                quantity: item?.Qty,
                price: item?.price,
                slices: this.getSliceFromSize(
                  item?.pizza?.size,
                  item?.pizza?.cut
                ),
                cookingInstructions: `${
                  defaultCuts[item?.pizza?.cut || "RegularCut"]
                } ${defaultPismis[item?.pizza?.cook || ""]} ${
                  item?.pizza?.cookingInstructions || ""
                }`,
              };
              if (this.state.freeCoupon?.text) {
                obj.discountCode = this.state.freeCoupon?.couponCode;
                obj["actualPrice"] = item?.regularPrice;
              }
              return itemFinalArray.push(obj);
            }
          } else if (item.type === "everythingElse") {
            let obj = {
              item: item?.everythingElse?.flavor
                ? item?.everythingElse?.flavor._id
                : item?.subType === "Drink"
                ? item?.everythingElse?.item?.variants?.length > 0
                  ? item?.everythingElse?.item?.variants?.filter(
                      (sizeNameID) =>
                        sizeNameID.sizeName.en === item?.everythingElse?.size
                    )?.[0]?._id
                  : item?.everythingElse?.item?._id
                : item?.everythingElse?.item?._id,
              quantity: item?.Qty,
              price: item?.price,
              cookingInstructions: "",
            };
            if (this.state.freeCoupon?.text) {
              obj.discountCode = this.state.freeCoupon?.couponCode;
              obj["actualPrice"] = item?.regularPrice;
            }
            itemFinalArray.push(obj);
          } else if (item.type === "coupons") {
            let obj = {
              deal: item?.coupons?.isCombinedDeal
                ? item?.coupons?.subDeal?._id || item?.coupons?.deal?._id
                : item?.coupons?.deal?._id,
              items: [],
              quantity: item.Qty,
            };
            const items = item?.coupons?.myDealItems?.map((dealItem, index) => {
              if (dealItem.defaultCategoryCode !== "Pizza") {
                return { item: dealItem.allowedItems[0]._id, quantity: 1 };
              } else if (dealItem.defaultCategoryCode === "Drinks") {
                return {
                  item: dealItem?.everythingElse?.flavor
                    ? dealItem?.everythingElse?.flavor._id
                    : dealItem?.subType === "Drink"
                    ? dealItem?.everythingElse?.item?.variants?.filter(
                        (sizeNameID) =>
                          sizeNameID.sizeName.en ===
                          dealItem?.everythingElse?.size
                      )?.[0]?._id
                    : dealItem?.everythingElse?.item?._id,
                  quantity: 1,
                };
              } else {
                return {
                  item: dealItem?.pizza?.item?.Whole?._id,
                  quantity: dealItem?.Qty,
                  size: defaultSizeCode[Number(dealItem.defaultSizeCode)],
                  slices: this.getSliceFromSize(
                    dealItem?.pizza?.size,
                    dealItem?.pizza?.cut
                  ),

                  cookingInstructions: `${
                    defaultCuts[item?.pizza?.cut || "RegularCut"]
                  } ${defaultPismis[item?.pizza?.cook || ""]} ${
                    item?.pizza?.cookingInstructions || ""
                  }`,
                  dough: dealItem?.pizza?.cruust?._id,
                  edge: dealItem?.pizza?.edge
                    ? dealItem?.pizza?.cruust?.basic
                      ? dealItem?.pizza?.edge?._id
                      : undefined
                    : undefined,
                  extraToppings:
                    dealItem?.pizza?.toppings?.extraTopping?.Whole?.map(
                      (extLeftItem) => {
                        return {
                          extTopping: extLeftItem._id,
                          modifierQty: extLeftItem.toppingSize,
                        };
                      }
                    ) || [],
                  standardToppings:
                    dealItem?.pizza?.toppings?.standard?.Whole?.map(
                      (stdLeftItem) => {
                        return {
                          stdTopping: stdLeftItem._id,
                          modifierQty: stdLeftItem.toppingSize,
                        };
                      }
                    ) || [],
                };
              }
            });
            obj["items"] = items;

            itemFinalArray.push(obj);
          }
          return "";
        });
        const taxResponse = await apiServices.getTax({
          items: itemFinalArray,
          channel: "callcenter",
        });
        this.setState({
          taxData: taxResponse.data.data,
          activity: false,
        });
      } else {
        this.setState({
          taxData: [],
          activity: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ activity: false });
    }
  };

  sendLocationSms = async () => {
    try {
      if (this.state.phone && this.state.phone.length > 8) {
        let response = await apiServices.sendSMSLocation({
          countryCode: this.state.countryCode.replace("+", ""),
          mobile: this.state.phone.startsWith("0")
            ? this.state.phone.replace(/^0+/, "")
            : this.state.phone,
        });
        if (response.data.status === 200) {
          toast.success(response.data.messege);
        } else {
          toast.error("Error Ouccur");
        }
      } else {
        toast.error("Invalid Mobile Number");
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleFreeCoupouns = async (coupon) => {
    try {
      this.setState({ activity: true });
      let { itemList, rewards } = this.state;
      await this.props.store.setWithRender("freeCoupon", coupon);

      let arr = [];
      for (let index = 0; index < itemList.length; index++) {
        const element = itemList[index];
        if (element.type !== "coupons") {
          let discountValue = (element.price / 100) * coupon?.discountValue;
          let discountPrice = element.price - discountValue;
          let obj = element;
          obj["isDiscount"] = true;
          obj["showMenuAmount"] = true;
          obj["regularPrice"] = element.price * element?.Qty;
          obj["price"] = discountPrice * element?.Qty;
          arr.push(obj);
        } else {
          arr.push(element);
        }
      }
      let index = rewards.findIndex((item) => item?._id === coupon?._id);
      rewards[index] = { ...rewards[index], redeemed: true };
      toast.success(`${coupon?.description?.enCC} Applied`);
      this.setState({
        activity: false,
        freeCoupon: coupon,
        itemList: arr,
        rewards,
      });
    } catch (error) {
      console.log(error, "handleFreeCoupouns");
      this.setState({ activity: false });
    }
  };

  removeFreeCoupon = async () => {
    try {
      this.setState({ activity: true });
      let { itemList, freeCoupon, rewards } = this.state;
      await this.props.store.remove("freeCoupon");
      let arr = [];
      for (let index = 0; index < itemList.length; index++) {
        const element = itemList[index];

        if (element.type !== "coupons") {
          let obj = element;
          obj["isDiscount"] = false;
          obj["showMenuAmount"] = false;
          obj["price"] = obj["regularPrice"];
          arr.push(obj);
        } else {
          arr.push(element);
        }
      }

      let index = rewards.findIndex((item) => item?._id === freeCoupon?._id);
      rewards[index] = { ...rewards[index], redeemed: false };

      toast.success(`${freeCoupon?.description?.enCC} Removed`);
      this.setState({
        activity: false,
        freeCoupon: {},
        itemList: arr,
        rewards,
      });
    } catch (error) {
      console.log(error, "removeFreeCoupon");
      this.setState({ activity: false });
    }
  };

  render() {
    return (
      <div
        style={{ overflowY: "scroll", height: "91.5vh" }}
        id="parentDivScroll"
      >
        <Suspense fallback={<Loader open={this.state.activity} />}>
          <OrderComponant
            removeDealItem={this.removeDealItem}
            parentRef={this.parentRef}
            checkData={this.checkData}
            {...this.state}
            {...this.props}
            addAddressFromMap={this.addAddressFromMap}
            checkTime={this.checkTime}
            handleCouponClicked={this.handleCouponClicked}
            RightMenuHandler={this.RightMenuHandler}
            SelectedTabChange={this.SelectedTabChange}
            nextBtnHandler={this.nextBtnHandler}
            laterDelivery={this.laterDelivery}
            mapHandler={this.mapHandler}
            selectSpecializePizzaHandler={this.selectSpecializePizzaHandler}
            selectEveryThingHandlerOne={this.selectEveryThingHandlerOne}
            addPizaHandler={this.addPizaHandler}
            countHanler={this.countHanler}
            getAddressFromMap={this.getAddressFromMap}
            handleChangeMobile={this.handleChangeMobile}
            getUserByPhone={this.getUserByPhone}
            customerFormOnChangeHandler={this.customerFormOnChangeHandler}
            getStoreByLocation={this.getStoreByLocation}
            stateToggleHandler={this.stateToggleHandler}
            addCustomer={this.addCustomer}
            customerErrorTest={this.customerErrorTest}
            removerFromCart={this.removerFromCart}
            orderPlace={this.orderPlace}
            // modalCLosOrder={this.modalCLosOrder}
            getAndSetModifyData={this.getAndSetModifyData}
            getToppingsDate={this.getToppingsDate}
            cancelOrder={this.cancelOrder}
            exitOrderHandler={this.exitOrderHandler}
            addAddress={this.addAddress}
            updateAddress={this.updateAddress}
            removeDealFromCart={this.removeDealFromCart}
            addDeal={this.addDeal}
            setLocalizeContent={this.setLocalizeContent}
            getTaxAndTotal={this.getTaxAndTotal}
            getServiceMethodDeals={this.getServiceMethodDeals}
            getProductCategories={this.getProductCategories}
            modifyOrderPlace={this.modifyOrderPlace}
            sendLocationSms={this.sendLocationSms}
            updateCustomerProfile={this.updateCustomerProfile}
            handleFreeCoupouns={this.handleFreeCoupouns}
            removeFreeCoupon={this.removeFreeCoupon}
          />
        </Suspense>
        <Dialog
          open={this.state.openTax || false}
          onClose={() => this.setState({ openTax: false })}
          maxWidth={"sm"}
          fullWidth
          PaperProps={{
            style: {
              background: "white",
              boxShadow: "none",
            },
          }}
        >
          <DialogTitle style={{ backgroundColor: "var(--dominos_blue)" }}>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <span
                  className={"fonts-loaded"}
                  style={{ fontFamily: "BabasNeueBold", color: "white" }}
                >
                  Tax and Total
                </span>
              </div>
              <div>
                <CancelIcon
                  onClick={() => this.setState({ openTax: false })}
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: 28,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <Paper
              style={{
                boxShadow: "none",
              }}
            >
              <Card
                style={{
                  boxShadow: "none",
                }}
              >
                <Grid container spacing={2}>
                  <Grid xs={8} item>
                    <p style={{ fontWeight: 600, color: "black" }}>Sub Total</p>
                    <p style={{ fontWeight: 600, color: "black" }}>G.S.T</p>
                    <p style={{ fontWeight: 600, color: "black" }}>
                      Delivery Charges
                    </p>
                    <p style={{ fontWeight: 600, color: "black" }}>Total</p>
                  </Grid>
                  <Grid xs={4} item>
                    <p style={{ fontWeight: 600, color: "black" }}>
                      AZN {this.state?.taxData?.menuAmount || "0.00"}
                    </p>

                    <p style={{ fontWeight: 600, color: "black" }}>
                      AZN {this.state?.taxData?.tax1Amount || "0.00"}
                    </p>

                    <p style={{ fontWeight: 600, color: "black" }}>
                      AZN {"0.00"}
                    </p>

                    <p style={{ fontWeight: 600, color: "black" }}>
                      AZN{" "}
                      {isNaN(
                        Number(this.state?.taxData?.paymentAmount)?.toFixed(2)
                      )
                        ? "0.00"
                        : Number(this.state?.taxData?.paymentAmount)?.toFixed(
                            2
                          ) || "0.00"}
                    </p>
                  </Grid>
                </Grid>
              </Card>
            </Paper>
          </DialogContent>
        </Dialog>

        <Loader open={this.state.activity} />

        {this.state.running ? (
          <div className="float">
            <div>
              <span style={{ fontSize: 15, color: "white" }}>
                Order Take Time
              </span>
              <ReactTimerStopwatch
                isOn={this.state.running}
                watchType="stopwatch"
                fromTime={new Date(0, 0, 0, 0, 0, 0, 0)}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStore(OrdersContainer);
